import { ReactEditor } from "slate-react";
import { BaseEditor, BaseRange, BaseSelection, MaximizeMode, NodeMatch, Path, PropsCompare, PropsMerge, Range, Node as SlateNode, Location } from "slate";
import { HistoryEditor } from "slate-history";
import { ButtonProps } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Enums } from "./supabase-helpers";

export type CustomEditor = BaseEditor &
  ReactEditor &
  HistoryEditor & {
    nodeToDecorations?: Map<Element, Range[]>;
  };

declare module "slate" {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
    Range: BaseRange & {
      [key: string]: unknown;
    };
  }
}

export type CustomElement = {
  id?: string;
  type: ElementType;
  variant?: ButtonType | null;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | null;
  align?: "left" | "center" | "right" | null;
  children: CustomText[] | CustomElement[];
  comment?: string | null;
  url?: string | null;
};

export type CustomText = {
  text: string;
  caps?: "title" | "proper" | "upper" | "normal";
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  pseudolink?: boolean;
  red?: boolean;
  strike?: boolean;
  green?: boolean;
  blue?: boolean;
  gray?: boolean;
  mark?: boolean;
  result?: boolean;
  sub?: boolean;
  sup?: boolean;
  href?: string;
  code?: boolean;
  [key: `commentThread-${string}`]: boolean;
  readability?: Readability;
  adverb?: boolean;
  hedge?: boolean;
};

export type Readability = "normal" | "hard" | "veryHard";

export type Format = keyof CustomText;
export type ElementType =
  | "paragraph"
  | "bulleted-list"
  | "numbered-list"
  | "list-item"
  | "checked-list"
  | "crossed-list"
  | "arrowed-list"
  | "dashed-list"
  | "blockquote"
  | "button"
  | "divider"
  | "heading"
  | "nested-list"
  | "unnested-list"
  | "link"
  | "code"
  | "callout"
  | "accordion-item"
  | "accordion-button"
  | "accordion-panel"
  | "card"
  | "optin"
  | "optin-input"
  | "optin-button"
  | "optin-disclaimer"
  | "table"
  | "table-row"
  | "table-cell"
  | "table-header";

export type LeafType = Omit<keyof CustomText, "text">;

export type AssetTypeFeatures = {
  [key: string]: string[][];
};

export const globals = ["paragraph", "callout"];
export const inlines = ["bold", "italic", "underline", "pseudolink", "link", "mark", "strike", "sub", "sup"];
export const wraps = ["curly-brackets"];
export const aligns = ["align"];
export const headings = ["heading"];
export const elements = ["button", "blockquote", "divider", "code"];
export const specials = ["optin", "optin-input", "optin-button", "accordion-item", "accordion-button", "accordion-panel", "card", "optin-disclaimer"];
export const lists = ["bulleted-list", "numbered-list", "checked-list", "crossed-list", "arrowed-list", "dashed-list", "list-item"];
export const colors = ["red", "green", "blue", "gray"];
export const tables = ["table", "table-row", "table-cell", "table-header"];

export const LEAF_TYPES = [...inlines, ...colors];

export const ASSET_TYPE_FEATURES = {
  EMAIL: [globals, inlines, wraps, aligns, headings, elements, lists, colors, tables],
  PAGE: [globals, inlines, wraps, aligns, headings, elements, specials, lists, colors, tables],
  POST: [globals, inlines, wraps, aligns, headings, elements, lists, colors, tables],
  SCRIPT: [globals, inlines, wraps, headings],
  SOCIAL: [globals, inlines, wraps],
  NOTE: [globals, inlines, wraps, headings, lists, tables],
  SEQUENCE: [],
};

export const LIST_TYPES = ["numbered-list", "bulleted-list", "checked-list", "crossed-list", "arrowed-list", "dashed-list", "list-item"];
export const ALIGN_TYPES = ["left", "center", "right"];
export const HEADING_TYPES = ["h1", "h2", "h3", "h4", "h5", "h6"] as HeadingType[];
export const CAPS_TYPES = ["title", "proper", "upper", "normal"];
export const BUTTON_VARIANTS = ["solid", "outline"];

export type HotkeyEvent = React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement, MouseEvent> | Event;
export type HeadingType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
export type WrapType =
  | "quote-marks"
  | "parentheses"
  | "square-brackets"
  | "curly-brackets"
  | "angle-brackets"
  | "apostrophes"
  | "asterisks"
  | "tilde"
  | "underscore";
export type AlignType = "left" | "center" | "right";
export type ButtonType = "solid" | "outline";
export type ListType = "numbered-list" | "bulleted-list" | "checked-list" | "crossed-list" | "arrowed-list" | "dashed-list" | "list-item";

export type ElementMenuProps = {
  forwardRef: React.RefObject<HTMLDivElement>;
  assetType: Enums<"asset_type">;
  slashMenuStyles: SlateControlsStyle;
  editor: CustomEditor;
  activeElementMenuIndex: number[];
  setActiveElementMenuIndex: React.Dispatch<React.SetStateAction<number[]>>;
};

export type ElementMenu = {
  group: string;
  items: ElementMenuItem[];
}[];

export type ElementMenuItem = {
  title: string;
  feature: string;
  description: string;
  iconType: IconType;
  commandKey: string;
  onClick: () => void;
};

export type SlateControlsStyle = {
  opacity: string;
  zIndex: string;
  top: string;
  left: string;
  isOpen: boolean;
};

export type SlateControlsState = {
  toolbar: SlateControlsStyle;
  slashMenu: SlateControlsStyle;
  linkPopover: SlateControlsStyle;
  emojiPopover: SlateControlsStyle;
};

export type NewCommentState = {
  isVisible: boolean;
  opacity: string;
  zIndex: string;
  top: string;
  left: string;
  elementText: string;
  elementPath: Path;
};

export type ToolbarFormatButton = {
  ariaLabel: string;
  icon: JSX.Element;
  onClick: () => void;
  isActive: boolean;
};

export type ToolbarDropdownButton = {
  ariaLabel: string;
  icon: JSX.Element;
  onClick: () => void;
  isActive: boolean;
  rounded?: string;
};

export type TimeStampsMap = Map<number, { startTime: string; endTime: string }>;

export type CommenterState = {
  height: number;
  scrollOffset: number;
  anchorTop: number;
  left: number;
  opacity: number;
  isOpen: boolean;
  elementSelection: BaseSelection | null;
  elementText: string;
  newThreadId: string;
  zIndex: number;
};

export interface NodeOptions {
  at?: Location | undefined;
  match?: (node: SlateNode, path: Location) => boolean;
  mode?: "highest" | "lowest";
  voids?: boolean;
}
