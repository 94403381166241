import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Switch,
  Grid,
  GridItem,
  Card,
  CardHeader,
  VStack,
  Flex,
  CardBody,
  List,
  ListItem,
  HStack,
  CardFooter,
  Button,
  Tag,
  Badge,
} from "@chakra-ui/react";
import { Link } from "@remix-run/react";
import { PropsWithChildren, useEffect, useState } from "react";
import { HiX } from "react-icons/hi";
import { HiCheck, HiEllipsisHorizontal } from "react-icons/hi2";
import Stripe from "stripe";
import { PLANS } from "utils/constants";
import SettingsRow from "components/SettingsRow";

import { BProfile } from "routes/_admin.billing";
import { BasicSubscription, PricingPlan } from "types/stripe-types";
import { ClientSideProfile } from "types/supabase-helpers";
import { titleCase } from "title-case";
import mixpanel from "mixpanel-browser";

export type SubscriptionPlansProps = {
  profile: ClientSideProfile;
  subscription: BasicSubscription | null;
};

export default function SubscriptionPlans({ profile, subscription }: PropsWithChildren<SubscriptionPlansProps>) {
  const [interval, setInterval] = useState<Stripe.Plan.Interval>(subscription?.interval || "month");
  const [currentPriceId, setCurrentPriceId] = useState<string | null>(subscription?.price_id || null);
  const [intervalPlans, setPlans] = useState<PricingPlan[]>(PLANS.filter((plan) => plan.interval === interval));

  useEffect(() => {
    setPlans(PLANS.filter((plan) => plan.interval === interval));
  }, [interval]);

  return (
    <VStack spacing={4}>
      <PricingIntervalSwitch interval={interval} setInterval={setInterval} />
      <Grid templateColumns="repeat(3, 1fr)" gap={6} width="full">
        {intervalPlans.map((plan) => {
          return <PricingPlanCard key={plan.price_id} plan={plan} profile={profile} subscription={subscription} />;
        })}
      </Grid>
    </VStack>
  );
}

const PricingPlanCard = ({ plan, profile, subscription }: { plan: PricingPlan; profile: BProfile; subscription: BasicSubscription | null }) => {
  return (
    <Card key={plan.price_id} p={2} overflow="hidden" borderTop="5px solid" borderTopColor="blue.500" as={GridItem}>
      {!subscription && plan.ribbon && (
        <Flex
          {...{
            alignItems: "center",
            justifyContent: "center",
            bg: "blue.500",
            color: "white",
            position: "absolute",
            right: "calc(var(--chakra-space-10) * -1)",
            top: 3,
            transform: "rotate(45deg)",
            py: 1.5,
            px: 12,
            fontWeight: "medium",
            lineHeight: "1.5rem",
            fontSize: "sm",
          }}>
          <Text>{plan.ribbon}</Text>
        </Flex>
      )}
      <CardHeader pb={2}>
        <PlanSummary plan={plan} />
      </CardHeader>
      <CardBody py={0}>
        <FeaturesList features={plan.features} />
      </CardBody>
      <CardFooter>
        <BillingAction profile={profile} plan={plan} subscription={subscription} />
      </CardFooter>
    </Card>
  );
};

const PricingIntervalSwitch = ({ interval, setInterval }: { interval: Stripe.Plan.Interval; setInterval: (interval: Stripe.Plan.Interval) => void }) => {
  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel htmlFor="interval" mb="0">
        Monthly
      </FormLabel>
      <Switch id="interval" isChecked={interval === "year"} onChange={(e) => setInterval(e.target.checked ? "year" : "month")} />
      <FormLabel htmlFor="interval" mb="0" ml={3}>
        Annual{" "}
        <Text as="span" color="green.400">
          (-20%)
        </Text>
      </FormLabel>
    </FormControl>
  );
};

const PlanFeature = ({ feature, isIncluded, isComingSoon }: { feature: string; isIncluded: boolean; isComingSoon?: boolean }) => {
  return (
    <ListItem key={feature}>
      <HStack spacing={3}>
        <Box
          rounded="full"
          p={0.5}
          fontSize={15}
          bg={isComingSoon ? "gray.100" : isIncluded ? "green.100" : "gray.100"}
          color={isComingSoon ? "gray.400" : isIncluded ? "green.500" : "gray.400"}>
          {isComingSoon ? <HiCheck /> : isIncluded ? <HiCheck /> : <HiX />}
        </Box>
        <Text color={isIncluded ? "gray.800" : "gray.400"} decoration={isIncluded ? "none" : "line-through"}>
          {feature}
        </Text>
        {isComingSoon && <Badge colorScheme="blue">Coming Soon</Badge>}
      </HStack>
    </ListItem>
  );
};

const PlanSummary = ({ plan }: { plan: PricingPlan }) => {
  return (
    <VStack spacing={1}>
      <VStack spacing={1}>
        <Text fontWeight="semibold" fontSize="xl">
          {plan.name}
        </Text>
        <Text fontSize="sm">{plan.description}</Text>
      </VStack>
      <VStack alignItems="center">
        <Flex alignItems="flex-start" fontWeight="semibold">
          <Text fontSize="xl" lineHeight="3rem">
            $
          </Text>
          <Text fontSize="4xl" lineHeight="3.75rem">
            {plan.amount}
          </Text>
          <Text alignSelf="flex-end" lineHeight="3rem" fontSize="xl" cursor="help">
            /{plan.interval}
          </Text>
        </Flex>
      </VStack>
    </VStack>
  );
};

const FeaturesList = ({ features }: { features: PricingPlan["features"] }) => {
  if (!features.included && !features.not_included) return null;

  return (
    <List spacing={3} stylePosition="inside">
      {features.included && features.included.map((feature) => <PlanFeature key={feature} feature={feature} isIncluded={true} />)}
      {features.not_included && features.not_included.map((feature) => <PlanFeature key={feature} feature={feature} isIncluded={false} />)}
      {features.coming_soon && features.coming_soon.map((feature) => <PlanFeature key={feature} feature={feature} isIncluded={true} isComingSoon={true} />)}
    </List>
  );
};

const BillingAction = ({ profile, plan, subscription }: { plan: PricingPlan; profile: BProfile; subscription: BasicSubscription | null }) => {
  const isSubscriptionActive = subscription?.status === "active";
  const isCurrentPlan = isSubscriptionActive && subscription?.price_id === plan.price_id;
  const isSameInterval = isSubscriptionActive && subscription?.interval === plan.interval;
  const isDifferentInterval = isSubscriptionActive && subscription && subscription.interval !== plan.interval;
  const isHigherThanCurrentPlan = isSubscriptionActive && subscription?.amount && plan.amount && subscription?.amount < plan.amount;
  const isLowerThanCurrentPlan = isSubscriptionActive && subscription?.amount && plan.amount && subscription?.amount > plan.amount;

  let buttonLabel = "Select Plan";
  if (isCurrentPlan) buttonLabel = "Cancel Plan";
  if (isDifferentInterval) buttonLabel = "Change Plan";
  if (isSameInterval && isHigherThanCurrentPlan) buttonLabel = "Upgade Plan";
  if (isSameInterval && isLowerThanCurrentPlan) buttonLabel = "Downgrade";
  if (plan.tier === "free" && profile.subscription_tier === "NONE") buttonLabel = "Current Plan";
  if (plan.tier === "free" && profile.subscription_tier === "BASIC") buttonLabel = "Downgrade";
  if (plan.tier === "free" && profile.subscription_tier === "PRO") buttonLabel = "Downgrade";
  if (plan.tier === "basic" && profile.subscription_tier === "NONE") buttonLabel = "Upgrade Plan";
  if (plan.tier === "pro" && profile.subscription_tier === "NONE") buttonLabel = "Upgrade Plan";

  let buttonVariant = "solid";
  if (isCurrentPlan) buttonVariant = "outline";
  if (isSameInterval && isHigherThanCurrentPlan) buttonVariant = "solid";
  if (isSameInterval && isLowerThanCurrentPlan) buttonVariant = "solid";
  if (plan.tier === "free" && profile.subscription_tier === "NONE") buttonVariant = "outline";

  let buttonLink = `${plan.payment_link}?client_reference_id=${profile.id}&prefilled_email=${profile.email}`;
  if (isCurrentPlan) buttonLink = `${plan.customer_portal_link}?prefilled_email=${profile.email}`;

  const handleClick = () => {
    mixpanel.identify(profile?.email);
    mixpanel.track("Billing Button Clicked", {
      "Button Label": buttonLabel,
      "Plan Tier": plan.tier,
      "Plan Amount": plan.amount,
      "Plan Interval": plan.interval,
      "Current Plan": profile.subscription_tier,
      "Button Link": buttonLink,
    });
  };

  return (
    <Button as={Link} to={encodeURI(buttonLink)} colorScheme="blue" variant={buttonVariant} width="full" size="md" target="_blank" onClick={handleClick}>
      {buttonLabel}
    </Button>
  );
};
