import { Box, Button, Flex, Heading, HStack, IconButton, Link as ChakraLink, Menu, MenuButton, MenuItem, MenuList, Text, VStack } from "@chakra-ui/react";
import { Link, useRevalidator } from "@remix-run/react";
import { useEffect, useState } from "react";
import { HiEllipsisHorizontal, HiOutlineArchiveBoxArrowDown, HiOutlineArrowUpTray, HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";
import { HiChevronDown } from "react-icons/hi";
import { ClientSideCommentActivity } from "routes/_admin";
import UserAvatar from "components/UserAvatar";
import useToast from "hooks/useToast";
import { TypedSupabaseClient } from "~/root";
import { ClientSideProfile } from "types/supabase-helpers";
import { friendlyTimeAgo, getAvatarUrl } from "utils/helpers";
import MenuIcon from "components/MenuIcon";
import FormFooter from "components/FormFooter";
import CloseModalButton from "components/CloseModalButton";
import mixpanel from "mixpanel-browser";

// * TYPES
export type CommentActivityProps = {
  commentActivities: ClientSideCommentActivity[] | undefined;
  profile: ClientSideProfile;
  supabase: TypedSupabaseClient;
  closeModal: () => void;
};

// * COMPONENT
export default function CommentActivity({ commentActivities, profile, supabase, closeModal }: CommentActivityProps) {
  const [unreadCommentActivities, setUnreadCommentActivities] = useState<ClientSideCommentActivity[]>([]);
  const [readCommentActivities, setReadCommentActivities] = useState<ClientSideCommentActivity[]>([]);

  useEffect(() => {
    const unreadCommentActivities = commentActivities?.filter((commentActivity) => !commentActivity.is_read && !commentActivity.is_archived);
    setUnreadCommentActivities(unreadCommentActivities || []);
    const readCommentActivities = commentActivities?.filter((commentActivity) => commentActivity.is_read && !commentActivity.is_archived);
    setReadCommentActivities(readCommentActivities || []);
    revalidator.revalidate();
  }, [commentActivities]);

  const revalidator = useRevalidator();

  const { addToast } = useToast();

  async function markAllAsRead() {
    const promises = unreadCommentActivities.map((commentActivity) =>
      supabase
        .from("join_comment_profile")
        .upsert({ is_read: true, comment_id: commentActivity.id, profile_id: profile.id }, { onConflict: "comment_id, profile_id" })
    );
    await Promise.all(promises);
    setUnreadCommentActivities([]);
    const updatedReadCommentActivities = unreadCommentActivities.map((commentActivity) => {
      return { ...commentActivity, is_read: true };
    });
    setReadCommentActivities(updatedReadCommentActivities);
    addToast({ title: "All comments marked as read", status: "success" });
  }

  const [displayLimit, setDisplayLimit] = useState(10);

  const showMoreComments = () => {
    setDisplayLimit((prev) => prev + 10);
  };

  return (
    <>
      <VStack spacing={6} maxH="calc(100vh - 8rem)" overflowY="auto">
        {!readCommentActivities && !unreadCommentActivities && (
          <VStack
            {...{
              alignItems: "center",
              justifyContent: "center",
              spacing: 5,
              bg: "gray.50",
              borderRadius: "md",
              border: "1px solid",
              borderColor: "gray.200",
              width: "100%",
              py: 16,
              px: 6,
              mb: 8,
            }}>
            <Flex alignItems="center" justifyContent="center" mb={6} mr={4}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                width="6rem"
                viewBox="0 0 647.63626 632.17383"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <path
                  d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
                  transform="translate(-276.18187 -133.91309)"
                  fill="#f2f2f2"
                />
                <path
                  d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
                  transform="translate(-276.18187 -133.91309)"
                  fill="#3f3d56"
                />
                <path
                  d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
                  transform="translate(-276.18187 -133.91309)"
                  fill="#3182ce"
                />
                <circle cx="190.15351" cy="24.95465" r="20" fill="#3182ce" />
                <circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff" />
                <path
                  d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
                  transform="translate(-276.18187 -133.91309)"
                  fill="#e6e6e6"
                />
                <path
                  d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
                  transform="translate(-276.18187 -133.91309)"
                  fill="#3f3d56"
                />
                <path
                  d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
                  transform="translate(-276.18187 -133.91309)"
                  fill="#3182ce"
                />
                <circle cx="433.63626" cy="105.17383" r="20" fill="#3182ce" />
                <circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff" />
              </svg>
            </Flex>
            <VStack>
              <Heading as="h2" size="xl">
                No activity yet
              </Heading>
              <Text as="p" fontSize="md" align="center">
                You'll see activity here when people leave comments on your work. Nobody has commented yet.
              </Text>
            </VStack>

            <Text>
              <Text as="span" fontWeight="medium">
                Need help?
              </Text>{" "}
              <ChakraLink
                color="blue.500"
                fontWeight="medium"
                onClick={() => {
                  mixpanel.identify(profile?.email);
                  mixpanel.track("Support Clicked", { "Action Location": `Comment Modal` });
                  window.Chatra("openChat", true);
                }}>
                Chat with us!
              </ChakraLink>{" "}
            </Text>
          </VStack>
        )}
        {unreadCommentActivities.length > 0 && (
          <VStack align="flex-start" width="100%">
            <HStack justifyContent="space-between" width="100%">
              <Box display="flex" alignItems="center">
                <Text fontSize="sm" fontWeight="medium">
                  Unread Comments
                </Text>
                <Box
                  display="inline-block"
                  bg="red.500"
                  rounded="sm"
                  color="white"
                  fontSize="xs"
                  px={0.5}
                  fontWeight="semibold"
                  minW="1.33em"
                  h="1.33em"
                  ml={1.5}
                  lineHeight="1.33em"
                  textAlign="center">
                  {unreadCommentActivities.length}
                </Box>
              </Box>
              <Button variant="ghost" size="xs" onClick={() => markAllAsRead()}>
                Mark all as read
              </Button>
            </HStack>
            {unreadCommentActivities.length > 0 &&
              unreadCommentActivities.map((commentActivity) => (
                <CommentItem
                  key={commentActivity.id}
                  commentActivity={commentActivity}
                  profile={profile}
                  supabase={supabase}
                  closeModal={closeModal}
                  setUnreadCommentActivities={setUnreadCommentActivities}
                  setReadCommentActivities={setReadCommentActivities}
                />
              ))}
          </VStack>
        )}
        {readCommentActivities.length > 0 && (
          <VStack align="flex-start" width="100%">
            <Text fontSize="sm" fontWeight="medium" display="flex" alignItems="center">
              {unreadCommentActivities.length > 0 ? "Other Comments" : "All Comments"}
            </Text>
            {readCommentActivities.length > 0 &&
              readCommentActivities
                .slice(0, displayLimit)
                .map((commentActivity) => (
                  <CommentItem
                    key={commentActivity.id}
                    commentActivity={commentActivity}
                    profile={profile}
                    supabase={supabase}
                    closeModal={closeModal}
                    setUnreadCommentActivities={setUnreadCommentActivities}
                    setReadCommentActivities={setReadCommentActivities}
                  />
                ))}
            {readCommentActivities.length > displayLimit && <ShowMoreButton showMoreComments={showMoreComments} />}
          </VStack>
        )}
      </VStack>
      {/* <FormFooter mt={0} pt={4} borderTop="1px solid" borderColor="gray.100">
        <CloseModalButton closeModal={closeModal} />
      </FormFooter> */}
    </>
  );
}

// * SUB-COMPONENTS
const CommentItem = ({
  commentActivity,
  profile,
  supabase,
  closeModal,
  setUnreadCommentActivities,
  setReadCommentActivities,
}: {
  commentActivity: ClientSideCommentActivity;
  profile: ClientSideProfile;
  supabase: TypedSupabaseClient;
  closeModal: () => void;
  setUnreadCommentActivities: React.Dispatch<React.SetStateAction<ClientSideCommentActivity[]>>;
  setReadCommentActivities: React.Dispatch<React.SetStateAction<ClientSideCommentActivity[]>>;
}) => {
  useEffect(() => {
    async function markCommentNotNew(commentId: string, profileId: string) {
      const { data: joinComment, error: joinCommentError } = await supabase
        .from("join_comment_profile")
        .upsert({ is_new: false, comment_id: commentId, profile_id: profileId }, { onConflict: "comment_id, profile_id" });
      if (joinCommentError) console.error({ joinCommentError });
    }
    if (commentActivity.is_new) markCommentNotNew(commentActivity.id, profile.id);
  }, []);

  async function handleCommentClick(commentId: string, profileId: string) {
    await markAsRead(commentId, profileId);
    closeModal();
  }

  const revalidator = useRevalidator();

  async function markAsRead(commentId: string, profileId: string) {
    const { data: joinComment, error: joinCommentError } = await supabase
      .from("join_comment_profile")
      .upsert({ is_read: true, comment_id: commentId, profile_id: profileId }, { onConflict: "comment_id, profile_id" });
    if (joinCommentError) console.error({ joinCommentError });
    else revalidator.revalidate();
  }

  async function markAsUnread(commentId: string, profileId: string) {
    const { data: joinComment, error: joinCommentError } = await supabase
      .from("join_comment_profile")
      .upsert({ is_read: false, comment_id: commentId, profile_id: profileId }, { onConflict: "comment_id, profile_id" });
    if (joinCommentError) console.error({ joinCommentError });
    else revalidator.revalidate();
  }

  async function markAsArchived(commentId: string, profileId: string) {
    const { data: joinComment, error: joinCommentError } = await supabase
      .from("join_comment_profile")
      .upsert({ is_archived: true, comment_id: commentId, profile_id: profileId }, { onConflict: "comment_id, profile_id" });
    if (joinCommentError) console.error({ joinCommentError });
    else revalidator.revalidate();
  }

  async function markAsUnarchived(commentId: string, profileId: string) {
    const { data: joinComment, error: joinCommentError } = await supabase
      .from("join_comment_profile")
      .upsert({ is_archived: false, comment_id: commentId, profile_id: profileId }, { onConflict: "comment_id, profile_id" });
    if (joinCommentError) console.error({ joinCommentError });
    else revalidator.revalidate();
  }

  return (
    <Box
      bg={commentActivity.is_read ? "gray.50" : "blue.50"}
      transition="background 0.1s ease-in-out"
      rounded="md"
      p={2.5}
      width="100%"
      cursor="pointer"
      // border="1px solid"
      // borderColor={commentActivity.is_read ? "gray.100" : "blue.100"}
      _hover={{ bg: commentActivity.is_read ? "gray.100" : "blue.100" }}>
      <HStack>
        <UserAvatar name={commentActivity.profile_name} src={getAvatarUrl(commentActivity.profile_id)} alignSelf="flex-start" />
        <VStack
          align="flex-start"
          width="100%"
          spacing={0.5}
          flex="1"
          maxWidth="calc(100% - 20px - 0.5rem - 0.75rem)"
          as={Link}
          onClick={() => handleCommentClick(commentActivity.id, profile.id)}
          to={commentActivity.navlink}>
          <HStack justifyContent="space-between" width="100%">
            <Text fontSize="xs" color="gray.600" noOfLines={1}>
              <Text as="span" fontWeight="medium" color="gray.700">
                {commentActivity.profile_name}
              </Text>{" "}
              commented on{" "}
              <Text as="span" fontWeight="medium" color="gray.700">
                {commentActivity.version_name}
              </Text>
              :
            </Text>
          </HStack>
          <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
            "{commentActivity.content}"
          </Text>
          <Text fontSize="xs" color="gray.700" noOfLines={1} pt={0.5}>
            {friendlyTimeAgo(new Date(commentActivity.created_at))}
          </Text>
        </VStack>

        <Menu placement="bottom-start">
          <MenuButton
            as={IconButton}
            aria-label="Mark as read"
            icon={<HiEllipsisHorizontal fontSize={20} />}
            size="xs"
            variant="ghost"
            rounded="full"
            _hover={{ bg: commentActivity.is_read ? "gray.200" : "blue.200" }}
            colorScheme={commentActivity.is_read ? "gray" : "blue"}
          />
          <MenuList>
            {!commentActivity.is_read ? (
              <MenuItem
                onClick={() => {
                  markAsRead(commentActivity.id, profile.id);
                  setUnreadCommentActivities((prev) =>
                    prev
                      .filter((comment) => comment.id !== commentActivity.id)
                      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                  );
                  setReadCommentActivities((prev) => [...prev, { ...commentActivity, is_read: true }]);
                }}
                icon={<MenuIcon iconType={HiOutlineEye} size="sm" />}
                fontSize="sm">
                Mark as read
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  markAsUnread(commentActivity.id, profile.id);
                  setUnreadCommentActivities((prev) => [...prev, { ...commentActivity, is_read: false }]);
                  setReadCommentActivities((prev) =>
                    prev
                      .filter((comment) => comment.id !== commentActivity.id)
                      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                  );
                }}
                icon={<MenuIcon iconType={HiOutlineEyeSlash} size="sm" />}
                fontSize="sm">
                Mark as unread
              </MenuItem>
            )}
            {!commentActivity.is_archived ? (
              <MenuItem
                onClick={() => {
                  markAsArchived(commentActivity.id, profile.id);
                  setUnreadCommentActivities((prev) => prev.filter((comment) => comment.id !== commentActivity.id));
                  setReadCommentActivities((prev) => prev.filter((comment) => comment.id !== commentActivity.id));
                }}
                icon={<MenuIcon iconType={HiOutlineArchiveBoxArrowDown} size="sm" />}
                fontSize="sm">
                Archive comment
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  markAsUnarchived(commentActivity.id, profile.id);
                  if (!commentActivity.is_read) setUnreadCommentActivities((prev) => [...prev, { ...commentActivity, is_archived: false }]);
                  else setReadCommentActivities((prev) => [...prev, { ...commentActivity, is_archived: false }]);
                }}
                icon={<MenuIcon iconType={HiOutlineArrowUpTray} size="sm" />}
                fontSize="sm">
                Unarchive comment
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </HStack>
    </Box>
  );
};

const ShowMoreButton = ({ showMoreComments }: { showMoreComments: () => void }) => {
  return (
    <Button colorScheme="blue" size="sm" py={2} variant="ghost" rightIcon={<HiChevronDown />} onClick={showMoreComments} alignSelf="center">
      Show more
    </Button>
  );
};
