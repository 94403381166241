import { jsx } from "slate-hyperscript";
import isHotkey from "is-hotkey";
import {
  Text,
  Range,
  Transforms,
  Path,
  Editor,
  Descendant,
  Element as SlateElement,
  Location,
  Node as SlateNode,
  Point,
  BasePoint,
  NodeEntry,
  fragment,
} from "slate";
import {
  HotkeyEvent,
  CustomEditor,
  LIST_TYPES,
  ListType,
  CustomElement,
  Format,
  BUTTON_VARIANTS,
  ButtonType,
  HEADING_TYPES,
  ALIGN_TYPES,
  AlignType,
  WrapType,
  HeadingType,
  ElementType,
  colors,
  LEAF_TYPES,
  CustomText,
  NodeOptions,
  Readability,
  SlateControlsState,
} from "types/slate-types";
import { ClientSideProfile, ClientSideRichSection } from "types/supabase-helpers";
import { getTextFromDescendant } from "utils/helpers";
import { TypedSupabaseClient } from "~/root";
import { useRevalidator } from "@remix-run/react";
import { ReactEditor } from "slate-react";
import { titleCase } from "title-case";
import { acceptedLyWords, hedgePhrases, ignoredStringSplitters, PLAIN_TEXT_PASTE_BLOCKS } from "utils/constants";
import { NodeInsertNodesOptions } from "slate/dist/interfaces/transforms/node";
import mixpanel from "mixpanel-browser";

type HotkeyAction = (props: HOTKEY_ACTION_PROPS) => void;
type SingleKeyAction = (props: SINGLEKEY_ACTION_PROPS) => void;

type HOTKEY_ACTION_PROPS = {
  event: React.KeyboardEvent<HTMLElement>;
  editor: CustomEditor;
  allowedFeatures: Set<string>;
  supabase: TypedSupabaseClient;
  section?: ClientSideRichSection | undefined;
  itemId: string;
  handleOpenLinkPopover?: () => void;
  handleOpenEmojiPopover?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  revalidator?: ReturnType<typeof useRevalidator>;
};

type SINGLEKEY_ACTION_PROPS = {
  event: React.KeyboardEvent<HTMLDivElement>;
  editor: CustomEditor;
  allowedFeatures: Set<string>;
  supabase: TypedSupabaseClient;
  profile: ClientSideProfile | null;
  section?: ClientSideRichSection | undefined;
  itemId: string;
  handleOpenLinkPopover?: () => void;
  revalidator?: ReturnType<typeof useRevalidator>;
  handleOpenSlashMenu?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  handleCloseSlashMenu?: () => void;
  handleCloseToolbar?: () => void;
  controlsState?: SlateControlsState;
  handleCloseEmojiPopover?: () => void;
  setEmojiQuery?: React.Dispatch<React.SetStateAction<string | null>>;
};

const SINGLEKEY_ACTIONS: Record<string, SingleKeyAction> = {
  space: (props: SINGLEKEY_ACTION_PROPS) => {
    insertSpace(props.event, props.editor);
  },
  backspace: (props: SINGLEKEY_ACTION_PROPS) => {
    handleBackspace(
      props.event,
      props.editor,
      props.itemId,
      props.supabase,
      props.profile,
      props.section,
      props.controlsState,
      props.handleCloseEmojiPopover,
      props.revalidator
    );
  },
  enter: (props: SINGLEKEY_ACTION_PROPS) => {
    insertEnter(props.event, props.editor);
  },
  Tab: (props: SINGLEKEY_ACTION_PROPS) => {
    insertTab(props.event, props.editor, props.controlsState);
  },
  "[": (props: SINGLEKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("square-brackets")) wrapText(props.event, props.editor, "square-brackets");
  },
  "'": (props: SINGLEKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("apostrophes")) wrapText(props.event, props.editor, "apostrophes");
  },
  "/": (props: SINGLEKEY_ACTION_PROPS) => {
    if (getPreviousCharacters(props.editor, 1) === "") props.handleOpenSlashMenu?.(props.event);
  },
  ArrowRight: (props: SINGLEKEY_ACTION_PROPS) => {
    if (props.controlsState?.emojiPopover.isOpen) props.event.preventDefault();
  },
  ArrowLeft: (props: SINGLEKEY_ACTION_PROPS) => {
    if (props.controlsState?.emojiPopover.isOpen) props.event.preventDefault();
  },
  ArrowUp: (props: SINGLEKEY_ACTION_PROPS) => {
    if (props.controlsState?.emojiPopover.isOpen) props.event.preventDefault();
  },
  ArrowDown: (props: SINGLEKEY_ACTION_PROPS) => {
    if (props.controlsState?.emojiPopover.isOpen) props.event.preventDefault();
  },
  // ArrowUp: (props: SINGLEKEY_ACTION_PROPS) => {
  //   if (props.allowedFeatures.has("table")) moveCursorUp(props.editor, props.event);
  // },
  // ArrowDown: (props: SINGLEKEY_ACTION_PROPS) => {
  //   if (props.allowedFeatures.has("table")) moveCursorDown(props.editor, props.event);
  // },
};

const HOTKEY_ACTIONS: Record<string, HotkeyAction> = {
  "shift+space": (props: HOTKEY_ACTION_PROPS) => {
    insertSpace(props.event, props.editor);
  },
  "mod+z": (props: HOTKEY_ACTION_PROPS) => {
    props.editor.undo();
  },
  "mod+shift+z": (props: HOTKEY_ACTION_PROPS) => {
    props.editor.redo();
  },
  "mod+shift+p": (props: HOTKEY_ACTION_PROPS) => {
    toggleElement("paragraph", props.editor, props.event);
  },
  "mod+b": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("bold")) toggleMark(props.editor, "bold", props.event);
  },
  "mod+i": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("italic")) toggleMark(props.editor, "italic", props.event);
  },
  "mod+u": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("underline")) toggleMark(props.editor, "underline", props.event);
  },
  "mod+shift+s": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("strike")) toggleMark(props.editor, "strike", props.event);
  },
  "mod+shift+l": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("pseudolink")) toggleMark(props.editor, "pseudolink", props.event);
  },
  "mod+shift+k": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("link")) props.handleOpenLinkPopover && props.handleOpenLinkPopover();
  },
  "shift+;": (props: HOTKEY_ACTION_PROPS) => {
    handleColon(props.editor, props.event, props.handleOpenEmojiPopover);
  },
  "shift+enter": (props: HOTKEY_ACTION_PROPS) => {
    props.event.preventDefault();
    safeInsertText(props.editor, "\n");
  },
  "mod+shift+m": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("mark")) toggleMark(props.editor, "mark", props.event);
  },
  "mod+shift+r": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("red")) toggleMark(props.editor, "red", props.event);
  },
  "mod+shift+g": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("green")) toggleMark(props.editor, "green", props.event);
  },
  "mod+shift+/": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("gray")) toggleMark(props.editor, "gray", props.event);
  },
  "mod+shift+-": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("sub")) toggleMark(props.editor, "sub", props.event);
  },
  "mod+shift+=": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("sup")) toggleMark(props.editor, "sup", props.event);
  },
  "mod+shift+h": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("heading")) toggleHeading(props.editor, props.event);
    else props.event.preventDefault();
  },
  "shift+'": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("quote-marks")) wrapText(props.event, props.editor, "quote-marks");
  },
  "mod+shift+c": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("callout")) toggleElement("callout", props.editor, props.event);
    else props.event.preventDefault();
  },
  "mod+shift+7": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("code")) toggleElement("code", props.editor, props.event);
    else props.event.preventDefault();
  },
  "mod+shift+e": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("table")) insertTable(props.editor, props.event);
  },
  "shift+[": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("curly-brackets")) wrapText(props.event, props.editor, "curly-brackets");
  },
  "shift+9": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("parentheses")) wrapText(props.event, props.editor, "parentheses");
  },
  "shift+,": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("angle-brackets")) wrapText(props.event, props.editor, "angle-brackets");
  },

  "shift+8": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("asterisks")) wrapText(props.event, props.editor, "asterisks");
  },
  "shift+`": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("tilde")) wrapText(props.event, props.editor, "tilde");
  },
  "shift+-": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("underscore")) wrapText(props.event, props.editor, "underscore");
  },
  "mod+shift+,": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("align")) toggleAlignment(props.event, props.editor);
  },
  "mod+shift+.": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("align")) toggleAlignment(props.event, props.editor);
  },
  "mod+shift+'": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("blockquote")) toggleElement("blockquote", props.editor, props.event);
    else props.event.preventDefault();
  },
  "mod+shift+b": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("button")) toggleButton(props.editor, props.event);
    else props.event.preventDefault();
  },
  "mod+shift+i": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("optin")) insertOptin(props.editor, props.event);
    else props.event.preventDefault();
  },
  "mod+shift+d": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("divider")) insertDivider(props.editor, props.event);
    else props.event.preventDefault();
  },
  "mod+shift+8": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("bulleted-list")) toggleList(props.editor, "bulleted-list", props.event);
    else props.event.preventDefault();
  },
  "mod+shift+1": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("numbered-list")) toggleList(props.editor, "numbered-list", props.event);
    else props.event.preventDefault();
  },
  "mod+shift+y": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("checked-list")) toggleList(props.editor, "checked-list", props.event);
    else props.event.preventDefault();
  },
  "mod+shift+x": (props: HOTKEY_ACTION_PROPS) => {
    if (props.allowedFeatures.has("crossed-list")) toggleList(props.editor, "crossed-list", props.event);
    else props.event.preventDefault();
  },
  "shift+Tab": (props: HOTKEY_ACTION_PROPS) => {
    unnestListItem(props.event, props.editor);
  },
  "mod+shift+\\": (props: HOTKEY_ACTION_PROPS) => {
    clearFormatting(props.editor);
  },
  "mod+\\": (props: HOTKEY_ACTION_PROPS) => {
    clearFormatting(props.editor);
  },
  "mod+shift+6": (props: HOTKEY_ACTION_PROPS) => {
    toTitleCase(props.editor, props.event);
  },
  "shift+.": (props: HOTKEY_ACTION_PROPS) => {
    insertArrow(props.editor, props.event);
  },
};

export function executeHotkeyAction(event: React.KeyboardEvent<HTMLElement>, props: Omit<HOTKEY_ACTION_PROPS, "event">) {
  // Find the hotkey action that matches the event.
  const hotkey = Object.keys(HOTKEY_ACTIONS).find((key) => isHotkey(key, event));
  // If a hotkey action is found, execute it.
  if (hotkey) HOTKEY_ACTIONS[hotkey]({ ...props, event });
}

export function executeSingleKeyAction(event: React.KeyboardEvent<HTMLDivElement>, props: Omit<SINGLEKEY_ACTION_PROPS, "event">) {
  if (!props.editor.selection) return;

  // Close the toolbar if it's open
  if (props.controlsState?.toolbar.isOpen) props.handleCloseToolbar?.();

  // Find the hotkey action that matches the event.
  const singleKey = Object.keys(SINGLEKEY_ACTIONS).find((key) => isHotkey(key, event));

  // If a hotkey action is found, execute it.
  if (singleKey) SINGLEKEY_ACTIONS[singleKey]({ ...props, event });

  // Close the slash menu if the user types anything other than a slash
  if (props.controlsState?.slashMenu.isOpen && props.editor.selection.focus.offset === 1 && event.key !== "/") props.handleCloseSlashMenu?.();

  // Update the emoji query if the emoji popover is open
  if (props.controlsState?.emojiPopover.isOpen) {
    // find the previous :: and return the text between them and the cursor and set it as the emoji query
    const currentElementText = (SlateNode.get(props.editor, props.editor.selection.anchor.path) as CustomText).text;
    const indexOfLastDoubleColon = currentElementText?.lastIndexOf("::");
    const indexOfCursor = props.editor.selection?.focus.offset;
    const emojiQuery = currentElementText?.slice(indexOfLastDoubleColon + 2, indexOfCursor) + (event.key.length === 1 ? event.key : "");
    props.setEmojiQuery?.(emojiQuery);
  }
}

export function getTablePaths(editor: CustomEditor): {
  tablePath: Path | undefined;
  rowPath: Path | undefined;
  cellPath: Path | undefined;
} {
  const currentPath = getCurrentPath(editor);
  const tablePath = currentPath?.slice(0, currentPath.length - 4);
  const rowPath = currentPath?.slice(0, currentPath.length - 3);
  const cellPath = currentPath?.slice(0, currentPath.length - 2);

  return {
    tablePath,
    rowPath,
    cellPath,
  };
}

export const getTableData = (editor: CustomEditor, tablePath: Path, rowPath: Path, cellPath: Path) => {
  const tableNode = SlateNode.get(editor, tablePath) as CustomElement;
  const rowNode = SlateNode.get(editor, rowPath) as CustomElement;
  const cellNode = SlateNode.get(editor, cellPath) as CustomElement;

  const rowsInThisTable = tableNode.children.length;
  const columnsInThisTable = rowNode.children.length;
  const cellsInThisRow = rowNode.children.length;
  const cellsInThisColumn = tableNode.children.length;
  const isFirstRow = rowPath[rowPath.length - 1] === 0;
  const isLastRow = rowPath[rowPath.length - 1] === rowsInThisTable - 1;
  const isFirstColumn = cellPath[cellPath.length - 1] === 0;
  const isLastColumn = cellPath[cellPath.length - 1] === columnsInThisTable - 1;

  return {
    rowsInThisTable,
    columnsInThisTable,
    cellsInThisRow,
    cellsInThisColumn,
    isFirstRow,
    isLastRow,
    isFirstColumn,
    isLastColumn,
  };
};

export function moveCursorUp(editor: CustomEditor, event?: React.KeyboardEvent<HTMLDivElement>) {
  if (getParentElement(editor)?.type === "table-cell") {
    if (event) event.preventDefault();
    if (!editor.selection) return;

    // Get paths
    const currentPath = getCurrentPath(editor);
    const tablePath = currentPath?.slice(0, currentPath.length - 4);
    const rowPath = currentPath?.slice(0, currentPath.length - 3);
    const cellPath = currentPath?.slice(0, currentPath.length - 2);
    if (!currentPath || !tablePath || !rowPath || !cellPath) return;

    const cellsInThisRow = (SlateNode.get(editor, rowPath) as CustomElement).children.length;
    const isFirstRow = rowPath[rowPath.length - 1] === 0;

    if (isFirstRow) {
      return;
    } else {
      const prevCellPath = [...tablePath, rowPath[rowPath.length - 1] - 1, cellPath[cellPath.length - 1]];
      Transforms.select(editor, {
        anchor: Editor.end(editor, prevCellPath),
        focus: Editor.end(editor, prevCellPath),
      });
    }
  }
}

export function moveCursorDown(editor: CustomEditor, event?: React.KeyboardEvent<HTMLDivElement>) {
  if (getParentElement(editor)?.type === "table-cell") {
    if (event) event.preventDefault();
    if (!editor.selection) return;

    // Get paths
    const currentPath = getCurrentPath(editor);
    const tablePath = currentPath?.slice(0, currentPath.length - 4);
    const rowPath = currentPath?.slice(0, currentPath.length - 3);
    const cellPath = currentPath?.slice(0, currentPath.length - 2);
    if (!currentPath || !tablePath || !rowPath || !cellPath) return;

    const cellsInThisRow = (SlateNode.get(editor, rowPath) as CustomElement).children.length;
    const rowsInThisTable = (SlateNode.get(editor, tablePath) as CustomElement).children.length;
    const isLastRow = rowPath[rowPath.length - 1] === rowsInThisTable - 1;

    if (isLastRow) {
      return;
    } else {
      const nextCellPath = [...tablePath, rowPath[rowPath.length - 1] + 1, cellPath[cellPath.length - 1]];
      Transforms.select(editor, {
        anchor: Editor.start(editor, nextCellPath),
        focus: Editor.start(editor, nextCellPath),
      });
    }
  }
}

export function insertArrow(editor: CustomEditor, event: HotkeyEvent) {
  const previousCharacter = getPreviousCharacters(editor, 1);
  if (previousCharacter === ">") {
    if (!editor.selection) return;
    event.preventDefault();
    safeDelete(editor, { at: { path: editor.selection.anchor.path, offset: editor.selection.anchor.offset - 1 }, distance: 1 });
    safeInsertText(editor, "→");
  }
}

export function handleColon(editor: CustomEditor, event: HotkeyEvent, handleOpenEmojiPopover?: (event: React.KeyboardEvent<HTMLDivElement>) => void) {
  if (!editor.selection) return;
  // Check if the previous character is a colon
  const previousCharacter = getPreviousCharacters(editor, 1);
  if (previousCharacter === ":") {
    handleOpenEmojiPopover?.(event as React.KeyboardEvent<HTMLDivElement>);
  }
}

export function handleBackspace(
  event: React.KeyboardEvent<HTMLElement>,
  editor: CustomEditor,
  itemId: string,
  supabase: TypedSupabaseClient,
  profile: ClientSideProfile | null,
  section: ClientSideRichSection | undefined,
  controlsState?: SlateControlsState,
  handleCloseEmojiPopover?: () => void,
  revalidator?: ReturnType<typeof useRevalidator>
) {
  if (!editor.selection) return;
  if (controlsState?.emojiPopover.isOpen) {
    const previousCharacter = getPreviousCharacters(editor, 1);
    if (previousCharacter === ":") {
      handleCloseEmojiPopover?.();
    }
  }
  // Check if the column should be deleted
  if (section?.column_method === "GRID" && shouldDeleteGridColumn(event, editor, itemId, supabase, section)) {
    deleteGridColumn(event, editor, itemId, supabase, section, profile, revalidator);
  } else if (
    editor.selection.anchor.offset === 0 &&
    editor.selection.focus.offset === 0 &&
    getParentElement(editor)?.type === "table-cell" &&
    editor.selection.focus.path[editor.selection.focus.path.length - 2] === 0
  ) {
    event.preventDefault();
    const grandparentPath = getGrandparentPath(editor);
    if (!grandparentPath) return;
    const grandparentNode = SlateNode.get(editor, grandparentPath) as CustomElement;
    const rowText = getTextFromDescendant(grandparentNode.children);
    if (rowText === "") {
      Transforms.removeNodes(editor, { at: grandparentPath });
    }
  }
  // Check if the previous element is a divider
  else {
    const currentElement = getCurrentElement(editor);
    if (currentElement?.type === "accordion-button") {
      if (!currentElement) return;
      const currentText = getTextFromDescendant(currentElement.children);
      const currentAccordionItemPath = getParentPath(editor);
      if (currentText === "" || !currentText) {
        event.preventDefault();
        // Remove the accordion button
        safeRemoveNodes(editor, { at: currentAccordionItemPath });
        // Move the cursor to the end of the previous accordion-button
        if (!currentAccordionItemPath) return;
        const previousAccordionItemPath = [currentAccordionItemPath[0] - 1, 0, 0] as Path;
        if (!previousAccordionItemPath) return;
        safeSelect(editor, Editor.end(editor, previousAccordionItemPath));
        return;
      }
    } else if (currentElement?.type === "optin-input") {
      const currentText = getTextFromDescendant(currentElement.children);
      if (currentText === "") {
        event.preventDefault();
        const parentPath = getParentPath(editor);
        safeRemoveNodes(editor, { at: parentPath });
        const previousElementPath = getPreviousPath(editor, 0);
        if (!previousElementPath) return;
        safeSelect(editor, Editor.end(editor, previousElementPath));
      } else {
        const cursorOffset = editor.selection.focus.offset;
        const isCollapsed = Range.isCollapsed(editor.selection);
        if (cursorOffset === 0 && isCollapsed) {
          event.preventDefault();
        }
      }
    } else if (currentElement?.type === "optin-disclaimer" || currentElement?.type === "optin-button") {
      const currentText = getTextFromDescendant(currentElement.children);
      if (currentText === "") {
        event.preventDefault();
      }
    } else {
      const previousElement = getPreviousElement(editor, 1);
      if (previousElement?.type === "divider") {
        const isCollapsed = Range.isCollapsed(editor.selection);
        const cursorOffset = editor.selection.focus.offset;
        if (isCollapsed && cursorOffset === 0) {
          event.preventDefault();
          // Get the path of the previous element
          const previousPath = getPreviousPath(editor, 1);
          if (!previousPath) return;
          // Remove the divider
          safeRemoveNodes(editor, { at: { path: previousPath, offset: 0 } });
        }
      } else if (previousElement?.type === "optin-input") {
        event.preventDefault();
        // Get the path of the previous element
        const previousPath = getPreviousPath(editor, 1);
        if (!previousPath) return;
        const pathToDelete = [previousPath[0]];
        safeRemoveNodes(editor, { at: pathToDelete });
      }
      // Check if the cursor is at the start of the line
      else {
        const currentLocation = getCurrentLocation(editor);
        const isCursorAtStart = currentLocation && currentLocation.offset === 0;
        const isCollapsed = Range.isCollapsed(editor.selection);
        if (!isCursorAtStart || !isCollapsed) return;

        const listLevel = currentLocation.path.length - 2;
        const isNestedList = listLevel > 1;

        const currentPath = Editor.node(editor, editor.selection)[1];
        const isCurrentPathLastIndex0 = currentPath[currentPath.length - 1] === 0;

        // Check if the list item should be unnested
        if (isCurrentPathLastIndex0 && LIST_TYPES.includes(currentElement?.type as ListType)) {
          event.preventDefault();
          const isLastListItem = isLastSibling(editor);
          const isFirstListItem = isFirstSibling(editor);
          const isOnlyListItem = isLastListItem && isFirstListItem;

          if (!isNestedList) {
            // console.log("is not nested list");
            toggleElement("paragraph", editor, event);
          } else {
            // console.log("is nested list");

            // console.log("is not first or last list item");
            Transforms.liftNodes(editor, { at: editor.selection });

            // console.log("check if next item is indented list that needs to be dealth with");

            const currentPath = getCurrentPath(editor);
            if (!currentPath) return;

            const nextPath = currentPath.slice(0, currentPath.length - 1);
            nextPath[nextPath.length - 1] = nextPath[nextPath.length - 1] + 1;

            let nextElementNode: NodeEntry<SlateNode> | undefined;

            try {
              nextElementNode = Editor.node(editor, nextPath);
              if (!nextElementNode) return;
            } catch (error) {
              return;
            }

            const nextElement = nextElementNode[0] as CustomElement;
            if (!nextElement) return;

            if (
              LIST_TYPES.includes(nextElement.type as ListType) &&
              nextElement.type !== "list-item" &&
              LIST_TYPES.includes((nextElement.children[0] as CustomElement).type) &&
              (nextElement.children[0] as CustomElement).type !== "list-item"
            ) {
              const listItemToUnnest = nextElementNode[1].slice();
              listItemToUnnest.push(0);
              Transforms.liftNodes(editor, { at: listItemToUnnest });
            }
          }
        }
        // Backspacing all other types (inc p)
        else {
          const previousElement = getPreviousElement(editor, 1);
          if (!previousElement) return;

          let nextElement: CustomElement | undefined;
          try {
            nextElement = getNextElement(editor, 1);
          } catch (error) {
            //
          }

          const backspacingBetweenTwoSameLists =
            previousElement &&
            nextElement &&
            nextElement.type === previousElement.type &&
            LIST_TYPES.includes(previousElement.type as ListType) &&
            LIST_TYPES.includes(nextElement.type as ListType);

          const lastDescendant = getLastDescendant(previousElement);
          if (!lastDescendant) return;
          const pathOfLastDescendant = ReactEditor.findPath(editor, lastDescendant);
          const parentOfLastDescendant = Editor.parent(editor, pathOfLastDescendant);

          const backspacingIntoEmptyListItem = (parentOfLastDescendant[0] as CustomElement).type === "list-item" && (lastDescendant as CustomText).text === "";

          if (backspacingIntoEmptyListItem && !backspacingBetweenTwoSameLists) {
            // console.log("backspacing into empty but not between lists");
            event.preventDefault();
            Transforms.delete(editor, { distance: 1, unit: "character", reverse: true });
            safeSetNodes(editor, { type: "list-item" });
            return;
          }

          if (backspacingIntoEmptyListItem && backspacingBetweenTwoSameLists) {
            // console.log("backspacing into empty AND between lists");
            event.preventDefault();
            Transforms.delete(editor, { distance: 1, unit: "character", reverse: true });
            safeSetNodes(editor, { type: "list-item" });
            // get the new path of the second list
            const nextElementPath = getNextPath(editor, 1);
            if (!nextElementPath) return;

            const listPath = [nextElementPath[0] + 1];

            // merge the two lists
            Transforms.mergeNodes(editor, { at: listPath });
            return;
          }

          if (backspacingBetweenTwoSameLists && !backspacingIntoEmptyListItem) {
            // console.log("is between two same lists, not into empty");
            event.preventDefault();

            // backspace once to get rid of the paragraph
            safeDelete(editor, { at: editor.selection, distance: 1, unit: "character", reverse: true });

            // get the new path of the second list
            const nextElementPath = getNextPath(editor, 1);
            if (!nextElementPath) return;

            const listPath = [nextElementPath[0] + 1];

            // merge the two lists
            Transforms.mergeNodes(editor, { at: listPath });
            return;
          }
        }
      }
    }
  }
}

const getLastDescendant = (node: Descendant | undefined): SlateNode | undefined => {
  if (!node) return;
  // If the node is a leaf node (no children), return the node itself
  if (!(node as CustomElement).children || (node as CustomElement).children.length === 0) {
    return node;
  }

  // Get the last child of the current node
  const lastChild = (node as CustomElement).children[(node as CustomElement).children.length - 1];

  // Recursively find the last descendant of the last child
  return getLastDescendant(lastChild);
};

export function getSelectedText(editor: CustomEditor): string {
  if (!editor.selection) return "";
  const fragment = (Editor.fragment(editor, editor.selection)[0] as CustomElement).children[0];

  const getDeepestText = (node: CustomElement | CustomText): string => {
    if ("text" in node) return node.text;
    else return getDeepestText(node.children[0]);
  };

  return getDeepestText(fragment);
}

export function clearFormatting(editor: CustomEditor) {
  LEAF_TYPES.forEach((format) => editor.removeMark(format));
}

export function toTitleCase(editor: CustomEditor, event: HotkeyEvent | undefined) {
  if (event) event.preventDefault();
  if (!editor.selection) return;
  const selection = editor.selection;
  // Get the currently selected text
  const selectedText = getSelectedText(editor);
  const titleCaseText = titleCase(selectedText);
  // Replace the selected text with the title case text
  safeInsertText(editor, titleCaseText);
  // Select the title case text
  safeSelect(editor, {
    anchor: selection.anchor,
    focus: selection.focus,
  });
}

export function insertOptin(editor: CustomEditor, event?: HotkeyEvent | undefined) {
  if (event) event.preventDefault();
  if (!editor.selection) return;
  const selection = editor.selection;
  const newOptinElement: CustomElement = {
    type: "optin",
    children: [
      {
        type: "optin-input",
        align: "center",
        variant: "solid",
        children: [
          {
            text: "Email address",
          },
        ],
      },
      {
        type: "optin-button",
        align: "center",
        variant: "solid",
        children: [
          {
            text: "Start Now",
          },
        ],
      },
      {
        type: "optin-disclaimer",
        align: "center",
        variant: "solid",
        children: [
          {
            text: "(Lorem ipsum dolor sit amet non lorem!)",
          },
        ],
      },
    ],
  };
  const currentElement = getCurrentElement(editor);
  if (!currentElement) return;
  const currentElementText = getTextFromDescendant(currentElement.children);
  let insertLocation = { path: selection.anchor.path, offset: selection.anchor.offset };

  safeInsertNodes(editor, newOptinElement, { at: insertLocation });
  if (currentElementText === "") {
    safeRemoveNodes(editor, { at: Editor.range(editor, selection) });
  }
}

export function getCurrentLocation(editor: CustomEditor): { path: Path; offset: number } | undefined {
  if (!editor.selection) return;
  const currentLocation = Editor.unhangRange(editor, editor.selection);
  return currentLocation.focus;
}

export async function deleteGridColumn(
  event: HotkeyEvent | undefined,
  editor: CustomEditor,
  itemId: string,
  supabase: TypedSupabaseClient,
  section: ClientSideRichSection,
  profile: ClientSideProfile | null,
  revalidator?: ReturnType<typeof useRevalidator>
) {
  const { error: deleteBlockError } = await supabase.from("block").delete().eq("id", itemId).single();
  if (deleteBlockError) return console.log({ deleteBlockError });

  // Update the section span
  const hasCrosstext = section?.layout?.includes("crosstext_");
  const newNumberOfColumns = hasCrosstext ? section?.block.length - 2 : section?.block.length - 1;
  const newSectionSpan = 12 / newNumberOfColumns;
  const { data: updatedSection, error: updateSectionError } = await supabase.from("section").update({ span: newSectionSpan }).eq("id", section.id).single();
  if (updateSectionError) return console.log({ updateSectionError });

  mixpanel.identify(profile?.email);
  mixpanel.track("Column Deleted", { "Section ID": section.id, "Section Layout": section.layout, "Version ID": section.version_id });
  // Revalidate the section
  revalidator?.revalidate();
}

export function shouldDeleteGridColumn(
  event: HotkeyEvent | undefined,
  editor: CustomEditor,
  itemId: string,
  supabase: TypedSupabaseClient,
  section: ClientSideRichSection | undefined
) {
  if (!section) return;
  const isGridLayout = section?.column_method === "GRID";
  if (!isGridLayout) return false;
  // const isThreeOrFourColumns = section?.block.length === 3 || section?.block.length === 4;
  // if (!isThreeOrFourColumns) return false;
  const isNotFirstColumn = section?.block[0].id !== itemId;
  if (!isNotFirstColumn) return false;
  const currentContentOfEditor = getTextFromDescendant(editor.children);
  const isCurrentEditorEmpty = currentContentOfEditor === "";
  if (!isCurrentEditorEmpty) return false;

  return true;
}

export function insertTab(event: HotkeyEvent | undefined, editor: CustomEditor, controlsState?: SlateControlsState) {
  if (controlsState?.emojiPopover.isOpen) return;
  const currentElementType = getCurrentElement(editor)?.type;
  if (LIST_TYPES.includes(currentElementType as ListType)) nestListItem(event, editor);
  else if (getParentElement(editor)?.type === "table-cell") tableTab(event, editor);
  else if (currentElementType === "code") insertCodeTab(event, editor);
  else event?.preventDefault();
}

export function tableTab(event: HotkeyEvent | undefined, editor: CustomEditor) {
  if (event) event.preventDefault();
  if (!editor.selection) return;

  // Get paths
  const currentPath = getCurrentPath(editor);
  const tablePath = currentPath?.slice(0, currentPath.length - 4);
  const rowPath = currentPath?.slice(0, currentPath.length - 3);
  const cellPath = currentPath?.slice(0, currentPath.length - 2);
  if (!currentPath || !tablePath || !rowPath || !cellPath) return;

  const cellsInThisRow = (SlateNode.get(editor, rowPath) as CustomElement).children.length;
  const isLastCellInRow = cellPath[cellPath.length - 1] === cellsInThisRow - 1;

  try {
    if (isLastCellInRow) {
      const nextRowPath = [...tablePath, rowPath[rowPath.length - 1] + 1, 0, 0, 0];
      Transforms.select(editor, {
        anchor: { path: nextRowPath, offset: 0 },
        focus: { path: nextRowPath, offset: 0 },
      });
    } else {
      const nextCellPath = [...rowPath, cellPath[cellPath.length - 1] + 1, 0, 0];
      Transforms.select(editor, {
        anchor: { path: nextCellPath, offset: 0 },
        focus: { path: nextCellPath, offset: 0 },
      });
    }
  } catch (error) {
    // console.log({ error });
  }

  // console.log({ currentPath, tablePath, rowPath, cellPath });
}

export function insertCodeTab(event: HotkeyEvent | undefined, editor: CustomEditor) {
  if (event) event.preventDefault();
  if (!editor.selection) return;
  const previousCharacters = getPreviousCharacters(editor, 1);
  if (!previousCharacters) return;
  if (previousCharacters === "\n" || previousCharacters === " ") {
    safeInsertText(editor, "    ");
    return;
  }
}

export function nestListItem(event: HotkeyEvent | undefined, editor: CustomEditor) {
  if (event) event.preventDefault();
  if (!editor.selection) return;
  const isListItem = isElementActive(editor, "list-item");
  const currentPath = getCurrentPath(editor);
  const isFirstSibling = currentPath && currentPath[currentPath.length - 2] === 0;
  // Only continue if list item and not first item
  if (!isListItem || isFirstSibling) return;
  // wrap the current list item in a new list
  const parentElementType = getParentElement(editor)?.type;

  if (!parentElementType) return;

  // check if the previous item is a already list
  const previousElement = getPreviousElement(editor, 1);
  if (!previousElement) return;

  if (LIST_TYPES.includes(previousElement.type as ListType) && previousElement.type !== "list-item") {
    // move the current list item to the end of the previous list
    if (!currentPath) return;
    const newLocation = currentPath.slice(0, currentPath.length - 1);
    newLocation[newLocation.length - 1] = newLocation[newLocation.length - 1] - 1;
    newLocation.push(previousElement.children.length);
    Transforms.moveNodes(editor, { at: editor.selection, to: newLocation });
    return;
  }

  safeWrapNodes(editor, { type: parentElementType, children: [] });
}

export function unnestListItem(event: HotkeyEvent | undefined, editor: CustomEditor) {
  if (event) event.preventDefault();
  if (!editor.selection) return;
  const isListItem = isElementActive(editor, "list-item");
  if (!isListItem) return;

  const currentPath = getCurrentPath(editor);
  if (!currentPath) return;
  const listLevel = currentPath.length - 2;

  if (listLevel === 1) {
    toggleElement("paragraph", editor, event);
  } else if (listLevel > 1) {
    Transforms.liftNodes(editor, { at: editor.selection });
  }
}

export function toggleList(editor: CustomEditor, listType: ListType, event?: HotkeyEvent | undefined) {
  if (event) event.preventDefault();
  if (!editor.selection) return;

  const isList = isElementActive(editor, listType);
  const isListItem = isElementActive(editor, "list-item");
  const parentElement = getParentElement(editor);
  const isParentSameType = parentElement?.type === listType;
  const numberOfSiblings = parentElement?.children.length;
  const currentPath = getCurrentPath(editor);
  const parentPath = getParentPath(editor);
  const isFirstSibling = currentPath && currentPath[currentPath.length - 2] === 0;

  if (!currentPath) return;
  const listLevel = currentPath.length - 2;

  // Is it already a list item of the same type?
  if (isListItem && isParentSameType) {
    // console.log("list item of same type");
    if (listLevel === 1) {
      // unwrap the current listitem
      safeSetNodes(editor, { type: "paragraph" });
      // unwrap this item from the list
      safeUnwrapNodes(editor, { match: (n) => (n as CustomElement).type === listType, split: true });
      return;
    } else if (listLevel > 1) {
      // Transforms.liftNodes(editor, { at: editor.selection });
      return;
    }
  }

  // Is it already a list item but not of the same type?
  if (isListItem && !isParentSameType) {
    // console.log("list item but not of same type");
    safeSetNodes(editor, { type: listType }, { at: parentPath });
    return;
  }

  const previousElementType = getPreviousElement(editor, 1)?.type;
  const nextElementType = getNextElementType(editor, 1);
  const isAfterList = isPreviousElementList(editor);
  const isAfterSameList = previousElementType === listType;
  const isBeforeList = isNextElementList(editor);
  const isBeforeSameList = nextElementType === listType;

  // If it's not already a list or next to one, convert it
  if (!isListItem && !isAfterList && !isBeforeList) {
    // console.log("not a list or next to one");
    safeWrapNodes(editor, { type: listType, children: [] });
    safeSetNodes(editor, { type: "list-item", as: null });
    return;
  }

  // If it's not a list but is right after a same type...
  if (!isListItem && isAfterSameList) {
    // console.log("not a list but is right after a same type");
    const previousElementType = getPreviousElement(editor, 1)?.type;
    if (!previousElementType) return;
    // If the previous element is a list, get this element ready to append
    if (previousElementType === listType) {
      safeSetNodes(editor, { type: "list-item" });
      const currentElement = getCurrentElement(editor);
      const currentElementPath = getCurrentPath(editor);
      if (!currentElementPath || !currentElement) return;
      // Get the path of the last item in the previous list
      const previousElement = getPreviousElement(editor, 1);
      const previousPath = getPreviousPath(editor, 1);
      if (!previousPath || !previousElement) return;
      const previousListPath = previousPath.slice(0, previousPath.length - 1);
      const previousListLastItemPath = [...previousListPath, previousElement.children.length];
      // Move the current element to the end of the previous list
      safeMoveNodes(editor, { at: [currentElementPath[0]], to: previousListLastItemPath });
      // Get the next element (after the original current element)
      const nextListPath = previousListPath;
      nextListPath[nextListPath.length - 1] = previousListPath[previousListPath.length - 1] + 1;
      const nextElement = getElementFromPath(editor, nextListPath);
      const nextElementType = nextElement?.type;
      if (!nextListPath || !nextElement || !nextElementType) return;
      // If the next element is a list
      if (nextElementType === listType) {
        // for each child in this list, move it to the end of the previous list
        nextElement.children.forEach((child, index) => {
          if (SlateElement.isElement(child)) {
            const childPath = [...nextListPath, 0];
            const moveTo = [...previousListLastItemPath];
            moveTo[moveTo.length - 1] = moveTo[moveTo.length - 1] + index + 1;
            safeMoveNodes(editor, { at: childPath, to: moveTo });
          }
        });
        // remove the next list
        safeRemoveNodes(editor, { at: nextListPath });
        return;
      }
      return;
    }
  }

  // If it's not a list but is right before one of the same type...
  if (!isList && !isListItem && isBeforeSameList) {
    // console.log("not a list but is right before one of the same type");
    // Make this item a list item and prepend it to the start of the next list
    const nextElement = getNextElement(editor, 1);
    const nextPath = getNextPath(editor, 1);
    if (!nextPath || !nextElement) return;
    const nextListPath = nextPath;
    safeSetNodes(editor, { type: "list-item" });
    // move this element so that it insert before the first item in the next list
    const currentElementPath = getCurrentPath(editor);
    if (!currentElementPath) return;
    safeMoveNodes(editor, { at: [currentElementPath[0]], to: nextListPath });
    return;
  }

  // If it's not a list but is right after a different type...
  if (!isListItem && isAfterList && !isAfterSameList) {
    // console.log("not a list but is right after a different type");
    // Make this item a list item and wrap it in a list
    safeWrapNodes(editor, { type: listType, children: [] });
    safeSetNodes(editor, { type: "list-item" });
    return;
  }

  // If it's not a list but is right before a different type...
  if (!isListItem && isBeforeList && !isBeforeSameList) {
    // console.log("not a list but is right before a different type");
    // Make this item a list item and wrap it in a list
    safeWrapNodes(editor, { type: listType, children: [] });
    safeSetNodes(editor, { type: "list-item" });
    return;
  }
}

export function getElementFromPath(editor: CustomEditor, path: Path): CustomElement | undefined {
  const element = Editor.node(editor, path);
  return element[0] as CustomElement;
}

export function getParentPath(editor: CustomEditor): Path | undefined {
  if (!editor.selection) return;
  try {
    const currentNode = Editor.parent(editor, editor.selection);
    const parentNode = Editor.parent(editor, currentNode[1]);
    return parentNode[1];
  } catch (error) {
    return undefined;
  }
}

export function getGrandparentPath(editor: CustomEditor): Path | undefined {
  if (!editor.selection) return;
  try {
    const currentNode = Editor.parent(editor, editor.selection);
    const parentNode = Editor.parent(editor, currentNode[1]);
    const grandparentNode = Editor.parent(editor, parentNode[1]);
    return grandparentNode[1];
  } catch (error) {
    return undefined;
  }
}

export function isPreviousElementList(editor: CustomEditor): boolean | undefined {
  if (!editor.selection) return;
  const previousElementType = getPreviousElement(editor, 1)?.type;
  if (!previousElementType) return;
  return LIST_TYPES.includes(previousElementType);
}

export function isNextElementList(editor: CustomEditor): boolean | undefined {
  if (!editor.selection) return;
  const nextElementType = getNextElementType(editor, 1);
  if (!nextElementType) return;
  return LIST_TYPES.includes(nextElementType);
}

export function isMarkActive(editor: CustomEditor, format: Format) {
  const marks = Editor.marks(editor) as { [key: string]: boolean } | null;
  return marks ? marks[format] === true : false;
}

export function isElementActive(editor: CustomEditor, elementType: ElementType) {
  const { selection } = editor;
  if (!selection) return false;
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n["type"] === elementType,
    })
  );
  return !!match;
}

export function isRangeCollapsed(editor: CustomEditor) {
  if (!editor.selection) return true;
  return Range.isCollapsed(editor.selection);
}

export function insertText(event: HotkeyEvent | undefined, editor: CustomEditor, text: string) {
  if (event) event.preventDefault();
  if (!editor.selection) return;
  safeInsertText(editor, text);
}

export function checkTimestamps(
  event: React.KeyboardEvent<HTMLElement>,
  editor: CustomEditor,
  setTimestamps: React.Dispatch<React.SetStateAction<Map<number, { startTime: string; endTime: string }>>>,
  wpm: number
) {
  const currentText = (getCurrentElement(editor)?.children[0] as CustomText).text;
  const currentTextLength = currentText.length;
  if (
    currentTextLength === 0 ||
    (editor.selection &&
      (isHotkey("space", event) ||
        isHotkey("enter", event) ||
        isHotkey("backspace", event) ||
        isHotkey("mod+z", event) ||
        isHotkey("mod+shift+z", event) ||
        isHotkey("mod+shift+h", event) ||
        isHotkey("mod+shift+c", event) ||
        isHotkey("mod+shift+p", event)))
  ) {
    calculateAllTimestamps(editor.children, setTimestamps, wpm);
  }
}

export function insertSpace(event: HotkeyEvent | undefined, editor: CustomEditor) {
  if (!editor.selection) return;

  // Insert a non-breaking space if the previous character is a space
  const previousCharacter = getPreviousCharacters(editor, 1);
  if (!previousCharacter) return;

  if (previousCharacter === " ") {
    event && event.preventDefault();
    safeInsertText(editor, "\u00A0");
  }

  //Insert a non-breaking space if this is a button element
  else {
    const currentElementType = getCurrentElement(editor)?.type;
    if (currentElementType === "button" || currentElementType === "optin-button" || getParentElement(editor)?.type === "table-cell") {
      event && event.preventDefault();
      safeInsertText(editor, "\u00A0");
      return;
    }
  }
}

export function insertEnter(event: React.KeyboardEvent<HTMLElement>, editor: CustomEditor) {
  const currentElement = getCurrentElement(editor);
  const { selection } = editor;
  if (!currentElement || !selection) return;
  if (currentElement.type === "button") insertButtonEnter(event, editor);
  else if (LIST_TYPES.includes(currentElement.type as ListType)) insertListEnter(event, editor, currentElement);
  else if (currentElement.type === "accordion-button") insertAccordionButtonEnter(event, editor);
  else if (getParentElement(editor)?.type === "table-cell") insertTableEnter(event, editor, currentElement);
  else if (getParentElement(editor)?.type === "accordion-panel") insertAccordionPanelEnter(event, editor, currentElement);
  else if (currentElement.type === "code" || currentElement.type === "heading" || currentElement.type === "blockquote" || currentElement.type === "callout") {
    insertParagraph(event, editor, currentElement);
    if (selection.anchor.offset === 0 && selection.focus.offset === 0) {
      let newCursorPath = Editor.path(editor, selection);

      newCursorPath = [newCursorPath[0] + 1, 0];

      Transforms.select(editor, { path: newCursorPath, offset: 0 });
    }
  }
}

export function insertTableEnter(event: React.KeyboardEvent<HTMLElement>, editor: CustomEditor, currentElement: CustomElement) {
  if (!editor.selection) return;
  const currentText = getTextFromDescendant(currentElement.children);

  const { tablePath, rowPath, cellPath } = getTablePaths(editor);
  if (!tablePath || !rowPath || !cellPath) return;

  const { cellsInThisRow, isLastColumn, isLastRow } = getTableData(editor, tablePath, rowPath, cellPath);

  if (currentText === "" && isLastColumn && isLastRow) {
    event.preventDefault();
    Transforms.insertNodes(
      editor,
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
      { at: [tablePath[0] + 1] }
    );
    Transforms.select(editor, Editor.start(editor, [tablePath[0] + 1]));
  }
}

export function insertListEnter(event: React.KeyboardEvent<HTMLElement>, editor: CustomEditor, currentElement: CustomElement) {
  if (!editor.selection) return;

  const currentElementText = getTextFromDescendant(currentElement.children);
  if (currentElementText === "") {
    event.preventDefault();
    const currentPath = getCurrentPath(editor);
    if (!currentPath) return;

    const listLevel = currentPath.length - 2;

    if (listLevel === 1) {
      toggleElement("paragraph", editor, event);
    } else if (listLevel > 1) {
      Transforms.liftNodes(editor, { at: editor.selection });
    }
  }
}

export function insertAccordionButtonEnter(event: HotkeyEvent, editor: CustomEditor) {
  if (!editor.selection) return;
  event.preventDefault();

  const parentElement = getParentPath(editor);
  if (!parentElement) return;
  const newPath = [parentElement[parentElement.length - 1] + 1];

  insertAccordionItem(newPath as Path, editor);
}

export function insertAccordionItem(newPath: Path, editor: CustomEditor) {
  safeInsertNodes(
    editor,
    [
      {
        type: "accordion-item",
        children: [
          {
            type: "accordion-button",
            children: [{ text: "" }],
          },
          {
            type: "accordion-panel",
            children: [{ type: "paragraph", children: [{ text: "" }] }],
          },
        ],
      },
    ],
    { at: newPath }
  );
  // move the cursor to the start of the new accordion button
  safeSelect(editor, {
    anchor: { path: [newPath[0], 0, 0], offset: 0 },
    focus: { path: [newPath[0], 0, 0], offset: 0 },
  });
}

export function insertAccordionPanelEnter(event: HotkeyEvent, editor: CustomEditor, currentElement: CustomElement) {
  if (!editor.selection) return;
  const currentText = getTextFromDescendant(currentElement.children);
  if (!currentText || currentText === "") {
    event.preventDefault();
    const currentAccordionItemPath = getGrandparentPath(editor);
    if (!currentAccordionItemPath) return;
    const newPath = [currentAccordionItemPath[0] + 1] as Path;
    safeRemoveNodes(editor, { at: editor.selection });
    insertAccordionItem(newPath, editor);
  }
}

export function insertButtonEnter(event: HotkeyEvent, editor: CustomEditor) {
  if (!editor.selection) return;
  event.preventDefault();
  const previousElementType = getPreviousElement(editor, 1)?.type;
  const currentElementText = getCurrentElementText(editor);

  // If it's the second button and empty, convert to paragraph
  if (previousElementType === "button" && currentElementText === "") {
    safeSetNodes(editor, { type: "paragraph", variant: null });
  }
  // If it's the second button and not empty, insert a new paragraph
  else if (previousElementType === "button" && currentElementText !== "") {
    safeInsertNodes(editor, [
      {
        type: "paragraph",
        variant: null,
        children: [{ text: "" }],
      },
    ]);
  }
  // If it's the first button, insert new button of different variant
  else {
    const currentElementVariant = getCurrentElement(editor)?.variant;
    const currentElementVariantIndex = currentElementVariant ? BUTTON_VARIANTS.indexOf(currentElementVariant) : -1;
    const nextElementVariantIndex = currentElementVariantIndex + 1;
    const nextElementVariant = nextElementVariantIndex < BUTTON_VARIANTS.length ? BUTTON_VARIANTS[nextElementVariantIndex] : BUTTON_VARIANTS[0];
    safeInsertNodes(editor, [
      {
        type: "button",
        variant: nextElementVariant as ButtonType,
        children: [{ text: "" }],
      },
    ]);
  }
}

export function insertParagraph(event: HotkeyEvent | undefined, editor: CustomEditor, currentElement: CustomElement) {
  if (!editor.selection) return;
  if (event) event.preventDefault();

  safeInsertNodes(editor, [
    {
      type: "paragraph",
      children: [{ text: "" }],
      align: currentElement.align,
    },
  ]);
}

export function insertTable(editor: CustomEditor, event?: HotkeyEvent | undefined) {
  if (event) event.preventDefault();
  const { selection } = editor;
  if (!selection) return;

  const tableElement: CustomElement = {
    type: "table",
    children: [
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              {
                type: "paragraph",
                children: [
                  {
                    text: "",
                  },
                ],
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              {
                type: "paragraph",
                children: [
                  {
                    bold: true,
                    text: "Column A",
                  },
                ],
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              {
                type: "paragraph",
                children: [
                  {
                    bold: true,
                    text: "Column B",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              {
                type: "paragraph",
                children: [
                  {
                    bold: true,
                    text: "Row 1",
                  },
                ],
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              {
                type: "paragraph",
                children: [
                  {
                    text: "",
                  },
                ],
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              {
                type: "paragraph",
                children: [
                  {
                    text: "",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              {
                type: "paragraph",
                children: [
                  {
                    bold: true,
                    text: "Row 2",
                  },
                ],
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              {
                type: "paragraph",
                children: [
                  {
                    text: "",
                  },
                ],
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              {
                type: "paragraph",
                children: [
                  {
                    text: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  safeInsertNodes(editor, [tableElement]);

  // Move the cursor into cell B2
  const tablePath = [selection.anchor.path[0] + 1];
  const cellA1Path = [tablePath[0], 0, 0];
  Transforms.select(editor, Editor.start(editor, cellA1Path));

  if (event?.type === "keydown") {
  }

  if (getTextFromDescendant([SlateNode.get(editor, selection.anchor.path)] as Descendant[]) === "") {
    Transforms.removeNodes(editor, { at: selection });
  }
}

export function insertDivider(editor: CustomEditor, event?: HotkeyEvent | undefined) {
  // If there's no event (slash menu click)
  if (!event) {
    try {
      safeInsertNodes(editor, [
        {
          type: "divider",
          children: [{ text: "" }],
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  }
  // If there's an event (hotkey)
  else if (event) event.preventDefault();

  if (!editor.selection) return;
  // Get relevant paths and content
  const currentPath = getCurrentPath(editor);
  const nextPath = getNextPath(editor, 1);
  const parentElement = getParentElement(editor);
  const currentElementNoText = getCurrentElementText(editor) === "";
  if (!currentPath || !nextPath || !parentElement) return;
  // Main element to insert
  let newElements: CustomElement[] = [
    {
      type: "divider",
      children: [{ text: "" }],
    },
  ];
  // Where to insert the new elements
  const newPath = [nextPath[nextPath.length - 2]];
  let endCursorLocation = editor.selection.anchor;
  // Check if we're inserting at the end of the parent element
  if (isLastSibling(editor) || currentElementNoText) {
    newElements.push({
      type: "paragraph",
      variant: null,
      as: null,
      children: [{ text: "" }],
    });
    endCursorLocation = { path: [newPath[0] + 1, 0], offset: 0 };
  }
  // // Check if we're inserting from a blank element
  // if (currentElementNoText) {
  //   endCursorLocation = { path: [newPath[0] + 1, 0], offset: 0 };
  // }
  // Insert the new elements
  safeInsertNodes(editor, newElements, { at: newPath });
  // Move the cursor to the right place
  safeSelect(
    editor,
    Editor.start(editor, {
      anchor: endCursorLocation,
      focus: endCursorLocation,
    })
  );
  // Remove the current element if it's empty
  if (currentElementNoText) {
    safeRemoveNodes(editor, { at: [currentPath[0]] });
  }
}

export function isLastSibling(editor: CustomEditor): boolean | undefined {
  if (!editor.selection) return;
  const currentPath = getCurrentPath(editor);
  if (!currentPath) return;
  const parentElement = getParentElement(editor);
  if (!parentElement) return;
  const numberOfSiblings = parentElement.children.length;
  const penultimatePart = currentPath[currentPath.length - 2];
  const isLastSibling = penultimatePart + 1 >= numberOfSiblings;
  return isLastSibling;
}

export function isFirstSibling(editor: CustomEditor): boolean | undefined {
  if (!editor.selection) return;
  const currentPath = getCurrentPath(editor);
  if (!currentPath) return;
  const parentElement = getParentElement(editor);
  if (!parentElement) return;
  const isFirstSibling = currentPath[currentPath.length - 2] === 0;
  return isFirstSibling;
}

export function getParentElement(editor: CustomEditor): CustomElement | undefined {
  if (!editor.selection) return;
  try {
    const currentNode = Editor.parent(editor, editor.selection);
    const parentNode = Editor.parent(editor, currentNode[1]);
    return parentNode[0] as CustomElement;
  } catch (error) {
    return undefined;
  }
}

export function getCurrentElementText(editor: CustomEditor): string | undefined {
  if (!editor.selection) return;
  const [match] = Array.from(
    Editor.nodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n),
    })
  );
  const currentElement = match[0] as CustomElement;
  const getText = (children: Descendant[]): string => {
    let text = "";
    children.forEach((child) => {
      if (Text.isText(child)) text += child.text;
      else if (SlateElement.isElement(child)) text += getText(child.children);
    });
    return text;
  };
  return getText(currentElement.children);
}

export function toggleMark(editor: CustomEditor, format: Format, event?: HotkeyEvent | undefined) {
  if (event) event.preventDefault();
  if (!editor.selection) return;
  const isActive = isMarkActive(editor, format);
  const isCollapsed = isRangeCollapsed(editor);
  // Highlighted text?
  if (!isCollapsed) {
    // Remove all colors if format is a color
    if (colors.includes(format)) colors.forEach((color) => editor.removeMark(color));
    // Remove sub/sup if format is sub/sup
    Array.from(["sub", "sup"]).forEach((format) => editor.removeMark(format));
    // Apply format to the highlighted text
    safeSetNodes(editor, { [format]: isActive ? null : true }, { match: (n) => Text.isText(n), split: true });
  }
  // No text highlighted?
  else {
    if (isActive) Editor.removeMark(editor, format);
    else Editor.addMark(editor, format, true);
  }
}

export function insertMark(editor: CustomEditor, format: Format) {
  Editor.addMark(editor, format, true);
}

export function removeComment(editor: Editor, format: string) {
  const descendants = editor.children as Descendant[];
  const pathsToRemove: Path[] = [];
  function processChildren(children: Descendant[]) {
    children.forEach((child) => {
      if ((child as CustomElement).children) {
        processChildren((child as CustomElement).children);
      } else if ((child as CustomText).text) {
        Object.keys(child).forEach((key) => {
          if (key === format) {
            const childPath = ReactEditor.findPath(editor, child);
            pathsToRemove.push(childPath);
          }
        });
      }
    });
  }
  processChildren(descendants);
  pathsToRemove.forEach((path) => {
    safeUnsetNodes(editor, format, { at: path });
  });
}

export function removeColor(editor: CustomEditor, event?: HotkeyEvent | undefined) {
  if (event) event.preventDefault();
  if (!editor.selection) return;
  colors.forEach((color) => editor.removeMark(color));
}

export function isDefaultColor(editor: CustomEditor): boolean | undefined {
  if (!editor.selection) return;
  const marks = Editor.marks(editor) as { [key: string]: boolean } | null;
  if (!marks) return;
  const isDefaultColor = marks["color"] === undefined;
  return isDefaultColor;
}

export function toggleElement(elementType: ElementType, editor: CustomEditor, event?: HotkeyEvent | undefined) {
  if (event) event.preventDefault();
  if (!editor.selection) return;

  // Check if we're toggling from a list type to a non-list type
  if (shouldUnwrapList(editor, elementType)) {
    // console.log("should unwrap list");
    const multipleElementsSelected = isMultipleElementsSelected(editor);
    if (!multipleElementsSelected) {
      // check if it needs lifting out of a nested list
      const currentPath = getCurrentPath(editor);
      if (!currentPath) return;

      const listLevel = currentPath.length - 2;

      if (listLevel === 1) {
        safeUnwrapNodes(editor, {
          match: (n) =>
            (n as CustomElement).type === "bulleted-list" ||
            (n as CustomElement).type === "numbered-list" ||
            (n as CustomElement).type === "checked-list" ||
            (n as CustomElement).type === "crossed-list",
          split: true,
        });
      } else if (listLevel > 1) {
        // console.log("should lift out of nested list");
        const listPath = currentPath.slice(0, currentPath.length - 1); // assuming list path is just one less than the list item
        const isFirstItem = listPath[listLevel] === 0;
        if (!isFirstItem) {
          Transforms.splitNodes(editor, { at: listPath, always: true });
        }
        Transforms.liftNodes(editor);
        Transforms.liftNodes(editor);
      }
      if (listLevel > 2) {
        for (let i = 0; i < listLevel - 2; i++) {
          Transforms.liftNodes(editor);
        }
      }
    } else {
      // console.log("should unwrap list but multiple elements selected");

      // 1. Identify the parent list
      const [parentListNode, parentListPath] = Editor.parent(editor, editor.selection);
      if (LIST_TYPES.includes((parentListNode as CustomElement).type as ListType)) {
        return; // exit if not a list
      }

      // 2. Break the list
      // Lift the items out of the list temporarily, so you can split and convert them.
      const listItemPaths = Array.from(
        Editor.nodes(editor, {
          at: editor.selection,
          match: (n) => (n as CustomElement).type === "list-item",
        }),
        ([_, path]) => [...path]
      );
      for (const path of listItemPaths.reverse()) {
        Transforms.liftNodes(editor, { at: path });
      }

      // 3. Unwrap Selected Items
      // Now, each lifted item should be a separate list. Convert them to paragraphs.

      for (const path of listItemPaths.reverse()) {
        safeUnwrapNodes(editor, { at: path, split: true });
      }

      // 4. Merge Lists
      // Re-merge adjacent lists
      Transforms.mergeNodes(editor, { at: parentListPath, match: (n) => (n as CustomElement).type === (parentListNode as CustomElement).type });
    }
  }
  // Toggle the element type on/off depending on whether it's already active
  const isActive = isElementActive(editor, elementType);
  safeSetNodes(editor, { type: isActive ? "paragraph" : elementType });

  // Check if the next item is a list and it begins with a nested list
  // If so, the first item in the nested list should be unwrapped
  // (No uls that don't appear to be children of lis)
  const nextElement = getNextElement(editor, 1);
  if (!nextElement) return;
  const nextElementIsNestedList = LIST_TYPES.includes(nextElement.type as ListType);
  if (!nextElementIsNestedList) return;
  const nextListFirstChildIsList =
    LIST_TYPES.includes((nextElement.children[0] as CustomElement).type as ListType) && (nextElement.children[0] as CustomElement).type !== "list-item";
  if (!nextListFirstChildIsList) return;
  const currentPath = getCurrentPath(editor);
  if (!currentPath) return;
  const nextListFirstChildPath = currentPath.slice(0);
  nextListFirstChildPath[nextListFirstChildPath.length - 2] = nextListFirstChildPath[nextListFirstChildPath.length - 2] + 1;
  nextListFirstChildPath.push(0);

  Transforms.liftNodes(editor, { at: nextListFirstChildPath });
}

export function shouldUnwrapList(editor: CustomEditor, elementType: ElementType) {
  const currentElementType = getCurrentElement(editor)?.type;
  const wasListType = LIST_TYPES.includes(currentElementType as ListType);
  const nowListType = LIST_TYPES.includes(elementType as ListType);
  return wasListType && !nowListType;
}

export function toggleButton(editor: CustomEditor, event?: HotkeyEvent | undefined) {
  if (event) event.preventDefault();
  if (!editor.selection) return;
  const currentElement = getCurrentElement(editor);
  if (!currentElement) return;
  const currentElementType = getCurrentElement(editor)?.type;
  if (currentElementType !== "button") {
    safeSetNodes(editor, { type: "button", variant: BUTTON_VARIANTS[0] as ButtonType });
  } else {
    const currentVariant = currentElement["variant"];
    const currentVariantIndex = currentVariant ? BUTTON_VARIANTS.indexOf(currentVariant) : -1;
    const nextVariantIndex = currentVariantIndex + 1;
    const nextVariant = nextVariantIndex < BUTTON_VARIANTS.length ? BUTTON_VARIANTS[nextVariantIndex] : null;
    if (!nextVariant) safeSetNodes(editor, { type: "paragraph", variant: null });
    else safeSetNodes(editor, { type: "button", variant: nextVariant as ButtonType });
  }
}

export function toggleTable(editor: CustomEditor, event?: HotkeyEvent | undefined) {
  if (event) event.preventDefault();
  if (!editor.selection) return;
  const currentElement = getCurrentElement(editor);
  if (!currentElement) return;
  const currentElementType = currentElement.type;
  if (currentElementType !== "table") {
    insertTable(editor);
  }
}

export function toggleHeading(editor: CustomEditor, event: HotkeyEvent | undefined, as?: HeadingType) {
  if (event) event.preventDefault();
  if (!editor.selection) return;

  // Check if we're toggling from a list type to a non-list type
  if (shouldUnwrapList(editor, "heading")) {
    safeUnwrapNodes(editor, {
      match: (n) =>
        (n as CustomElement).type === "bulleted-list" ||
        (n as CustomElement).type === "numbered-list" ||
        (n as CustomElement).type === "checked-list" ||
        (n as CustomElement).type === "crossed-list",
      split: true,
    });
  }

  // If as is provided, set the element to that heading level
  if (as) return safeSetNodes(editor, { type: "heading", as });

  // If as is not provided, cycle through the heading levels
  const currentElementAs = getCurrentElement(editor)?.as;
  // console.log({ currentElementAs });
  const currentAsIndex = currentElementAs ? HEADING_TYPES.indexOf(currentElementAs) : -1;
  // console.log({ currentAsIndex });
  const nextAsIndex = currentAsIndex + 1;
  // console.log({ nextAsIndex });
  const nextElementAs = nextAsIndex < HEADING_TYPES.length ? HEADING_TYPES[nextAsIndex] : null;
  // console.log({ nextElementAs });
  if (!nextElementAs) safeSetNodes(editor, { type: "paragraph", as: null });
  else safeSetNodes(editor, { type: "heading", as: nextElementAs });
}

export function toggleAlignment(event: React.KeyboardEvent<HTMLElement> | undefined, editor: CustomEditor) {
  if (event) event.preventDefault();
  if (!editor.selection) return;
  const currentElement = getCurrentElement(editor);
  if (!currentElement) return;
  const direction = event?.key === "," ? -1 : 1;
  const currentAlignment = currentElement["align"];
  const currentAlignmentIndex = currentAlignment ? ALIGN_TYPES.indexOf(currentAlignment) : 0;
  const nextAlignmentIndex = currentAlignmentIndex + direction;
  const nextAlignment =
    nextAlignmentIndex >= ALIGN_TYPES.length ? ALIGN_TYPES[0] : nextAlignmentIndex < 0 ? ALIGN_TYPES[ALIGN_TYPES.length - 1] : ALIGN_TYPES[nextAlignmentIndex];
  safeSetNodes(editor, { align: nextAlignment as AlignType });
  const parentPath = getParentPath(editor);
  if (!parentPath) return;
  safeSetNodes(editor, { align: nextAlignment as AlignType }, { at: parentPath });
}

export function setAlignment(editor: CustomEditor, align: AlignType) {
  if (!editor.selection) return;
  safeSetNodes(editor, { align });
}

export function wrapText(event: HotkeyEvent | undefined, editor: CustomEditor, wrapType: WrapType) {
  if (!editor.selection || isRangeCollapsed(editor)) return;
  if (event) event.preventDefault();
  const { selection } = editor;

  const anchorBeforeFocus = isAnchorBeforeFocus(editor);
  let innerString = Editor.string(editor, selection);
  let newString = "";
  if (wrapType === "quote-marks") newString = `"${innerString}"`;
  else if (wrapType === "curly-brackets") newString = `{${innerString}}`;
  else if (wrapType === "square-brackets") newString = `[${innerString}]`;
  else if (wrapType === "parentheses") newString = `(${innerString})`;
  else if (wrapType === "angle-brackets") newString = `<${innerString}>`;
  else if (wrapType === "apostrophes") newString = `'${innerString}'`;
  else if (wrapType === "asterisks") newString = `*${innerString}*`;
  else if (wrapType === "tilde") newString = `~${innerString}~`;
  else if (wrapType === "underscore") newString = `_${innerString}_`;
  safeInsertText(editor, newString, { at: selection });
  // Highlight the new string including the wrap marks
  const newAnchorOffset = anchorBeforeFocus ? selection.anchor.offset : selection.anchor.offset + 2;
  const newFocusOffset = anchorBeforeFocus ? selection.focus.offset + 2 : selection.focus.offset;
  safeSelect(editor, {
    anchor: { path: selection.anchor.path, offset: newAnchorOffset },
    focus: { path: selection.focus.path, offset: newFocusOffset },
  });
}

export function getPreviousElement(editor: CustomEditor, count: number): CustomElement | undefined {
  if (!editor.selection) return;
  try {
    const previousPath = getPreviousPath(editor, count);
    if (!previousPath) return;
    const previousPathLastSliced = previousPath.slice(0, previousPath.length - 1);
    const element = Editor.node(editor, previousPathLastSliced);
    if (!element) return;
    return element[0] as CustomElement;
  } catch (error) {
    console.error(error);
    return;
  }
}

export function getNextElement(editor: CustomEditor, count: number): CustomElement | undefined {
  if (!editor.selection) return;
  const nextPath = getNextPath(editor, count);
  if (!nextPath) return;
  const [match] = Array.from(
    Editor.nodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n),
      at: Editor.unhangRange(editor, { anchor: { path: nextPath, offset: 0 }, focus: { path: nextPath, offset: 0 } }),
    })
  );
  if (!match) return;
  return match[0] as CustomElement;
}

export function getCurrentPath(editor: CustomEditor): Path | undefined {
  if (!editor.selection) return;
  const currentLocation = Editor.unhangRange(editor, editor.selection);
  return currentLocation.anchor.path;
}

export function getPreviousPath(editor: CustomEditor, count: number): Path | undefined {
  if (!editor.selection) return;
  try {
    const currentLocation = Editor.unhangRange(editor, editor.selection);
    const currentPath = currentLocation.anchor.path;
    const penultimatePart = currentPath[currentPath.length - 2];
    const previousPart = currentPath[currentPath.length - 3];
    const remainder = count - penultimatePart;
    if (remainder > 0) {
      const newPreviousPart = previousPart - remainder;
      if (newPreviousPart < 0) return;
      const newPath = [...currentPath];
      newPath[newPath.length - 3] = newPreviousPart;
      return newPath;
    } else {
      const newPath = [...currentPath];
      newPath[newPath.length - 2] = penultimatePart - count;
      return newPath;
    }
  } catch (error) {
    console.error(error);
    return;
  }
}

export function getNextPath(editor: CustomEditor, count: number): Path | undefined {
  if (!editor.selection) return;
  const currentLocation = Editor.unhangRange(editor, editor.selection);
  const currentPath = currentLocation.anchor.path;
  const penultimatePart = currentPath[currentPath.length - 2];
  const newPath = [...currentPath];
  newPath[newPath.length - 2] = penultimatePart + count;
  return newPath;
}

export function getNextElementType(editor: CustomEditor, count: number): ElementType | undefined {
  if (!editor.selection) return;
  const nextPath = getNextPath(editor, count);
  if (!nextPath) return;
  try {
    const [match] = Array.from(
      Editor.nodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n),
        at: Editor.unhangRange(editor, { anchor: { path: nextPath, offset: 0 }, focus: { path: nextPath, offset: 0 } }),
      })
    );
    if (!match) return;
    const elementType = (match[0] as CustomElement)["type"];
    return elementType as ElementType;
  } catch (error) {
    return;
  }
}

export function getPreviousCharacters(editor: CustomEditor, distance: number): string | undefined {
  if (!editor.selection) return;
  const { anchor } = editor.selection;
  const previousCharLocation = Editor.before(editor, anchor, { unit: "character", distance });
  if (!previousCharLocation) return;
  const previousCharRange = Editor.range(editor, previousCharLocation, anchor);
  const previousCharText = Editor.string(editor, previousCharRange);
  return previousCharText;
}

export function getCurrentElement(editor: CustomEditor): CustomElement | undefined {
  if (!editor.selection) return;
  try {
    const multipleElementsSelected = isMultipleElementsSelected(editor);
    const anchorBeforeFocus = isAnchorBeforeFocus(editor);

    let locationToUse = editor.selection;
    if (multipleElementsSelected && anchorBeforeFocus) {
      locationToUse = { anchor: editor.selection.anchor, focus: editor.selection.anchor };
    } else if (multipleElementsSelected && !anchorBeforeFocus) {
      locationToUse = { anchor: editor.selection.focus, focus: editor.selection.focus };
    }

    const element = Editor.parent(editor, locationToUse);
    return element[0] as CustomElement;
  } catch (error) {
    return;
  }
}

export function isMultipleElementsSelected(editor: CustomEditor): boolean | undefined {
  if (!editor.selection) return;
  const { anchor, focus } = editor.selection;
  // Check if the anchor and focus are in the same element
  // They're in the same element if all the path parts except the last one are the same
  const isMultipleElementsSelected = anchor.path.slice(0, anchor.path.length - 1).join() !== focus.path.slice(0, focus.path.length - 1).join();
  return isMultipleElementsSelected;
}

export function isAnchorBeforeFocus(editor: CustomEditor): boolean | undefined {
  if (!editor.selection) return;
  const { anchor, focus } = editor.selection;
  const isAnchorBeforeFocus = Point.isBefore(anchor, focus);
  return isAnchorBeforeFocus;
}

export function getActiveElement(editor: CustomEditor): CustomElement | undefined {
  if (!editor.selection) return;
  try {
    const node = Editor.node(editor, editor.selection) as [CustomElement, Path];
    const element = Editor.parent(editor, node[1]);
    const parent = Editor.parent(editor, element[1]);
    return parent[0] as CustomElement;
  } catch (error) {
    return;
  }
}

export function markWrappedContent(startChar: string, endChar: string, text: string, ranges: any, path: number[]) {
  const parts = text.split(startChar);
  parts.forEach((part, i) => {
    if (i !== 0) {
      const prevRange = parts[i - 1];
      const start = text.indexOf(prevRange) + prevRange.length + 0;
      let end = start + part.length + 2;
      if (part.includes(endChar)) {
        let extraLength = 0;
        if (part.includes(endChar + endChar)) extraLength = 2;
        const range = part.split(endChar)[0];
        end = start + range.length + 2 + extraLength;
      }
      ranges.push({
        anchor: { path, offset: start },
        focus: { path, offset: end },
        mark: { mark: true },
      });
    }
  });
}

export function decorateRanges(
  editor: Editor,
  node: SlateNode,
  path: number[],
  options?: {
    query?: string | undefined;
    check_readability?: boolean | undefined;
  }
): Range[] {
  if (!editor || !node || !path) {
    console.warn("Required decoration parameters are missing.");
    return [];
  }

  const ranges: any = [];

  if (Text.isText(node)) {
    // Check if the text node is inside a code block
    const isInsideCodeBlock = isInsideNodeType(editor, path, "code");
    if (!isInsideCodeBlock) {
      const { text } = node;
      markWrappedContent("{", "}", text, ranges, path);
      // markWrappedContent("[", "]", text, ranges, path);

      // Highlight the query
      if (options?.query) {
        // Escape special characters in the query to use it in a regular expression
        const escapedQuery = options.query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const queryRegex = new RegExp(`\\b${escapedQuery}\\b`, "gi"); // 'gi' for global, case-insensitive
        let match;

        // Use regex.exec in a loop to find all matches and their indices
        while ((match = queryRegex.exec(text)) !== null) {
          // Push the range with the match index and length of the query
          ranges.push({
            anchor: { path, offset: match.index },
            focus: { path, offset: match.index + match[0].length },
            result: true, // Use 'highlight' or any property that your Slate renderer uses to identify highlighting
          });
        }
      }

      if (options?.check_readability) {
        // 1. Check for adverbs
        const adverbRanges = getAdverbRanges(text, path);
        ranges.push(...adverbRanges);

        // 2. Check for hedging/hesitant words
        const hedgingRanges = getHedgingRanges(text, path);
        ranges.push(...hedgingRanges);

        // 3. Check for passive voice
        const passiveRanges = getPassiveRanges(text, path);
        ranges.push(...passiveRanges);
      }
    }
  } else {
    if (options?.check_readability) {
      const descendants = Array.from(node.children);
      const textFromDescendants = getTextFromDescendant(descendants, { recursive: false, separator: "///", softBreakChar: "." });

      // Generate unique placeholders
      const placeholders = ignoredStringSplitters.map((_, index) => `PLACEHOLDER_${index}_`);

      // Step 1: Replace ignored string splitters with placeholders
      let tempText = textFromDescendants;
      ignoredStringSplitters.forEach((splitter, index) => {
        const escapedSplitter = splitter.replace(/\./g, "\\.");
        const regex = new RegExp(escapedSplitter, "g");
        tempText = tempText.replace(regex, placeholders[index]);
      });

      // Step 2: Split the sentences
      const tempSentences = tempText.split(/[.!?—]+/);

      // Step 3: Replace placeholders back
      const sentences = tempSentences.map((sentence) => {
        let tempSentence = sentence;
        placeholders.forEach((placeholder, index) => {
          const regex = new RegExp(placeholder, "g");
          tempSentence = tempSentence.replace(regex, ignoredStringSplitters[index]);
        });
        return tempSentence;
      });

      sentences.forEach((sentence) => {
        // 4. Check for readability
        const readability = getReadabilityLabel(sentence);
        if (readability !== "normal") {
          const readabilityRanges = getReadabilityRanges(editor, sentence, node, path, readability);
          ranges.push(...readabilityRanges);
        }
      });
    }
  }

  return ranges;
}

const getReadabilityRanges = (editor: CustomEditor, sentence: string, node: CustomEditor | CustomElement, path: number[], readability: Readability) => {
  const ranges: Range[] = [];

  // console.log({ sentence });
  // find the start of the sentence in the nodes
  const sentenceTextArray = sentence
    .trim()
    .split("///")
    .filter((string) => string !== "");

  const startPath = path.slice();
  let startOffset = 0 as number;

  node.children.find((child, index) => {
    if (Text.isText(child) && child.text.includes(sentenceTextArray[0].replace("///", ""))) {
      startPath.push(index);
      startOffset = child.text.indexOf(sentenceTextArray[0].replace("///", ""));
    }
  });

  const endPath = startPath.slice();
  let endOffset = startOffset + sentenceTextArray[0].length;

  for (let i = 1; i < sentenceTextArray.length; i++) {
    const sentenceText = sentenceTextArray[i].trim();

    node.children.map((child, index) => {
      if (Text.isText(child) && child.text.includes(sentenceText)) {
        endPath[endPath.length - 1] = startPath.slice()[startPath.length - 1] + index;
        endOffset = sentenceText.length;
      }
    });
  }

  if (startPath.length === 0 || endPath.length === 0) return [];

  let minusChar = 0;

  try {
    // remove 1 character if the last character is a ?!.
    minusChar = [".", "?", "!", "—"].includes(
      Editor.string(editor, { anchor: { path: endPath, offset: endOffset }, focus: { path: endPath, offset: endOffset + 1 } })
    )
      ? 1
      : 0;
  } catch (error) {
    // console.log(error);
  }

  ranges.push({
    anchor: { path: startPath, offset: startOffset },
    focus: { path: endPath, offset: endOffset + 1 - minusChar },
    readability: readability,
  });

  return ranges;
};

const getAdverbRanges = (text: string, path: Path) => {
  const ranges: Range[] = [];
  const lyWords = text
    .toLowerCase()
    .split(" ")
    .filter((word) => (word.trim().substring(word.length - 2) === "ly" ? word.trim() : null));
  lyWords.forEach((lyWord, index) => {
    // check if it appears in the acceptedLyWords set
    if (!acceptedLyWords.has(lyWord)) {
      // find the start of the word in the text
      const start = text.indexOf(lyWord);
      const end = start + lyWord.length;

      ranges.push({
        anchor: { path, offset: start },
        focus: { path, offset: end },
        adverb: true,
      });
    }
  });
  return ranges;
};

const getHedgingRanges = (text: string, path: Path) => {
  const ranges: Range[] = [];
  // 1. Convert text to lower case for easier matching
  const lowerText = text.toLowerCase();

  // 2. Loop through each hedge phrase in the Set
  hedgePhrases.forEach((phrase) => {
    let start = lowerText.indexOf(phrase); // 3. Find the starting position

    while (start !== -1) {
      // Check if the phrase is in the text
      const end = start + phrase.length; // Calculate the end position based on the length of the phrase

      // Check characters before and after the found phrase
      const charBefore = lowerText[start - 1];
      const charAfter = lowerText[end];

      // Check if these characters are word characters or if they don't exist (i.e., null or undefined)
      const isWordCharBefore = charBefore ? /\w/.test(charBefore) : false;
      const isWordCharAfter = charAfter ? /\w/.test(charAfter) : false;

      // Check if either of them is a non-word character or if they don't exist (start or end of string)
      const isIsolatedOrEdge = (!isWordCharBefore || charBefore == null) && (!isWordCharAfter || charAfter == null);

      if (isIsolatedOrEdge) {
        // 4. Add to Ranges
        ranges.push({
          anchor: { path, offset: start },
          focus: { path, offset: end },
          hedge: true, // Add a property to indicate this is a hedge phrase
        });
      }

      // Search for the next occurrence of the phrase
      start = lowerText.indexOf(phrase, start + 1);
    }
  });

  return ranges;
};

const getPassiveRanges = (text: string, path: Path) => {
  const ranges: Range[] = [];

  // 1. Match potential passive voices based on regex
  const potentialPassives = text.match(/\s(is|are|was|were|be|been|being)\s([a-z]{2,30})\b(\sby\b)?/gi);

  if (potentialPassives) {
    // 2. Filter down to actual passive voices
    const validPassives = potentialPassives.filter((potential) => {
      const match = potential.match(/([a-z]+)\b(\sby\b)?$/i); // regex that
      if (!match) return false;

      const mainVerb = match[1];

      // Check if it's a regular verb ending in 'ed' or an irregular verb
      const isPassive = mainVerb.match(/ed$/) !== null; // replace this with your own logic if you have a list of irregular verbs

      return isPassive;
    });

    // 3. Remove leading spaces
    const cleanedPassives = validPassives.map((validPassive) => validPassive.replace(/^\s/, ""));

    // 4. Create ranges (analogous to building a tree fragment)
    cleanedPassives.forEach((cleanedPassive) => {
      let start = text.indexOf(cleanedPassive);
      const end = start + cleanedPassive.length;

      ranges.push({
        anchor: { path, offset: start },
        focus: { path, offset: end },
        passive: true,
      });
    });
  }

  return ranges;
};

export function getReadingLevel(text: string) {
  const letters = countLettersInString(text);
  const words = countWordsInString(text);
  const sentences = countSentencesInString(text);

  if (0 === words || 0 === sentences) return 0;
  var r = Math.round(4.71 * (letters / words) + 0.5 * (words / sentences) - 21.43);
  const result = r <= 0 ? 0 : r;

  return result;
}

export function countLettersInString(text: string) {
  // match all word characters
  const matches = text.match(/\w/g);
  return matches ? matches.length : 0;
}

export function countWordsInString(string: string) {
  return string
    .trim()
    .split(" ")
    .filter((word) => word.trim() !== "").length;
}

export function countSentencesInString(string: string) {
  const placeholders = ignoredStringSplitters.map((_, index) => `PLACEHOLDER_${index}_`);

  let tempString = string;
  ignoredStringSplitters.forEach((splitter, index) => {
    const escapedSplitter = splitter.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    const regex = new RegExp(escapedSplitter, "g");
    tempString = tempString.replace(regex, placeholders[index]);
  });

  const sentenceCount = tempString.split(/[.!?]+/).length;

  return sentenceCount;
}

export function getReadabilityLabel(text: string): Readability {
  const n = getReadingLevel(text);
  const t = countWordsInString(text);
  return t < 14 ? "normal" : n >= 10 && n < 14 ? "hard" : n >= 14 ? "veryHard" : "normal";
}

export function isInsideNodeType(editor: Editor, path: number[], type: string): boolean {
  let currentPath = path;

  while (Path.parent(currentPath).length > 0) {
    const [parent, parentPath] = Editor.parent(editor, currentPath);
    if ((parent as CustomElement).type === type) {
      return true;
    }
    currentPath = parentPath;
  }

  return false;
}

export function getAllPreviousElements(editor: CustomEditor, element: CustomElement): CustomElement[] {
  let editorContent = editor.children as Descendant[];

  const elementPath = ReactEditor.findPath(editor, element);

  const currentElementIndex = elementPath[0];

  let previousElements: CustomElement[] = [];

  for (let i = 0; i < currentElementIndex; i++) {
    previousElements.push(editorContent[i] as CustomElement);
  }

  // If the loop finishes, it means the path did not point to a valid element
  return previousElements;
}

export const calculateAllTimestamps = (
  content: Descendant[],
  setTimestamps: React.Dispatch<React.SetStateAction<Map<number, { startTime: string; endTime: string }>>>,
  wpm: number
) => {
  const newTimestamps = new Map();
  let spokenSecondsStart = 0;

  for (const [index, element] of content.entries()) {
    const type = (element as CustomElement).type;
    if (type === "heading" || type === "callout") continue;

    const currentText = getTextFromDescendant([element as any], { neverTypes: ["heading"] });
    const spokenSecondsCurrent = calculateDuration(currentText, wpm);

    const startTime = secondsToHms(spokenSecondsStart);
    const endTime = secondsToHms(spokenSecondsStart + spokenSecondsCurrent - 1);

    newTimestamps.set(index, { startTime, endTime }); // Use index instead of id

    spokenSecondsStart += spokenSecondsCurrent;
  }
  setTimestamps(newTimestamps);
};

// Function to calculate spoken duration for a given text
// Returns duration in seconds
export function calculateDuration(text: string, wpm: number): number {
  const wordsPerMinute = wpm;
  const words = text.split(/\s+/).length;
  const minutes = words / wordsPerMinute;
  return Math.ceil(minutes * 60);
}

// Function to convert seconds to H:MM:SS format
// Returns a string in the format of "H:MM:SS" or "MM:SS"
export function secondsToHms(seconds: number) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hDisplay = h > 0 ? h.toString().padStart(2, "0") + ":" : "";
  const mDisplay = m > 0 ? m.toString().padStart(2, "0") + ":" : "00:";
  const sDisplay = s > 0 ? (s < 10 ? "0" + s : s) : "00";
  return hDisplay + mDisplay + sDisplay;
}

export function refocusBackspace(editor: CustomEditor, distance?: number) {
  ReactEditor.focus(editor);

  const selection = editor.selection;
  if (!selection) return;

  const previousCharacter = getPreviousCharacters(editor, distance || 1);
  if (previousCharacter === "/") {
    safeDelete(editor, { at: { path: selection.anchor.path, offset: selection.anchor.offset - 1 }, distance: distance || 1 });
  }
}

export function isDescendant(parent: HTMLElement, child: EventTarget | null): boolean {
  if (!child) return false;

  let node: Node | null = child as Node;
  while (node !== null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

export function getPathFromElement(editor: CustomEditor, element: CustomElement) {
  const path = ReactEditor.findPath(editor, element);
  return path;
}

export function getActiveColor(editor: CustomEditor) {
  const colors = ["red", "green", "blue", "gray"] as (keyof CustomText)[];
  for (const color of colors) {
    if (isMarkActive(editor, color)) return `var(--chakra-colors-${color}-500)`;
  }
  return "var(--chakra-colors-gray-700)";
}

export function isLinkActive(editor: CustomEditor) {
  const [link] = Editor.nodes(editor, { match: (n) => Text.isText(n) && !!n.href });
  return !!link;
}

export function wrapLink(editor: CustomEditor, url: string = "#") {
  if (editor.selection) {
    const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);
    if (!isCollapsed) {
      safeSetNodes(editor, { href: url }, { match: (n) => Text.isText(n), split: true });
    }
  }
}

export function getElementProperty(editor: CustomEditor, property: string) {
  const currentElement = getCurrentElement(editor);
  if (!currentElement) return false;
  return currentElement[property as keyof CustomElement];
}

export const deserialize = (
  el: HTMLElement,
  markAttributes: Partial<CustomText> = {}
): Descendant[] | Descendant | CustomElement | CustomText | string | (string | Descendant | null)[] | null => {
  if (el.nodeType === Node.TEXT_NODE) {
    // return if empty text node
    if (el.textContent === "") return null;
    else return jsx("text", markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes } as Partial<CustomText>;

  // define attributes for text nodes
  switch (el.nodeName) {
    case "STRONG":
      nodeAttributes.bold = true;
      break;
    case "EM":
      nodeAttributes.italic = true;
      break;
    case "U":
      nodeAttributes.underline = true;
      break;
    case "S":
      nodeAttributes.strike = true;
    case "DEL":
      nodeAttributes.strike = true;
      break;
    case "CODE":
      nodeAttributes.code = true;
      break;
    case "A":
      if (el.classList.contains("pseudolink")) nodeAttributes.pseudolink = true;
      else nodeAttributes.href = el.getAttribute("href") || undefined;
      break;

    case "SPAN":
      if (el.classList.contains("red-text")) nodeAttributes.red = true;
      else if (el.classList.contains("green-text")) nodeAttributes.green = true;
      else if (el.classList.contains("blue-text")) nodeAttributes.blue = true;
      else if (el.classList.contains("gray-text")) nodeAttributes.gray = true;
      break;
  }

  const children = Array.from(el.childNodes)
    .map((node) => deserialize(node as HTMLElement, nodeAttributes))
    .flat()
    .filter((child) => child !== null);

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return jsx("fragment", {}, children);
    case "BLOCKQUOTE":
      return jsx("element", { type: "blockquote" }, children);
    case "P":
      return jsx("element", { type: "paragraph" }, children);
    case "H1":
      return jsx("element", { type: "heading", as: "h1" }, children);
    case "H2":
      return jsx("element", { type: "heading", as: "h2" }, children);
    case "H3":
      return jsx("element", { type: "heading", as: "h3" }, children);
    case "H4":
      return jsx("element", { type: "heading", as: "h4" }, children);
    case "H5":
      return jsx("element", { type: "heading", as: "h5" }, children);
    case "H6":
      return jsx("element", { type: "heading", as: "h6" }, children);
    case "UL":
      return jsx("element", { type: getListTypeFromClassList(el.classList) || "bulleted-list" }, children);
    case "OL":
      return jsx("element", { type: getListTypeFromClassList(el.classList) || "numbered-list" }, children);
    case "LI":
      return jsx("element", { type: "list-item" }, children);
    default:
      return children;
  }
};

export const getListTypeFromClassList = (classList: DOMTokenList): ListType | undefined => {
  if (classList.contains("checked-list")) return "checked-list";
  else if (classList.contains("crossed-list")) return "crossed-list";
  else if (classList.contains("bulleted-list")) return "bulleted-list";
  else if (classList.contains("numbered-list")) return "numbered-list";
  else return;
};

export const removeEmptyElements = (content: Descendant[]): Descendant[] => {
  return content.filter((element: Descendant) => {
    if (
      (element as CustomElement).type === "paragraph" &&
      (element as CustomElement).children.length === 1 &&
      ((element as CustomElement).children[0] as CustomText).text === ""
    )
      return false;
    else return true;
  });
};

export const removeEmptyHTMLElements = (content: HTMLElement): HTMLElement => {
  // recursively check if each node has any text content
  // if not, remove the node
  const children = Array.from(content.childNodes);
  children.forEach((child) => {
    if (child.nodeType === Node.TEXT_NODE) {
      if (child.textContent === "") content.removeChild(child);
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      removeEmptyHTMLElements(child as HTMLElement);
      if (child.textContent === "") content.removeChild(child);
    }
  });
  return content;
};

export const handleSlatePaste = (
  event: React.ClipboardEvent<HTMLDivElement>,
  editor: CustomEditor,
  allowedFeatures: Set<string>,
  setIsPasted?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // Get the original text and html from the clipboard
  const originalPlainText = event.clipboardData.getData("text/plain");
  const originalHtml = event.clipboardData.getData("text/html");

  // Paste plain text and return if we're in a certain block type
  const currentElement = getCurrentElement(editor);
  const parentElement = getParentElement(editor);
  if (
    (currentElement && PLAIN_TEXT_PASTE_BLOCKS.includes(currentElement.type)) ||
    (parentElement && PLAIN_TEXT_PASTE_BLOCKS.includes(parentElement?.type || ""))
  ) {
    event.preventDefault();
    const joinedText = originalPlainText.split("\n").join(" ").trim();
    safeInsertText(editor, joinedText);
    return;
  }

  // Paste as is if it's already slate content
  const isSlateContent = originalHtml.includes("data-slate");
  if (isSlateContent) {
    // Remove elements of type that are not allowed
    const document = new DOMParser().parseFromString(originalHtml, "text/html");

    removeEmptyHTMLElements(document.body);
    const slateFromHtml = deserialize(document.body) as Descendant[];
    if (slateFromHtml && slateFromHtml.length > 0) {
      event.preventDefault();
      const slateOnlyAllowedElements = slateFromHtml.filter((element) => {
        if ((element as CustomElement).type) {
          const type = (element as CustomElement).type;
          if (allowedFeatures.has(type)) return true;
          else return false;
        } else return true;
      });
      safeInsertFragment(editor, slateOnlyAllowedElements);
      return;
    }
  }

  // Deserialize and paste if original is HTML
  if (originalHtml) {
    const document = new DOMParser().parseFromString(originalHtml, "text/html");
    const slateFromHtml = deserialize(document.body) as Descendant[];
    if (slateFromHtml && slateFromHtml.length > 0) {
      event.preventDefault();
      const slateWithoutEmpties = removeEmptyElements(slateFromHtml);
      safeInsertFragment(editor, slateWithoutEmpties);
      return;
    }
  }

  if (originalPlainText.includes("\n")) {
    // Replace double newlines with single newlines
    const textToPasteWithoutDuplicateNewlines = originalPlainText.replace(/\n{2,}/g, "\n");
    const plainTextNoEmpties = textToPasteWithoutDuplicateNewlines;
    const plainTextArray = plainTextNoEmpties.split("\n");

    // Insert each line as a separate paragraph
    event.preventDefault();
    const newSlateContent: Descendant[] = plainTextArray.map((line) => {
      return {
        type: "paragraph",
        children: [{ text: line }],
      };
    });
    safeInsertFragment(editor, newSlateContent);
    return;
  }

  setIsPasted?.(true);
};

// Define the function that will recursively search for the commentThread
export const findCommentThread = (content: CustomElement[], threadId: string): boolean => {
  // Loop over each element in the editor
  for (const element of content) {
    // If the element is of type CustomText, check for commentThread
    if ("text" in element) {
      if (`commentThread-${threadId}` in element) {
        return true;
      }
    }
    // If the element is of type CustomElement, recurse into its children
    else if ("children" in element) {
      const found = findCommentThread(element.children as CustomElement[], threadId);
      if (found) {
        return true;
      }
    }
  }

  // If we made it through the entire loop without returning true, return false.
  return false;
};

export const safeInsertNodes = (editor: CustomEditor, nodes: SlateNode | SlateNode[], options?: NodeInsertNodesOptions<SlateNode> | undefined) => {
  try {
    Transforms.insertNodes(editor, nodes, options);
  } catch (error) {
    console.error(error);
  }
};

export const safeInsertText = (editor: CustomEditor, text: string, options?: { at?: Location; voids?: boolean } | undefined) => {
  try {
    Transforms.insertText(editor, text, options);
  } catch (error) {
    console.error(error);
  }
};

export const safeSetNodes = (editor: CustomEditor, props: Partial<SlateNode>, options?: NodeOptions & { hanging?: boolean; split?: boolean }) => {
  try {
    Transforms.setNodes(editor, props, options);
  } catch (error) {
    console.error(error);
  }
};

export const safeDelete = (
  editor: CustomEditor,
  options?:
    | { at?: Location; distance?: number; unit?: "character" | "word" | "line" | "block"; reverse?: boolean; hanging?: boolean; voids?: boolean }
    | undefined
) => {
  try {
    Transforms.delete(editor, options);
  } catch (error) {
    console.error(error);
  }
};

export const safeSelect = (editor: CustomEditor, target: Location) => {
  try {
    Transforms.select(editor, target);
  } catch (error) {
    console.error(error);
  }
};

export const safeRemoveNodes = (editor: CustomEditor, options?: (NodeOptions & { hanging?: boolean }) | undefined) => {
  try {
    Transforms.removeNodes(editor, options);
  } catch (error) {
    console.error(error);
  }
};

export const safeUnwrapNodes = (editor: CustomEditor, options?: (NodeOptions & { split?: boolean }) | undefined) => {
  try {
    Transforms.unwrapNodes(editor, options);
  } catch (error) {
    // console.error(error);
  }
};

export const safeWrapNodes = (editor: CustomEditor, element: SlateElement, options?: (NodeOptions & { split?: boolean }) | undefined) => {
  try {
    Transforms.wrapNodes(editor, element, options);
  } catch (error) {
    console.error(error);
  }
};

export const safeInsertFragment = (
  editor: CustomEditor,
  fragment: SlateNode[],
  options?: { at?: Location; hanging?: boolean; voids?: boolean } | undefined
) => {
  try {
    Transforms.insertFragment(editor, fragment, options);
  } catch (error) {
    console.error(error);
  }
};

export const safeUnsetNodes = (
  editor: CustomEditor,
  props: string | string[],
  options?: (NodeOptions & { hanging?: boolean; split?: boolean }) | undefined
) => {
  try {
    Transforms.unsetNodes(editor, props, options);
  } catch (error) {
    console.error(error);
  }
};

export const safeMoveNodes = (editor: CustomEditor, options: NodeOptions & { to: Path }) => {
  try {
    Transforms.moveNodes(editor, options);
  } catch (error) {
    console.error(error);
  }
};

export const getSlatePoint = (editor: ReactEditor, x: number, y: number): Point | null => {
  // Create a DOM range from the clicked coordinates
  const domRange = document.caretRangeFromPoint(x, y);
  if (!domRange) return null;

  // Convert the DOM range to a Slate range
  let slateRange;
  try {
    slateRange = ReactEditor.toSlateRange(editor, domRange, { exactMatch: false, suppressThrow: true });
  } catch (e) {
    // This can happen if the clicked area doesn't belong to this editor
    return null;
  }

  // We'll assume the range is collapsed to a single point
  const { anchor } = Range.isRange(slateRange) ? slateRange : { anchor: slateRange };

  return anchor;
};
