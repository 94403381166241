import { Avatar, AvatarBadge, AvatarProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export type UserAvatarProps = AvatarProps & {
  badgeColor?: string;
};

export default function UserAvatar({ badgeColor, src, size, onError: onErrorProp, ...props }: UserAvatarProps) {
  const { name } = props;

  const [srcToUse, setSrcToUse] = useState(src);

  const handleOnError = () => {
    setSrcToUse(undefined);
    onErrorProp && onErrorProp();
  };

  useEffect(() => {
    setSrcToUse(src);
  }, [src]);

  return (
    <Avatar
      className="user-avatar"
      src={srcToUse}
      {...props}
      bg={srcToUse ? "gray.200" : name ? "gray.600" : "gray.300"}
      color="white"
      size={size}
      height={size === "sm" ? "8" : "10"}
      width={size === "sm" ? "8" : "10"}
      onError={handleOnError}>
      {badgeColor && <AvatarBadge boxSize="1em" bg={`${badgeColor}.500`} top={0} transform="translate(33%, -25%)" />}
    </Avatar>
  );
}
