import { createElement } from "react";
import { IconBaseProps, IconType } from "react-icons";

export type MenuIconProps = IconBaseProps & {
  iconType: IconType;
  size?: "xs" | "sm" | "md" | "lg";
};

export default function MenuIcon({ iconType, size = "md", ...props }: MenuIconProps) {
  let fontSize = "19px";
  if (size === "xs") fontSize = "17px";
  if (size === "sm") fontSize = "18px";
  if (size === "md") fontSize = "19px";
  if (size === "lg") fontSize = "20px";
  return <>{createElement(iconType, { fontSize, ...props })}</>;
}
