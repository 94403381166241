import Stripe from "stripe";
import { BProfile } from "routes/_admin.billing";
import { Descendant } from "slate";
import { AlignType, CustomElement, CustomText } from "types/slate-types";
import { PostgrestError } from "@supabase/supabase-js";
import {
  ClientSideProfile,
  ClientSideProject,
  ClientSideRichAsset,
  ClientSideRichCommentThread,
  Enums,
  Profile,
  ProjectMember,
  Status,
  SupabaseResponse,
} from "types/supabase-helpers";
import { ACRONYMS, GRID_SIZE } from "./constants";
import { BiAlignLeft, BiAlignRight, BiAlignMiddle } from "react-icons/bi";

export function debounce(fn: (...args: any[]) => void, delay: number): (...args: any[]) => void {
  let timer: number | NodeJS.Timeout | undefined = undefined;
  return function (...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export function deepEqual(a: any, b: any): boolean {
  if (a === b) return true;

  if (a && b && typeof a === "object" && typeof b === "object") {
    if (Object.keys(a).length !== Object.keys(b).length) return false;

    for (const key in a) {
      if (!b.hasOwnProperty(key)) return false;
      if (!deepEqual(a[key], b[key])) return false;
    }

    return true;
  }

  return false;
}

export function shallowEqual(a: any, b: any): boolean {
  if (JSON.stringify(a) === JSON.stringify(b)) return true;
  return false;
}

export function getResponse(data: any, error: PostgrestError | null): SupabaseResponse {
  if (error) {
    console.error({ error });
    return {
      data: null,
      error,
    };
  }
  return {
    data,
    error: null,
  };
}

export function getSubscriptionInfo(profile: ClientSideProfile) {
  const isProUser = profile.subscription_tier === "PRO";
  const isBasicUser = profile.subscription_tier === "BASIC";
  const isTrialActive = !!profile.trial_ended_at && new Date(profile.trial_ended_at) >= new Date();
  const isTrialExpired = !!profile.trial_ended_at && new Date(profile.trial_ended_at) < new Date();
  const isPayingUser = isProUser || isBasicUser;
  return { isProUser, isBasicUser, isPayingUser, isTrialActive, isTrialExpired };
}

export function getTrialInfo(profile: ClientSideProfile) {
  if (!profile.trial_ended_at) return { trialDaysRemaining: null, trialDaysUsedPercentage: null };
  const trialEndDate = new Date(profile.trial_ended_at);
  const trialDaysRemaining = Math.round((trialEndDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
  const trialDaysUsedPercentage = Math.round(((14 - trialDaysRemaining) / 14) * 100);
  return { trialDaysRemaining, trialDaysUsedPercentage };
}

export function getIntervalAdverb(interval: Stripe.Plan.Interval) {
  switch (interval) {
    case "day":
      return "daily";
    case "week":
      return "weekly";
    case "month":
      return "monthly";
    case "year":
      return "yearly";
    default:
      return interval;
  }
}

export function getFriendlyDateFromMs(ms: number) {
  return new Date(ms * 1000).toLocaleDateString("en-US", friendlyDateStringOptions);
}

export function getFriendlyDateFromDate(date: Date) {
  const newDate = date.toLocaleDateString("en-US", friendlyDateStringOptions);
  return newDate;
}

export const friendlyDateStringOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const shortDateStringOptions: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
};

export function getShortDateFromIso(iso: string) {
  const date = new Date(iso);
  return date.toLocaleDateString("en-US", shortDateStringOptions);
}

export function getMMMMYYYYDateFromMs(ms: number) {
  const date = new Date(ms * 1000);
  return `${date.toLocaleString("default", { month: "long" })} ${date.getFullYear()}`;
}

export function daysBeforeTodayISO(days: number) {
  return new Date(Date.now() - 24 * 60 * 60 * 1000 - days).toISOString();
}

export function daysAfterTodayISO(days: number) {
  const newDate = new Date(Date.now() + 24 * 60 * 60 * 1000 * days).toISOString();
  return newDate;
}

export function logLoader(route: string) {
  console.log();
  console.log(`----- ${route.toUpperCase()} LOADER ----`, new Date().toLocaleTimeString(), "-----");
}

export function getRandomImageDimensions(min: number, max: number) {
  const width = Math.floor(Math.random() * (max - min + 1) + min);
  const height = Math.floor(Math.random() * (max - min + 1) + min);
  return `${width}x${height}`;
}

// create a function that gets a random avatar OR randomly returns undefined half the time
export function getRandomAvatar() {
  const random = Math.random();
  if (random < ((100 / 3) * 2) / 100) return undefined;
  else {
    const dimensions = getRandomImageDimensions(200, 300);
    return `https://source.unsplash.com/random/${dimensions}/?headshot`;
  }
}

export function get1LineAddress(address: Stripe.PaymentMethod.BillingDetails["address"] | undefined) {
  if (!address) return null;
  const addressArray = [address.line1, address.line2, address.city, address.state, address.postal_code];
  return addressArray.filter((line) => line).join(", ");
}

export function getParamsFromLocation(location: string) {
  const isUrl = location.startsWith("http");
  const sliceStart = isUrl ? 4 : 2;
  const params = location.split("/").slice(sliceStart);
  return {
    tid: params[0],
    cid: params[1],
    pid: params[2],
    aid: params[3],
    v: params[4],
  };
}

export function getTextFromDescendant(
  descendants: Descendant[],
  {
    onlyTypes,
    neverTypes,
    separator,
    recursive = true,
    softBreakChar,
  }:
    | { onlyTypes?: CustomElement["type"][]; neverTypes?: CustomElement["type"][]; separator?: string; recursive?: boolean; softBreakChar?: string }
    | undefined = {}
) {
  let text = "";
  function processChildren(children: Descendant[]) {
    children.forEach((child) => {
      if (recursive && (child as CustomElement).children && (!onlyTypes || onlyTypes.includes((child as CustomElement).type))) {
        processChildren((child as CustomElement).children);
      } else if ((child as CustomText).text) {
        if (softBreakChar) text += separator + (child as CustomText).text.replace(/\n/g, softBreakChar).trim();
        else text += separator + (child as CustomText).text.trim();
      }
    });
  }
  processChildren(descendants);
  return text.trim();
}

export function getOnboardingStatus(profile: Profile, projectMembers: ProjectMember[] | null) {
  const hasSomeAccess = projectMembers && projectMembers.length > 0;
  const hasOnlyViewerAccess = hasSomeAccess && projectMembers.every((member) => member.access === "VIEW");
  const hasAnyEditorAccess = hasSomeAccess && projectMembers.some((member) => member.access === "EDIT");

  // New user?
  if ((!profile.onboarding_tid || !profile.onboarding_cid || !profile.onboarding_pid || !profile.onboarding_aid) && !hasSomeAccess)
    return { onboardingStatus: "new-user" };

  // New viewer?
  if (!profile.user_joyride_complete && !profile.viewer_joyride_complete && hasOnlyViewerAccess && !hasAnyEditorAccess)
    return { onboardingStatus: "new-viewer" };

  // Completely onboarded?
  return { onboardingStatus: "onboarded" };
}

export function isViewerOnly(ownedProjectMembers: ProjectMember[] | null) {
  const hasSomeAccess = ownedProjectMembers && ownedProjectMembers.length > 0;
  return (hasSomeAccess && ownedProjectMembers.every((member) => member.access === "VIEW")) || false;
}

export function hasAnyEditorAccess(ownedProjectMembers: ProjectMember[] | null) {
  const hasSomeAccess = ownedProjectMembers && ownedProjectMembers.length > 0;
  return hasSomeAccess && ownedProjectMembers.some((member) => member.access === "EDIT");
}

export function hasMissingOnboardingIds(profile: Profile) {
  return !profile.onboarding_tid || !profile.onboarding_cid || !profile.onboarding_pid || !profile.onboarding_aid;
}

export function getNestedAssets(
  assetsUnnested: ClientSideRichAsset[] | null,
  aid: string | undefined,
  statuses: Status[] | null,
  hasEditAccess: boolean
): ClientSideRichAsset[] {
  if (!assetsUnnested || !statuses) return [];
  function processVersions(item: ClientSideRichAsset): ClientSideRichAsset | undefined {
    if (item.version) {
      let filteredVersions = item.version;
      if (!hasEditAccess && item.asset_type !== "SEQUENCE") filteredVersions = filteredVersions.filter((version) => version.stage === 3);
      if (filteredVersions.length === 0) return;
      const highestVersion = filteredVersions.reduce((acc, version) => {
        if (version.version_number > acc.version_number) return version;
        return acc;
      }, filteredVersions[0]);
      const statusRow = statuses?.find((status) => status.stage === highestVersion.stage);
      const reviewStatusRow = statuses?.find((status) => status.stage === (highestVersion.version_review as any)?.stage) || statuses![0];

      highestVersion.status_name = statusRow?.edit_name;
      highestVersion.status_color = statusRow?.color;
      highestVersion.asset_type = item.asset_type;
      highestVersion.review_stage = reviewStatusRow?.stage || 1;
      highestVersion.review_status_name = reviewStatusRow?.view_name;
      highestVersion.review_status_color = reviewStatusRow?.color;
      return {
        ...item,
        version: [highestVersion],
      };
    }
    return item;
  }
  const processedAssets = assetsUnnested.map(processVersions).filter(Boolean) as ClientSideRichAsset[];
  const nestedAssets: ClientSideRichAsset[] = [];
  for (let item of processedAssets) {
    if (item.parent_asset_id) {
      const parent = processedAssets.find((a) => a.id === item.parent_asset_id);
      if (parent) {
        if (!parent.children) parent.children = [];
        parent.children.push(item);
      } else nestedAssets.push(item);
    } else nestedAssets.push(item);
  }

  // If the user doesn't have edit access, filter out SEQUENCE assets that don't have children
  if (!hasEditAccess) {
    return nestedAssets.filter((asset) => {
      if (asset.asset_type === "SEQUENCE") {
        return asset.children && asset.children.length > 0;
      }
      return true;
    });
  }
  return nestedAssets;
}

// export function sortRichAssetsVersionRewiews(assets: ClientSideRichAsset[]): ClientSideRichAsset[] {
//   const sortAssetVersionReviews = (asset: ClientSideRichAsset) => {
//     asset.version.forEach((version) => {
//       if (version.version_review) version.version_review.sort((a, b) => b.stage - a.stage);
//     });
//     asset.children?.forEach((child) => sortAssetVersionReviews(child));
//   };
//   assets.forEach((asset) => sortAssetVersionReviews(asset));
//   return assets;
// }

export function sentenceCaseAcronyms(input: string): string {
  // Step 2: Function definition
  // Split the string into words
  const words = input.split(" "); // Step 3: Split the string

  // Loop through each word to capitalize first letter and check for acronyms
  const cappedWords = words.map((word) => {
    // Step 4: Looping and checking each word
    if (ACRONYMS.has(word.toUpperCase())) {
      // Step 5: Check if word is an acronym
      return word.toUpperCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Step 6: Otherwise, capitalize first letter
  });

  // Join the words back into a string and return
  return cappedWords.join(" "); // Step 7: Joining and returning the result
}

export function calculateInitialColSpansPc(firstColSpan: number, numberOfCols: number) {
  const firstColWidthPc = (firstColSpan / GRID_SIZE) * 100;
  const restColSpan = (100 - firstColWidthPc) / (numberOfCols - 1);
  const colSpanArr = Array.from({ length: numberOfCols }, (_, i) => (i === 0 ? firstColWidthPc : restColSpan));
  return colSpanArr;
}

export function calculateInitialColSpansGrid(firstColSpan: number, numberOfCols: number) {
  const firstColWidthGrid = firstColSpan;
  const restColSpan = GRID_SIZE - firstColWidthGrid;
  const restEachColSpan = restColSpan / (numberOfCols - 1);
  const colSpanArr = Array.from({ length: numberOfCols }, (_, i) => (i === 0 ? firstColWidthGrid : restEachColSpan));
  return colSpanArr;
}

export const asyncIterableToStream = (asyncIterable: AsyncIterable<Uint8Array>) => {
  return new ReadableStream({
    async pull(controller) {
      for await (const entry of asyncIterable) {
        controller.enqueue(entry);
      }
      controller.close();
    },
  });
};

export function getAlignmentIcon(alignment: AlignType) {
  if (alignment === "left") return <BiAlignLeft />;
  if (alignment === "center") return <BiAlignMiddle />;
  if (alignment === "right") return <BiAlignRight />;
  return <BiAlignLeft />;
}

export function sortCommentThreadsDateAscending(commentThreads: ClientSideRichCommentThread[]) {
  // for each thread, sort the comments in the comment array by date
  const sortedThreads = commentThreads.map((thread) => {
    const sortedComments = thread.comment.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    return { ...thread, comments: sortedComments };
  });

  return sortedThreads;
}

export function getCommentDate(date: string) {
  const commentDate = new Date(date);
  const isToday =
    new Date().getDate() === commentDate.getDate() &&
    new Date().getMonth() === commentDate.getMonth() &&
    new Date().getFullYear() === commentDate.getFullYear();
  if (isToday) {
    return `Today, ${commentDate.toLocaleString(undefined, { hour: "numeric", minute: "numeric" })}`;
  }
  const isYesterday =
    new Date().getDate() - 1 === commentDate.getDate() &&
    new Date().getMonth() === commentDate.getMonth() &&
    new Date().getFullYear() === commentDate.getFullYear();
  if (isYesterday) {
    return `Yesterday, ${commentDate.toLocaleString(undefined, { hour: "numeric", minute: "numeric" })}`;
  }
  return commentDate.toLocaleString(undefined, { dateStyle: "medium", timeStyle: "short" });
}
export function getBaseFontSize(assetType: Enums<"asset_type">, project: ClientSideProject) {
  let baseFontSize = 16;
  if (assetType === "EMAIL") baseFontSize = project.email_base_font_size;
  else if (assetType === "PAGE") baseFontSize = project.page_base_font_size;
  else if (assetType === "POST") baseFontSize = project.post_base_font_size;
  else if (assetType === "SCRIPT") baseFontSize = project.script_base_font_size;
  else if (assetType === "SEQUENCE") baseFontSize = project.email_base_font_size;
  return baseFontSize;
}

export function getAvatarUrl(profileId: string) {
  return `https://uiabqsovxkqtsoducarn.supabase.co/storage/v1/object/public/assets/${profileId}/avatar`;
}

export const friendlyTimeAgo = (inputDate: Date): string => {
  const now = new Date();
  const millisecondsAgo = now.getTime() - inputDate.getTime();

  const isToday = now.toDateString() === inputDate.toDateString();
  const isYesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000).toDateString() === inputDate.toDateString();

  const isLastHour = millisecondsAgo < 60 * 60 * 1000;
  const isLast7Days = millisecondsAgo < 7 * 24 * 60 * 60 * 1000;

  const minutesAgo = Math.floor(millisecondsAgo / (1000 * 60));
  const hoursAgo = Math.floor(millisecondsAgo / (1000 * 60 * 60));
  const daysAgo = Math.floor(millisecondsAgo / (1000 * 60 * 60 * 24));

  if (isLastHour) {
    return `${minutesAgo} minutes ago`;
  }

  if (isToday) {
    return `${hoursAgo} hours ago`;
  }

  if (isYesterday) {
    return `Yesterday at ${inputDate.toLocaleTimeString(undefined, { hour: "numeric", minute: "numeric" })}`;
  }

  if (isLast7Days) {
    return `${inputDate.toLocaleDateString(undefined, {
      weekday: "short",
      month: "short",
      day: "numeric",
    })} at ${inputDate.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "numeric",
    })}`;
  }

  return `${inputDate.toLocaleDateString(undefined, { month: "short", day: "numeric" })} at ${inputDate.toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "numeric",
  })}`;
};

export const getIds = (asset: ClientSideRichAsset): string[] => {
  // Array to gather IDs
  let ids = [asset.version[0].id];

  // If the asset has children, gather their IDs as well
  if (asset.children) {
    asset.children.forEach((child) => {
      ids = ids.concat(getIds(child));
    });
  }

  return ids;
};

export const isReviewed = (asset: ClientSideRichAsset): boolean => {
  // Check if the current asset is reviewed
  if (asset.version[0].review_stage !== 3) return false;

  // If the asset has children, check them too
  if (asset.children) {
    return asset.children.every(isReviewed);
  }

  // If the asset doesn't have children or all children are reviewed, return true
  return true;
};

export function allAssetsReviewed(assets: ClientSideRichAsset[]) {
  const flattenedAssets = assets
    .map((asset) => {
      if (asset.children) {
        return [asset, ...asset.children];
      } else {
        return [asset];
      }
    })
    .flat()
    .filter((asset) => asset.asset_type !== "SEQUENCE");

  const allAssetsReviewed = flattenedAssets.every((asset) => asset.version[0].review_stage === 3);
  return allAssetsReviewed;
}

export function getParentAsset(asset: ClientSideRichAsset, assets: ClientSideRichAsset[]) {
  const parentAsset = assets.find((a) => a.id === asset.parent_asset_id);
  if (parentAsset) {
    return parentAsset;
  } else {
    return null;
  }
}

export function isUnreviewedNonSequence(asset: ClientSideRichAsset | undefined) {
  if (!asset || asset.asset_type === "SEQUENCE") return false;
  if (asset.version[0].review_stage === 3) return false;
  return true;
}

export function isSequenceWithUnreviewedChildren(asset: ClientSideRichAsset | undefined) {
  if (!asset || asset.asset_type !== "SEQUENCE") return false;
  if (!asset.children) return false;
  if (asset.children.length === 0) return false;
  const unreviewedChildren = asset.children.filter((child) => child.version[0].review_stage !== 3);
  if (unreviewedChildren.length === 0) return false;
  return true;
}

export function isChildAsset(asset: ClientSideRichAsset) {
  return asset.parent_asset_id !== null;
}

export function findUnreviewedAsset(startIndex: number, assets: ClientSideRichAsset[]): ClientSideRichAsset | null {
  for (let i = startIndex; i < assets.length; i++) {
    const asset = assets[i];
    if (isUnreviewedNonSequence(asset)) {
      return asset;
    } else if (isSequenceWithUnreviewedChildren(asset)) {
      const firstUnreviewedChild = asset.children?.find((child) => child.version[0].review_stage !== 3);
      if (firstUnreviewedChild) {
        return firstUnreviewedChild;
      }
    }
  }
  return null;
}
