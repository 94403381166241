import { ToastProps, useToast } from "@chakra-ui/react";

export default function Toast() {
  const toast = useToast();

  const addToast = (newRes: ToastProps) => {
    const id = `toast-${new Date().getTime()}`;
    if (!toast.isActive(id)) {
      toast({
        title: newRes.title,
        description: newRes.description,
        status: newRes.status,
        position: "bottom-right",
        isClosable: false,
        duration: newRes.duration || 3000,
        variant: "solid",
      });
    }
  };

  return { addToast };
}
