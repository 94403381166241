import { BiAlignLeft, BiAlignMiddle, BiAlignRight, BiBold, BiItalic, BiLink, BiParagraph, BiSolidDroplet, BiStrikethrough, BiUnderline } from "react-icons/bi";
import { BsListUl, BsListOl, BsListCheck, BsInputCursorText } from "react-icons/bs";
import {
  HiBars3BottomLeft,
  HiOutlineBell,
  HiOutlineBriefcase,
  HiOutlineChatBubbleLeft,
  HiOutlineCog,
  HiOutlineCreditCard,
  HiOutlineDocument,
  HiOutlineFolder,
  HiOutlineLifebuoy,
} from "react-icons/hi2";
import { LuHeading1, LuHeading2, LuHeading3, LuHeading4, LuHeading5, LuHeading6, LuCode2, LuHighlighter, LuSubscript, LuSuperscript } from "react-icons/lu";
import { MdHorizontalRule } from "react-icons/md";
import { RxButton, RxSection, RxTable } from "react-icons/rx";
import { TbBlockquote, TbTable } from "react-icons/tb";
import { BasicSubscription, PricingPlan } from "types/stripe-types";
import {
  toggleElement,
  refocusBackspace,
  toggleHeading,
  toggleList,
  toggleButton,
  insertDivider,
  isMarkActive,
  toggleMark,
  isDefaultColor,
  removeColor,
  isLinkActive,
  getElementProperty,
  setAlignment,
  insertOptin,
  toggleTable,
} from "lib/slate-helpers";
import { CustomEditor, ElementMenu, ToolbarDropdownButton, ToolbarFormatButton } from "types/slate-types";
import { ClientSideProfile, Enums } from "types/supabase-helpers";
import { Editor, Transforms } from "slate";
import CommentActivity, { CommentActivityProps } from "partials/CommentActivity";
import { ClientSideCommentActivity } from "routes/_admin";
import { TypedSupabaseClient } from "~/root";
import { Box, Text } from "@chakra-ui/react";
import SubscriptionPlans from "partials/SubscriptionPlans";
import { ModalContextType } from "hooks/useModal";
import { Emoji } from "types/custom_types";
import { ReactEditor } from "slate-react";
import mixpanel from "mixpanel-browser";

export const TOAST_SUCCESS_TITLE = "Success!";

export const TRIAL_DURATION_DAYS = 14;

export const ACCOUNT_MENU_ITEMS = (
  commentActivities: ClientSideCommentActivity[],
  anyNewComments: boolean,
  showBilling: boolean,
  showPortfolio: boolean,
  profile: ClientSideProfile,
  openModal: any,
  closeModal: any,
  supabase: TypedSupabaseClient
) => {
  return [
    {
      group: "Features",
      items: [
        {
          label: "Drive",
          iconType: HiOutlineFolder,
          to: "/drive",
          onClick: () => {},
          isVisible: true,
          reloadDocument: true,
          replace: true,
          tag: "",
          badgeColor: "",
          isDisabled: false,
        },
        // {
        //   label: "Activity",
        //   iconType: HiOutlineBell,
        //   to: "/activity",
        //   onClick: () => {},
        //   isVisible: true,
        //   reloadDocument: false,
        //   replace: false,
        //   tag: "",
        //   isDisabled: false,
        // },
        {
          label: "Portfolio",
          iconType: HiOutlineBriefcase,
          to: `/portfolio/${profile.portfolio_slug}`,
          onClick: () => {},
          isVisible: showPortfolio,
          reloadDocument: false,
          replace: false,
          tag: "Soon!",
          badgeColor: "",
          isDisabled: true,
        },
      ],
    },
    {
      group: "Notifications",
      items: [
        {
          label: "Activity",
          iconType: HiOutlineBell,
          to: "",
          onClick: () => {
            openModal("Activity", <CommentActivity {...({ supabase, profile, commentActivities, closeModal } as CommentActivityProps)} />, "lg");
          },
          isVisible: true,
          reloadDocument: false,
          replace: false,
          tag: "",
          badgeColor: anyNewComments ? "red" : "",
          isDisabled: false,
        },
      ],
    },
    {
      group: "Settings",
      items: [
        {
          label: "Billing",
          iconType: HiOutlineCreditCard,
          to: "/billing",
          onClick: () => {},
          isVisible: showBilling,
          reloadDocument: false,
          replace: false,
          tag: "",
          badgeColor: "",
          isDisabled: false,
        },
        {
          label: "Support",
          iconType: HiOutlineLifebuoy,
          to: undefined,
          onClick: () => {
            mixpanel.identify(profile?.email);
            mixpanel.track("Support Clicked", { "Action Location": `Account Menu` });
            window.Chatra("openChat", true);
          },
          isVisible: true,
          reloadDocument: false,
          replace: false,
          tag: "",
          badgeColor: "",
          isDisabled: false,
        },
        {
          label: "Settings",
          iconType: HiOutlineCog,
          command: "⌘⇧S",
          to: "/settings",
          onClick: () => {},
          isVisible: true,
          reloadDocument: false,
          replace: false,
          tag: "",
          badgeColor: "",
          isDisabled: false,
        },
      ],
    },
  ];
};

export const PLANS = [
  {
    name: "Free Plan",
    tier: "free",
    description: "Perfect for new copywriters",
    amount: 0,
    interval: "month",
    price_id: "",
    payment_link: "https://billing.stripe.com/p/login/3cs9BC7oh9MI70k288",
    customer_portal_link: "https://billing.stripe.com/p/login/3cs9BC7oh9MI70k288",
    features: {
      included: ["1 client", "1 project", "20 assets", "Client sharing"],
      not_included: ["Version control", "Portfolio"],
      coming_soon: [],
    },
    visible: true,
    default: true,
  },
  {
    name: "Free Plan",
    tier: "free",
    description: "Perfect for new copywriters",
    amount: 0,
    interval: "year",
    price_id: "",
    payment_link: "https://billing.stripe.com/p/login/3cs9BC7oh9MI70k288",
    customer_portal_link: "https://billing.stripe.com/p/login/3cs9BC7oh9MI70k288",
    features: {
      included: ["1 client", "1 project", "20 assets", "Client sharing"],
      not_included: ["Version control", "Portfolio"],
      coming_soon: [],
    },
    visible: true,
    default: true,
  },
  {
    name: "Basic Plan",
    tier: "basic",
    description: "Unlimited assets and sharing",
    amount: 20,
    interval: "month",
    price_id: "price_1NeOpdFALBECfhUStnXKDAgu",
    payment_link: "https://buy.stripe.com/3cs9Cy5mD2O2dYQ288",
    customer_portal_link: "https://billing.stripe.com/p/login/3cs9BC7oh9MI70k288",
    features: {
      included: ["Unlimited clients", "Unlimited projects", "Unlimited assets", "Client sharing"],
      not_included: ["Version control", "Portfolio"],
      coming_soon: [],
    },
    visible: true,
    default: true,
    ribbon: "Popular",
  },
  {
    name: "Basic Plan",
    tier: "basic",
    description: "Unlimited assets and sharing",
    amount: 192,
    interval: "year",
    price_id: "price_1NezaFFALBECfhUSn6gDoRID",
    payment_link: "https://buy.stripe.com/dR6aGCdT988m4og5km",
    customer_portal_link: "https://billing.stripe.com/p/login/3cs9BC7oh9MI70k288",
    features: {
      included: ["Unlimited clients", "Unlimited projects", "Unlimited assets", "Client sharing"],
      not_included: ["Version control", "Portfolio"],
      coming_soon: [],
    },
    visible: true,
    default: false,
  },
  {
    name: "Pro Plan",
    tier: "pro",
    description: "Extras for managing assets",
    amount: 30,
    interval: "month",
    price_id: "price_1NezbrFALBECfhUS1kdqzC9t",
    payment_link: "https://buy.stripe.com/14k3eabL1bkycUMaEI",
    customer_portal_link: "https://billing.stripe.com/p/login/3cs9BC7oh9MI70k288",
    features: {
      included: ["Unlimited clients", "Unlimited projects", "Unlimited assets", "Client sharing", "Version control"],
      not_included: [],
      coming_soon: ["Portfolio"],
    },
    visible: true,
    default: false,
  },
  {
    name: "Pro Plan",
    tier: "pro",
    description: "Extras for managing assets",
    amount: 288,
    interval: "year",
    price_id: "price_1NezbrFALBECfhUSWGCPCAUM",
    payment_link: "https://buy.stripe.com/14kg0W3ev74i8EwcMP",
    customer_portal_link: "https://billing.stripe.com/p/login/3cs9BC7oh9MI70k288",
    features: {
      included: ["Unlimited clients", "Unlimited projects", "Unlimited assets", "Client sharing", "Version control"],
      not_included: [],
      coming_soon: ["Portfolio"],
    },
    visible: true,
    default: false,
  },
] as PricingPlan[];

export const SECTION_TYPES = ["BASIC", "COLUMNS", "HERO", "PRICING", "CTA", "SHOWCASE", "TRUST"] as Enums<"section_type">[];

export const ACRONYMS = new Set(["CTA", "HTML", "URL"]);

export const GRID_SIZE = 12;
export const MIN_COL_WIDTH_PERCENTAGE = (2 / GRID_SIZE) * 100;
export const MAX_COL_WIDTH_PERCENTAGE = 100 - MIN_COL_WIDTH_PERCENTAGE;
export const SNAP_DISTANCE = 10;
export const SNAP_POINTS = [33.33, 50, 66.66]; // 33.33%, 50%, and 66.66%

export const PLACEHOLDER_BLOCKS = ["IMAGE", "VIDEO"] as Enums<"block_type">[];
export const DEFAULT_SCRIPT_WPM = 140;

export const SUPPORT_EMAIL = "support@copyframe.com";

export const SLASH_PROMPT = "(or type / to open the menu)";
export const TIMESTAMPS_WIDTH = 150;

export const PLAIN_TEXT_PASTE_BLOCKS = ["accordion-button", "card"];

export const ELEMENT_MENU_ITEMS = (editor: CustomEditor) => {
  return [
    {
      group: "Basic elements",
      items: [
        {
          title: "Paragraph",
          feature: "paragraph",
          description: "Normal paragraph text",
          iconType: BiParagraph,
          commandKey: "P",
          onClick: () => {
            toggleElement("paragraph", editor);
            refocusBackspace(editor);
          },
        },
      ],
    },
    {
      group: "Heading elements",
      items: [
        {
          title: "Heading 1",
          feature: "heading",
          description: "Main page title",
          iconType: LuHeading1,
          commandKey: "H",
          onClick: () => {
            toggleHeading(editor, event, "h1");
            refocusBackspace(editor);
          },
        },
        {
          title: "Heading 2",
          feature: "heading",
          description: "Big section heading",
          iconType: LuHeading2,
          commandKey: "H",
          onClick: () => {
            toggleHeading(editor, event, "h2");
            refocusBackspace(editor);
          },
        },
        {
          title: "Heading 3",
          feature: "heading",
          description: "Medium section heading",
          iconType: LuHeading3,
          commandKey: "H",
          onClick: () => {
            toggleHeading(editor, event, "h3");
            refocusBackspace(editor);
          },
        },
        {
          title: "Heading 4",
          feature: "heading",
          description: "Small section heading",
          iconType: LuHeading4,
          commandKey: "H",
          onClick: () => {
            toggleHeading(editor, event, "h4");
            refocusBackspace(editor);
          },
        },
        {
          title: "Heading 5",
          feature: "heading",
          description: "Tiny inline heading",
          iconType: LuHeading5,
          commandKey: "H",
          onClick: () => {
            toggleHeading(editor, event, "h5");
            refocusBackspace(editor);
          },
        },
        {
          title: "Heading 6",
          feature: "heading",
          description: "Eyebrow heading",
          iconType: LuHeading6,
          commandKey: "H",
          onClick: () => {
            toggleHeading(editor, event, "h6");
            refocusBackspace(editor);
          },
        },
      ],
    },
    {
      group: "List elements",
      items: [
        {
          title: "Bulleted list",
          feature: "bulleted-list",
          description: "Bulleted list",
          iconType: BsListUl,
          commandKey: "*",
          onClick: () => {
            toggleList(editor, "bulleted-list");
            refocusBackspace(editor);
          },
        },
        {
          title: "Numbered list",
          feature: "numbered-list",
          description: "Numbered list",
          iconType: BsListOl,
          commandKey: "1",
          onClick: () => {
            toggleList(editor, "numbered-list");
            refocusBackspace(editor);
          },
        },
        {
          title: "Checked list",
          feature: "checked-list",
          description: "Checked list",
          iconType: BsListCheck,
          commandKey: "Y",
          onClick: () => {
            toggleList(editor, "checked-list");
            refocusBackspace(editor);
          },
        },
      ],
    },
    {
      group: "Other elements",
      items: [
        {
          title: "Callout",
          feature: "callout",
          description: "Make writing stand out",
          iconType: RxSection,
          commandKey: "C",
          onClick: () => {
            toggleElement("callout", editor);
            refocusBackspace(editor);
          },
        },
        {
          title: "Blockquote",
          feature: "blockquote",
          description: "Capture a quote",
          iconType: TbBlockquote,
          commandKey: '"',
          onClick: () => {
            toggleElement("blockquote", editor);
            refocusBackspace(editor);
          },
        },
        {
          title: "Button",
          feature: "button",
          description: "Call to action",
          iconType: RxButton,
          commandKey: "B",
          onClick: () => {
            toggleButton(editor);
            refocusBackspace(editor);
          },
        },
        {
          title: "Table",
          feature: "table",
          description: "Data in rows and columns",
          iconType: TbTable,
          commandKey: "E",
          onClick: () => {
            ReactEditor.focus(editor);
            const { selection } = editor;
            if (!selection) return;
            toggleTable(editor);
            Transforms.removeNodes(editor, { at: selection });
          },
        },
        {
          title: "Divider",
          feature: "divider",
          description: "Visually separate content",
          iconType: MdHorizontalRule,
          commandKey: "D",
          onClick: () => {
            refocusBackspace(editor, 2);
            insertDivider(editor);
          },
        },
        {
          title: "Optin form",
          feature: "optin",
          description: "Capture email addresses",
          iconType: BsInputCursorText,
          commandKey: "I",
          onClick: () => {
            const { selection } = editor;
            if (selection) Transforms.delete(editor, { at: { path: selection.anchor.path, offset: selection.anchor.offset - 1 }, distance: 1 });
            insertOptin(editor);
          },
        },
        {
          title: "Code",
          feature: "code",
          description: "Code",
          iconType: LuCode2,
          commandKey: "7",
          onClick: () => {
            toggleElement("code", editor);
            refocusBackspace(editor);
          },
        },
      ],
    },
  ] as ElementMenu;
};

export const TOOLBAR_FORMAT_BUTTONS = (editor: CustomEditor) =>
  [
    {
      ariaLabel: "bold",
      icon: <BiBold />,
      onClick: () => toggleMark(editor, "bold"),
      isActive: isMarkActive(editor, "bold"),
    },
    {
      ariaLabel: "italic",
      icon: <BiItalic />,
      onClick: () => toggleMark(editor, "italic"),
      isActive: isMarkActive(editor, "italic"),
    },
    {
      ariaLabel: "underline",
      icon: <BiUnderline />,
      onClick: () => toggleMark(editor, "underline"),
      isActive: isMarkActive(editor, "underline"),
    },
    {
      ariaLabel: "strikethrough",
      icon: <BiStrikethrough />,
      onClick: () => toggleMark(editor, "strike"),
      isActive: isMarkActive(editor, "strike"),
    },
    {
      ariaLabel: "link",
      icon: <BiLink />,
      onClick: () => {},
      isActive: isLinkActive(editor),
    },
    {
      ariaLabel: "superscript",
      icon: <LuSuperscript />,
      onClick: () => toggleMark(editor, "sup"),
      isActive: isMarkActive(editor, "sup"),
    },
    {
      ariaLabel: "subscript",
      icon: <LuSubscript />,
      onClick: () => toggleMark(editor, "sub"),
      isActive: isMarkActive(editor, "sub"),
    },
    {
      ariaLabel: "mark",
      icon: <LuHighlighter />,
      onClick: () => toggleMark(editor, "mark"),
      isActive: isMarkActive(editor, "mark"),
    },
  ] as ToolbarFormatButton[];

export const TOOLBAR_COLOR_BUTTONS = (editor: CustomEditor) =>
  [
    {
      ariaLabel: "red",
      icon: <BiSolidDroplet style={{ color: "var(--chakra-colors-red-500)" }} />,
      onClick: () => toggleMark(editor, "red"),
      isActive: isMarkActive(editor, "red"),
      rounded: "0",
    },
    {
      ariaLabel: "green",
      icon: <BiSolidDroplet style={{ color: "var(--chakra-colors-green-500)" }} />,
      onClick: () => toggleMark(editor, "green"),
      isActive: isMarkActive(editor, "green"),
    },
    {
      ariaLabel: "blue",
      icon: <BiSolidDroplet style={{ color: "var(--chakra-colors-blue-500)" }} />,
      onClick: () => toggleMark(editor, "blue"),
      isActive: isMarkActive(editor, "blue"),
    },
    {
      ariaLabel: "gray",
      icon: <BiSolidDroplet style={{ color: "var(--chakra-colors-gray-500)" }} />,
      onClick: () => toggleMark(editor, "gray"),
      isActive: isMarkActive(editor, "gray"),
    },
    {
      ariaLabel: "black",
      icon: <BiSolidDroplet style={{ color: "var(--chakra-colors-gray-800)" }} />,
      onClick: () => removeColor(editor),
    },
  ] as ToolbarDropdownButton[];

export const TOOLBAR_ALIGN_BUTTONS = (editor: CustomEditor) =>
  [
    {
      ariaLabel: "left",
      icon: <BiAlignLeft />,
      onClick: () => setAlignment(editor, "left"),
      isActive: getElementProperty(editor, "align") === "left" || !getElementProperty(editor, "align"),
      rounded: "0",
    },
    {
      ariaLabel: "center",
      icon: <BiAlignMiddle />,
      onClick: () => setAlignment(editor, "center"),
      isActive: getElementProperty(editor, "align") === "center",
    },
    {
      ariaLabel: "right",
      icon: <BiAlignRight />,
      onClick: () => setAlignment(editor, "right"),
      isActive: getElementProperty(editor, "align") === "right",
    },
  ] as ToolbarDropdownButton[];

export enum SOCIAL_COLOR {
  Facebook = "#4267B2",
  Reddit = "#FF5700",
  X = "#000000",
  LinkedIn = "#2867B2",
  Instagram = "#E1306C",
  Threads = "#C13584",
  Pinterest = "#E60023",
  YouTube = "#FF0000",
  TikTok = "#000000",
  Snapchat = "#FFFC00",
  Twitch = "#9146FF",
  Medium = "#000000",
  Tumblr = "#001935",
  WhatsApp = "#25D366",
  Telegram = "#0088CC",
  Discord = "#7289DA",
  Slack = "#4A154B",
  Website = "#4A5568",
  Email = "#718096",
}

export enum ASSET_TYPE_COLOR {
  EMAIL = "blue",
  PAGE = "purple",
  POST = "teal",
  SCRIPT = "cyan",
  SOCIAL = "pink",
}

export const EDITOR_JOYRIDE_STEPS = [
  // {
  //   target: "#project-name",
  //   title: "Welcome to your Drive!",
  //   content: "We've created your first project for you. You can rename it by clicking on the name.",
  //   disableBeacon: true,
  // },
  {
    target: "#project-dropdowns",
    emoji: "🎉",
    title: "Welcome to your Drive",
    content: "All of your work is organized by team, client, and project. Use these dropdowns to navigate between them.",
    disableBeacon: true,
  },
  {
    target: "#sidebar",
    emoji: "💼",
    title: "Assets in this project",
    content: "The sidebar shows all of the assets for the current project. Clicking on an asset opens it in the editor.",
  },
  {
    target: "#canvas",
    emoji: "📝",
    title: "Working on your copy",
    content: "The main editor is like a Google Doc, but you can also add layouts, placeholders, and other elements.",
  },
  {
    target: "#menu-button-status-dropdown",
    emoji: "🚦",
    title: "Track your progress",
    content: "Update the status of your work using this dropdown. See the status of your assets easily in the sidebar.",
  },
  {
    target: "#project-actions #share-button",
    emoji: "📨",
    title: "Share with clients for review",
    content: "When you're ready to share your work, invite a client to review it. They'll see the latest completed version of each asset and leave comments.",
  },
];

export const VIEWER_JOYRIDE_STEPS = [
  // {
  //   target: "#project-name",
  //   title: "Welcome to your Drive!",
  //   content: "We've created your first project for you. You can rename it by clicking on the name.",
  //   disableBeacon: true,
  // },
  {
    target: "#project-name",
    title: "Welcome to CopyFrame!",
    content: "You've been invited to review the work in this project. Let's take a quick tour.",
    disableBeacon: true,
  },
  {
    target: "#sidebar",
    title: "Assets",
    content: "The sidebar shows all of the assets you've been invited to review. You can click on an asset to open it in the editor.",
  },
  {
    target: "#canvas",
    title: "Document",
    content: "This is where you can review the copy. You can leave comments by highlighting text and clicking the comment button.",
  },
  {
    target: "#menu-button-review-dropdown",
    title: "Status",
    content: 'When you\'re done reviewing, you can change the status of the asset to "Reviewed".',
  },
  {
    target: "#all-reviewed-button",
    title: "Wrapping up",
    content: "Once you've reviewed all of the assets, you can hand the project back to the owner by clicking this button.",
  },
];

export const SOCIAL_CTA_OPTIONS = [
  "Apply now",
  "Book now",
  "Call now",
  "Contact us",
  "Donate now",
  "Download",
  "Get access",
  "Get offer",
  "Get quote",
  "Get showtimes",
  "Learn more",
  "Listen now",
  "Order now",
  "Play game",
  "Request time",
  "See menu",
  "Send message",
  "Shop now",
  "Sign up",
  "Subscribe",
  "Watch more",
];

export const acceptedLyWords = new Set([
  "actually",
  "additionally",
  "allegedly",
  "ally",
  "alternatively",
  "anomaly",
  "apply",
  "approximately",
  "ashely",
  "ashly",
  "assembly",
  "awfully",
  "baily",
  "belly",
  "bely",
  "billy",
  "bradly",
  "bristly",
  "bubbly",
  "bully",
  "burly",
  "butterfly",
  "carly",
  "charly",
  "chilly",
  "comely",
  "completely",
  "comply",
  "consequently",
  "costly",
  "courtly",
  "crinkly",
  "crumbly",
  "cuddly",
  "curly",
  "currently",
  "daily",
  "dastardly",
  "deadly",
  "deathly",
  "definitely",
  "dilly",
  "disorderly",
  "doily",
  "dolly",
  "dragonfly",
  "early",
  "elderly",
  "elly",
  "emily",
  "especially",
  "exactly",
  "exclusively",
  "family",
  "finally",
  "firefly",
  "folly",
  "frequently",
  "friendly",
  "frilly",
  "gadfly",
  "gangly",
  "generally",
  "ghastly",
  "giggly",
  "globally",
  "goodly",
  "gravelly",
  "grisly",
  "gully",
  "haily",
  "hally",
  "harly",
  "hardly",
  "heavenly",
  "hillbilly",
  "hilly",
  "holly",
  "holy",
  "homely",
  "homily",
  "horsefly",
  "hourly",
  "immediately",
  "instinctively",
  "imply",
  "italy",
  "jelly",
  "jiggly",
  "jilly",
  "jolly",
  "july",
  "karly",
  "kelly",
  "kindly",
  "lately",
  "likely",
  "lilly",
  "lily",
  "lively",
  "lolly",
  "lonely",
  "lovely",
  "lowly",
  "luckily",
  "mealy",
  "measly",
  "melancholy",
  "mentally",
  "molly",
  "monopoly",
  "monthly",
  "multiply",
  "nightly",
  "oily",
  "only",
  "orderly",
  "panoply",
  "particularly",
  "partly",
  "paully",
  "pearly",
  "pebbly",
  "polly",
  "potbelly",
  "presumably",
  "previously",
  "pualy",
  "quarterly",
  "rally",
  "rarely",
  "recently",
  "rely",
  "reply",
  "reportedly",
  "roughly",
  "sally",
  "scaly",
  "shapely",
  "shelly",
  "shirly",
  "shortly",
  "sickly",
  "silly",
  "sly",
  "smelly",
  "sparkly",
  "spindly",
  "spritely",
  "squiggly",
  "stately",
  "steely",
  "supply",
  "surly",
  "tally",
  "timely",
  "trolly",
  "ugly",
  "underbelly",
  "unfortunately",
  "unholy",
  "unlikely",
  "usually",
  "waverly",
  "weekly",
  "wholly",
  "willy",
  "wily",
  "wobbly",
  "wooly",
  "worldly",
  "wrinkly",
  "yearly",
]);

export const hedgePhrases = new Set([
  "i believe",
  "i consider",
  "i don't believe",
  "i don't consider",
  "i don't feel",
  "i don't suggest",
  "i don't think",
  "i feel",
  "i hope to",
  "i might",
  "i suggest",
  "i think",
  "i was wondering",
  "i will try",
  "i wonder",
  "in my opinion",
  "is kind of",
  "is sort of",
  "just",
  "maybe",
  "perhaps",
  "possibly",
  "we believe",
  "we consider",
  "we don't believe",
  "we don't consider",
  "we don't feel",
  "we don't suggest",
  "we don't think",
  "we feel",
  "we hope to",
  "we might",
  "we suggest",
  "we think",
  "we were wondering",
  "we will try",
  "we wonder",
]);

export const buzzwords = new Set([
  "synergy",
  "leverage",
  "paradigm",
  "disruptive",
  "innovative",
  "groundbreaking",
  "thought leader",
  "robust",
  "turnkey",
  "seamless",
  "scalable",
  "optimized",
  "empower",
  "value-added",
  "holistic",
  "actionable",
  "cutting-edge",
  "best-of-breed",
  "game-changing",
  "mission-critical",
  "revolutionary",
  "viral",
  "outside the box",
  "core competency",
  "dynamic",
  "end-to-end",
  "next-level",
  "low-hanging fruit",
  "bandwidth",
  "ecosystem",
  "solution-oriented",
  "deep dive",
  "impactful",
  "key performance indicators",
  "bottom line",
  "granular",
  "guru",
  "ninja",
  "rockstar",
  "bleeding-edge",
  "customer-centric",
  "secret sauce",
  "unprecedented",
  "incentivize",
]);

export const ignoredStringSplitters = [
  "Mr.",
  "Mrs.",
  "Ms.",
  "Dr.",
  "Prof.",
  "St.",
  "Sr.",
  "Jr.",
  "U.S.",
  "U.K.",
  "U.N.",
  "E.U.",
  "etc.",
  "e.g.",
  "i.e.",
  ".co",
  ".com",
  ".org",
  ".net",
  ".gov",
  ".edu",
  "Col.",
  "Sgt.",
  "Lt.",
  "Adm.",
  "Maj.",
  "Sen.",
  "Rep.",
  "Jan.",
  "Feb.",
  "Apr.",
  "Mar.",
  "Jun.",
  "Jul.",
  "Aug.",
  "Sep.",
  "Sept.",
  "Oct.",
  "Nov.",
  "Dec.",
  "Pvt.",
  "Cpl.",
  "Capt.",
  "Gen.",
  "Ave.",
  "inc.",
  "ft.",
  "Gov.",
  "ltd.",
  "Rev.",
  "Mme.",
  "Pres.",
  "Hon.",
  "vs.",
  "M.",
  "a.m.",
  "p.m.",
  "Bros.",
  "Ph.D.",
  "D.C.",
  "F.B.I.",
  "N.Y.",
  "N.J.",
  "N.C.",
  "C.I.A.",
  "U.S.A.",
  "e.t.c.",
  "L.A.",
  "C.E.O.",
  "C.O.O.",
  "C.F.O.",
  "M.B.A.",
  "P.S.",
  "R.S.V.P.",
  "B.C.",
  "A.D.",
  "B.C.E.",
  "C.E.",
  "O.K.",
  "D.O.B.",
  "I.P.",
  "V.I.P.",
  "A.K.A.",
  "H.Q.",
  "R.D.",
  "Bldg.",
  "Pl.",
  "Sq.",
  "Vol.",
  "No.",
  "oz.",
  "lb.",
  "yd.",
  "qt.",
  "gal.",
  "mi.",
  "M.P.H.",
  "K.M.",
  "K.G.",
  "M.G.",
  "E.S.T.",
  "P.S.T.",
  "C.S.T.",
  "G.M.T.",
  "P.O.",
  "P.O. Box",
  "I.D.",
  ".com",
  ".org",
  ".net",
  ".int",
  ".edu",
  ".gov",
  ".mil",
  ".arpa",
  ".ac",
  ".ad",
  ".ae",
  ".af",
  ".ag",
  ".ai",
  ".al",
  ".am",
  ".an",
  ".ao",
  ".aq",
  ".ar",
  ".as",
  ".at",
  ".au",
  ".aw",
  ".ax",
  ".az",
  ".ba",
  ".bb",
  ".bd",
  ".be",
  ".bf",
  ".bg",
  ".bh",
  ".bi",
  ".bj",
  ".bm",
  ".bn",
  ".bo",
  ".br",
  ".bs",
  ".bt",
  ".bw",
  ".by",
  ".bz",
  ".ca",
  ".cc",
  ".cd",
  ".cf",
  ".cg",
  ".ch",
  ".ci",
  ".ck",
  ".cl",
  ".cm",
  ".cn",
  ".co",
  ".cr",
  ".cu",
  ".cv",
  ".cw",
  ".cx",
  ".cy",
  ".cz",
  ".de",
  ".dj",
  ".dk",
  ".dm",
  ".do",
  ".dz",
  ".ec",
  ".ee",
  ".eg",
  ".es",
  ".et",
  ".eu",
  ".fi",
  ".fj",
  ".fk",
  ".fm",
  ".fo",
  ".fr",
  ".ga",
  ".gd",
  ".ge",
  ".gf",
  ".gg",
  ".gh",
  ".gi",
  ".gl",
  ".gm",
  ".gn",
  ".gp",
  ".gq",
  ".gr",
  ".gs",
  ".gt",
  ".gu",
  ".gw",
  ".gy",
  ".hk",
  ".hm",
  ".hn",
  ".hr",
  ".ht",
  ".hu",
  ".id",
  ".ie",
  ".il",
  ".im",
  ".in",
  ".io",
  ".iq",
  ".ir",
  ".is",
  ".it",
  ".je",
  ".jm",
  ".jo",
  ".jp",
  ".ke",
  ".kg",
  ".kh",
  ".ki",
  ".km",
  ".kn",
  ".kr",
  ".kw",
  ".ky",
  ".kz",
  ".la",
  ".lb",
  ".lc",
  ".li",
  ".lk",
  ".lr",
  ".ls",
  ".lt",
  ".lu",
  ".lv",
  ".ly",
  ".ma",
  ".mc",
  ".md",
  ".me",
  ".mg",
  ".mh",
  ".mk",
  ".ml",
  ".mm",
  ".mn",
  ".mo",
  ".mp",
  ".mq",
  ".mr",
  ".ms",
  ".mt",
  ".mu",
  ".mv",
  ".mw",
  ".mx",
  ".my",
  ".mz",
  ".na",
  ".nc",
  ".ne",
  ".nf",
  ".ng",
  ".ni",
  ".nl",
  ".no",
  ".np",
  ".nr",
  ".nu",
  ".nz",
  ".om",
  ".pa",
  ".pe",
  ".pf",
  ".pg",
  ".ph",
  ".pk",
  ".pl",
  ".pm",
  ".pn",
  ".pr",
  ".ps",
  ".pt",
  ".pw",
  ".py",
  ".qa",
  ".re",
  ".ro",
  ".rs",
  ".ru",
  ".rw",
  ".sa",
  ".sb",
  ".sc",
  ".sd",
  ".se",
  ".sg",
  ".sh",
  ".si",
  ".sk",
  ".sl",
  ".sm",
  ".sn",
  ".so",
  ".sr",
  ".ss",
  ".st",
  ".sv",
  ".sx",
  ".sy",
  ".sz",
  ".tc",
  ".td",
  ".tf",
  ".tg",
  ".th",
  ".tj",
  ".tk",
  ".tl",
  ".tm",
  ".tn",
  ".to",
  ".tp",
  ".tr",
  ".tt",
  ".tv",
  ".tw",
  ".tz",
  ".ua",
  ".ug",
  ".uk",
  ".us",
  ".uy",
  ".uz",
  ".va",
  ".vc",
  ".ve",
  ".vg",
  ".vi",
  ".vn",
  ".vu",
  ".wf",
  ".ws",
  ".ye",
  ".yt",
  ".za",
  ".zm",
  ".zw",
  ".co.uk", // Commonly used second-level domain in the UK
  ".org.uk", // Commonly used second-level domain for organizations in the UK
  ".gov.uk", // Used for government sites in the UK
  ".ac.uk", // Academic (generally universities) in the UK
  ".me.uk", // Personal websites in the UK
];

export const NO_SUB_WARNING = {
  title: "Read Only",
  description: "This is read-only because you don't have a paid CopyFrame subscription. Upgrade to edit it!",
};

export function openReadOnlyModal(openModal: ModalContextType["openModal"], profile: ClientSideProfile | null, subscription: BasicSubscription | null) {
  if (!profile) return;
  openModal(
    `Upgrade Your Plan`,
    <Box>
      <Text mb={6}>
        You need to be on a{" "}
        <Text as="span" fontWeight="semibold">
          paid plan
        </Text>{" "}
        to use that feature.
      </Text>
      <SubscriptionPlans subscription={subscription} profile={profile} />
    </Box>,
    "4xl"
  );
}

export const EMOJIS = [
  { code: "U+1F600", emoji: "😀", description: "grinning face smile smiling" },
  { code: "U+1F603", emoji: "😃", description: "grinning face with big eyes smile smiling" },
  { code: "U+1F604", emoji: "😄", description: "grinning face with smile smiling eyes" },
  { code: "U+1F601", emoji: "😁", description: "beaming face with smile smiling eyes" },
  { code: "U+1F606", emoji: "😆", description: "grinning squinting face laughing funny" },
  { code: "U+1F605", emoji: "😅", description: "grinning face with sweat laughing funny" },
  { code: "U+1F923", emoji: "🤣", description: "rolling on the floor laughing funny" },
  { code: "U+1F602", emoji: "😂", description: "face with tears of joy laughing funny" },
  { code: "U+1F642", emoji: "🙂", description: "slightly smile smiling face" },
  { code: "U+1F643", emoji: "🙃", description: "upside-down face upside" },
  { code: "U+1FAE0", emoji: "🫠", description: "melting face" },
  { code: "U+1F609", emoji: "😉", description: "winking face" },
  { code: "U+1F60A", emoji: "😊", description: "smile smiling face with smile smiling eyes blush pleased" },
  { code: "U+1F607", emoji: "😇", description: "smile smiling face with halo angel innocent" },

  { code: "U+1F970", emoji: "🥰", description: "smile smiling face with hearts love" },
  { code: "U+1F60D", emoji: "😍", description: "smile smiling face with heart-eyes love" },
  { code: "U+1F929", emoji: "🤩", description: "star-struck" },
  { code: "U+1F618", emoji: "😘", description: "face blowing a kiss" },
  { code: "U+1F617", emoji: "😗", description: "kissing face kiss" },
  { code: "U+263A", emoji: "☺", description: "smile smiling face" },
  { code: "U+1F61A", emoji: "😚", description: "kissing face with closed eyes" },
  { code: "U+1F619", emoji: "😙", description: "kissing face with smile smiling eyes" },
  { code: "U+1F972", emoji: "🥲", description: "smile smiling face with tear" },

  { code: "U+1F60B", emoji: "😋", description: "face savoring food tongue out" },
  { code: "U+1F61B", emoji: "😛", description: "face with tongue out" },
  { code: "U+1F61C", emoji: "😜", description: "winking face with tongue out" },
  { code: "U+1F92A", emoji: "🤪", description: "zany face wacky weird crazy" },
  { code: "U+1F61D", emoji: "😝", description: "squinting face with tongue out" },
  { code: "U+1F911", emoji: "🤑", description: "money-mouth face cash rich" },

  { code: "U+1F917", emoji: "🤗", description: "smile smiling face with open hands hugging face" },
  { code: "U+1F92D", emoji: "🤭", description: "face with hand over mouth giggle shy laughing" },
  { code: "U+1FAE2", emoji: "🫢", description: "face with open eyes and hand over mouth quiet" },
  { code: "U+1FAE3", emoji: "🫣", description: "face with peeking eye nervous peek hide" },
  { code: "U+1F92B", emoji: "🤫", description: "shushing face quiet shush hush" },
  { code: "U+1F914", emoji: "🤔", description: "thinking face pondering hm" },
  { code: "U+1FAE1", emoji: "🫡", description: "saluting face" },

  { code: "U+1F910", emoji: "🤐", description: "zipper-mouth face quiet silent zip" },
  { code: "U+1F928", emoji: "🤨", description: "face with raised eyebrow sceptical skeptical" },
  { code: "U+1F610", emoji: "😐", description: "neutral face blank unamused" },
  { code: "U+1F611", emoji: "😑", description: "expressionless face flat unamused" },
  { code: "U+1F636", emoji: "😶", description: "face without mouth no mouth silent quiet" },
  { code: "U+1FAE5", emoji: "🫥", description: "dotted line face transparent invisible" },
  { code: "U+1F636 U+200D U+1F32B U+FE0F", emoji: "😶‍🌫️", description: "face in clouds" },
  { code: "U+1F60F", emoji: "😏", description: "smirking face smirk smile" },
  { code: "U+1F612", emoji: "😒", description: "unamused face annoyed irritated mutter" },
  { code: "U+1F644", emoji: "🙄", description: "face with rolling eyes eye roll" },
  { code: "U+1F62C", emoji: "😬", description: "grimacing face grimace grin" },
  { code: "U+1F62E U+200D U+1F4A8", emoji: "😮‍💨", description: "face exhaling" },
  { code: "U+1F925", emoji: "🤥", description: "lying face pinocchio" },
  { code: "U+1FAE8", emoji: "🫨", description: "shaking face" },
  { code: "U+1F642 U+200D U+2194 U+FE0F", emoji: "🙂‍↔️", description: "⊛ head shaking horizontally" },
  { code: "U+1F642 U+200D U+2195 U+FE0F", emoji: "🙂‍↕️", description: "⊛ head shaking vertically" },

  { code: "U+1F60C", emoji: "😌", description: "relieved face calm grateful blessed happy" },
  { code: "U+1F614", emoji: "😔", description: "pensive face sad down" },
  { code: "U+1F62A", emoji: "😪", description: "sleepy face sad down" },
  { code: "U+1F924", emoji: "🤤", description: "drooling face dribble drool" },
  { code: "U+1F634", emoji: "😴", description: "sleeping face sleep tired nap zz" },

  { code: "U+1F637", emoji: "😷", description: "face with medical mask face mask" },
  { code: "U+1F912", emoji: "🤒", description: "face with thermometer sick ill poorly" },
  { code: "U+1F915", emoji: "🤕", description: "face with head-bandage sick poorly bandage" },
  { code: "U+1F922", emoji: "🤢", description: "nauseated face sick vomit" },
  { code: "U+1F92E", emoji: "🤮", description: "face vomiting vomit sick" },
  { code: "U+1F927", emoji: "🤧", description: "sneezing face sneeze" },
  { code: "U+1F975", emoji: "🥵", description: "hot face heat" },
  { code: "U+1F976", emoji: "🥶", description: "cold face freezing chilly" },
  { code: "U+1F974", emoji: "🥴", description: "woozy face drunk" },
  { code: "U+1F635", emoji: "😵", description: "face with crossed-out eyes" },
  { code: "U+1F635 U+200D U+1F4AB", emoji: "😵‍💫", description: "face with spiral eyes" },
  { code: "U+1F92F", emoji: "🤯", description: "exploding head mind blown" },

  { code: "U+1F920", emoji: "🤠", description: "cowboy hat face" },
  { code: "U+1F973", emoji: "🥳", description: "partying face party celebrate" },
  { code: "U+1F978", emoji: "🥸", description: "disguised face" },

  { code: "U+1F60E", emoji: "😎", description: "smile smiling face with sunglasses cool" },
  { code: "U+1F913", emoji: "🤓", description: "nerd face geek glasses" },
  { code: "U+1F9D0", emoji: "🧐", description: "face with monocle inspect" },

  { code: "U+1F615", emoji: "😕", description: "confused face sad" },
  { code: "U+1FAE4", emoji: "🫤", description: "face with diagonal mouth awkward" },
  { code: "U+1F61F", emoji: "😟", description: "worried face sad" },
  { code: "U+1F641", emoji: "🙁", description: "slightly frowning face sad" },
  { code: "U+2639", emoji: "☹", description: "frowning face" },
  { code: "U+1F62E", emoji: "😮", description: "face with open mouth shock surprise" },
  { code: "U+1F62F", emoji: "😯", description: "hushed face shock surprise" },
  { code: "U+1F632", emoji: "😲", description: "astonished face shock surprise" },
  { code: "U+1F633", emoji: "😳", description: "flushed face embarrassed blush" },
  { code: "U+1F97A", emoji: "🥺", description: "pleading face sad puppy dog cute" },
  { code: "U+1F979", emoji: "🥹", description: "face holding back tears sad puppy dog cute" },
  { code: "U+1F626", emoji: "😦", description: "frowning face with open mouth" },
  { code: "U+1F627", emoji: "😧", description: "anguished face shock" },
  { code: "U+1F628", emoji: "😨", description: "fearful face nervous scared anxious" },
  { code: "U+1F630", emoji: "😰", description: "anxious face with sweat nervous anxious" },
  { code: "U+1F625", emoji: "😥", description: "sad but relieved face cry tear" },
  { code: "U+1F622", emoji: "😢", description: "crying face tear cry" },
  { code: "U+1F62D", emoji: "😭", description: "loudly crying face cry tears" },
  { code: "U+1F631", emoji: "😱", description: "face screaming in fear scream" },
  { code: "U+1F616", emoji: "😖", description: "confounded face frustrated angry" },

  { code: "U+1F623", emoji: "😣", description: "persevering face tired" },
  { code: "U+1F61E", emoji: "😞", description: "disappointed face sad" },
  { code: "U+1F613", emoji: "😓", description: "downcast face with sweat sad tired" },
  { code: "U+1F629", emoji: "😩", description: "weary face tired sleepy exhausted" },
  { code: "U+1F62B", emoji: "😫", description: "tired face sleepy exhausted" },
  { code: "U+1F971", emoji: "🥱", description: "yawning face tired sleepy yawn" },

  { code: "U+1F624", emoji: "😤", description: "face with steam from nose angry" },
  { code: "U+1F621", emoji: "😡", description: "enraged face angry furious" },
  { code: "U+1F620", emoji: "😠", description: "angry face furious" },
  { code: "U+1F92C", emoji: "🤬", description: "face with symbols on mouth angry furious fuming" },
  { code: "U+1F608", emoji: "😈", description: "smile smiling face with horns devil smile" },
  { code: "U+1F47F", emoji: "👿", description: "angry face with horns devil" },
  { code: "U+1F480", emoji: "💀", description: "skull death dead" },
  { code: "U+2620", emoji: "☠", description: "skull and crossbones" },

  { code: "U+1F4A9", emoji: "💩", description: "pile of poo shit" },
  { code: "U+1F921", emoji: "🤡", description: "clown face" },
  { code: "U+1F479", emoji: "👹", description: "ogre devil" },
  { code: "U+1F47A", emoji: "👺", description: "goblin devil" },
  { code: "U+1F47B", emoji: "👻", description: "ghost boo scare" },
  { code: "U+1F47D", emoji: "👽", description: "alien" },
  { code: "U+1F47E", emoji: "👾", description: "alien monster space invader" },
  { code: "U+1F916", emoji: "🤖", description: "robot ai bot" },

  { code: "U+1F63A", emoji: "😺", description: "grinning cat" },
  { code: "U+1F638", emoji: "😸", description: "grinning cat with smile smiling eyes" },
  { code: "U+1F639", emoji: "😹", description: "cat with tears of joy" },
  { code: "U+1F63B", emoji: "😻", description: "smile smiling cat with heart-eyes" },
  { code: "U+1F63C", emoji: "😼", description: "cat with wry smile" },
  { code: "U+1F63D", emoji: "😽", description: "kissing cat" },
  { code: "U+1F640", emoji: "🙀", description: "weary cat" },
  { code: "U+1F63F", emoji: "😿", description: "crying cat" },
  { code: "U+1F63E", emoji: "😾", description: "pouting cat" },

  { code: "U+1F648", emoji: "🙈", description: "see-no-evil monkey hide eye look" },
  { code: "U+1F649", emoji: "🙉", description: "hear-no-evil monkey ears listen" },
  { code: "U+1F64A", emoji: "🙊", description: "speak-no-evil monkey quiet" },

  { code: "U+1F48C", emoji: "💌", description: "love letter" },
  { code: "U+1F498", emoji: "💘", description: "heart with arrow" },
  { code: "U+1F49D", emoji: "💝", description: "heart with ribbon" },
  { code: "U+1F496", emoji: "💖", description: "sparkling heart" },
  { code: "U+1F497", emoji: "💗", description: "growing heart" },
  { code: "U+1F493", emoji: "💓", description: "beating heart" },
  { code: "U+1F49E", emoji: "💞", description: "revolving hearts" },
  { code: "U+1F495", emoji: "💕", description: "two hearts" },
  { code: "U+1F49F", emoji: "💟", description: "heart decoration" },
  { code: "U+2763", emoji: "❣", description: "heart exclamation" },
  { code: "U+1F494", emoji: "💔", description: "broken heart" },
  { code: "U+2764 U+FE0F U+200D U+1F525", emoji: "❤️‍🔥", description: "heart on fire" },
  { code: "U+2764 U+FE0F U+200D U+1FA79", emoji: "❤️‍🩹", description: "mending heart" },
  { code: "U+2764", emoji: "❤", description: "red heart" },
  { code: "U+1FA77", emoji: "🩷", description: "pink heart" },
  { code: "U+1F9E1", emoji: "🧡", description: "orange heart" },
  { code: "U+1F49B", emoji: "💛", description: "yellow heart" },
  { code: "U+1F49A", emoji: "💚", description: "green heart" },
  { code: "U+1F499", emoji: "💙", description: "blue heart" },
  { code: "U+1FA75", emoji: "🩵", description: "light blue heart" },

  { code: "U+1F49C", emoji: "💜", description: "purple heart" },
  { code: "U+1F90E", emoji: "🤎", description: "brown heart" },
  { code: "U+1F5A4", emoji: "🖤", description: "black heart" },
  { code: "U+1FA76", emoji: "🩶", description: "grey heart" },
  { code: "U+1F90D", emoji: "🤍", description: "white heart" },

  { code: "U+1F48B", emoji: "💋", description: "kiss mark" },
  { code: "U+1F4AF", emoji: "💯", description: "hundred points" },
  { code: "U+1F4A2", emoji: "💢", description: "anger symbol" },
  { code: "U+1F4A5", emoji: "💥", description: "collision" },
  { code: "U+1F4AB", emoji: "💫", description: "dizzy" },
  { code: "U+1F4A6", emoji: "💦", description: "sweat droplets" },
  { code: "U+1F4A8", emoji: "💨", description: "dashing away" },
  { code: "U+1F573", emoji: "🕳", description: "hole" },
  { code: "U+1F4AC", emoji: "💬", description: "speech balloon" },
  { code: "U+1F441 U+FE0F U+200D U+1F5E8 U+FE0F", emoji: "👁️‍🗨️", description: "eye in speech bubble" },
  { code: "U+1F5E8", emoji: "🗨", description: "left speech bubble" },
  { code: "U+1F5EF", emoji: "🗯", description: "right anger bubble" },
  { code: "U+1F4AD", emoji: "💭", description: "thought balloon" },
  { code: "U+1F4A4", emoji: "💤", description: "ZZZ" },

  { code: "U+1F44B", emoji: "👋", description: "waving hand wave hi hello hey" },
  { code: "U+1F91A", emoji: "🤚", description: "raised back of hand" },
  { code: "U+1F590", emoji: "🖐", description: "hand with fingers splayed" },
  { code: "U+270B", emoji: "✋", description: "raised hand" },
  { code: "U+1F596", emoji: "🖖", description: "vulcan salute star trek" },
  { code: "U+1FAF1", emoji: "🫱", description: "rightwards hand right" },
  { code: "U+1FAF2", emoji: "🫲", description: "leftwards hand left" },
  { code: "U+1FAF3", emoji: "🫳", description: "palm down hand" },
  { code: "U+1FAF4", emoji: "🫴", description: "palm up hand" },
  { code: "U+1FAF7", emoji: "🫷", description: "leftwards pushing hand" },
  { code: "U+1FAF8", emoji: "🫸", description: "rightwards pushing hand" },

  { code: "U+1F44C", emoji: "👌", description: "OK hand okay" },
  { code: "U+1F90C", emoji: "🤌", description: "pinched fingers" },
  { code: "U+1F90F", emoji: "🤏", description: "pinching hand pinch" },
  { code: "U+270C", emoji: "✌", description: "victory hand" },
  { code: "U+1F91E", emoji: "🤞", description: "crossed fingers hope" },
  { code: "U+1FAF0", emoji: "🫰", description: "hand with index finger and thumb crossed" },
  { code: "U+1F91F", emoji: "🤟", description: "love-you gesture" },
  { code: "U+1F918", emoji: "🤘", description: "sign of the horns rock" },
  { code: "U+1F919", emoji: "🤙", description: "call me hand rad awesome" },

  { code: "U+1F448", emoji: "👈", description: "backhand index pointing left back" },
  { code: "U+1F449", emoji: "👉", description: "backhand index pointing right forward" },
  { code: "U+1F446", emoji: "👆", description: "backhand index pointing up" },
  { code: "U+1F595", emoji: "🖕", description: "middle finger fuck swear" },
  { code: "U+1F447", emoji: "👇", description: "backhand index pointing down point" },
  { code: "U+261D", emoji: "☝", description: "index pointing up" },
  { code: "U+1FAF5", emoji: "🫵", description: "index pointing at the viewer" },

  { code: "U+1F44D", emoji: "👍", description: "thumbs up" },
  { code: "U+1F44E", emoji: "👎", description: "thumbs down" },
  { code: "U+270A", emoji: "✊", description: "raised fist" },
  { code: "U+1F44A", emoji: "👊", description: "oncoming fist bump" },
  { code: "U+1F91B", emoji: "🤛", description: "left-facing fist" },
  { code: "U+1F91C", emoji: "🤜", description: "right-facing fist" },

  { code: "U+1F44F", emoji: "👏", description: "clapping hands clap" },
  { code: "U+1F64C", emoji: "🙌", description: "raising hands praise" },
  { code: "U+1FAF6", emoji: "🫶", description: "heart hands love hands" },
  { code: "U+1F450", emoji: "👐", description: "open hands two hands" },
  { code: "U+1F932", emoji: "🤲", description: "palms up together two hands" },
  { code: "U+1F91D", emoji: "🤝", description: "handshake shake hands" },
  { code: "U+1F64F", emoji: "🙏", description: "folded hands pray" },

  { code: "U+270D", emoji: "✍", description: "writing hand" },
  { code: "U+1F485", emoji: "💅", description: "nail polish" },
  { code: "U+1F933", emoji: "🤳", description: "selfie" },

  { code: "U+1F4AA", emoji: "💪", description: "flexed biceps strong muscle" },
  { code: "U+1F9BE", emoji: "🦾", description: "mechanical arm" },
  { code: "U+1F9BF", emoji: "🦿", description: "mechanical leg" },
  { code: "U+1F9B5", emoji: "🦵", description: "leg" },
  { code: "U+1F9B6", emoji: "🦶", description: "foot" },
  { code: "U+1F442", emoji: "👂", description: "ear" },
  { code: "U+1F9BB", emoji: "🦻", description: "ear with hearing aid" },
  { code: "U+1F443", emoji: "👃", description: "nose" },
  { code: "U+1F9E0", emoji: "🧠", description: "brain" },
  { code: "U+1FAC0", emoji: "🫀", description: "anatomical heart" },
  { code: "U+1FAC1", emoji: "🫁", description: "lungs" },
  { code: "U+1F9B7", emoji: "🦷", description: "tooth" },
  { code: "U+1F9B4", emoji: "🦴", description: "bone" },
  { code: "U+1F440", emoji: "👀", description: "eye look see" },
  { code: "U+1F441", emoji: "👁", description: "eye" },
  { code: "U+1F445", emoji: "👅", description: "tongue" },
  { code: "U+1F444", emoji: "👄", description: "mouth" },
  { code: "U+1FAE6", emoji: "🫦", description: "biting lip" },

  { code: "U+1F476", emoji: "👶", description: "baby" },
  { code: "U+1F9D2", emoji: "🧒", description: "child" },
  { code: "U+1F466", emoji: "👦", description: "boy" },
  { code: "U+1F467", emoji: "👧", description: "girl" },
  { code: "U+1F9D1", emoji: "🧑", description: "person" },
  { code: "U+1F471", emoji: "👱", description: "person: blond hair" },
  { code: "U+1F468", emoji: "👨", description: "man" },
  { code: "U+1F9D4", emoji: "🧔", description: "person: beard" },
  { code: "U+1F9D4 U+200D U+2642 U+FE0F", emoji: "🧔‍♂️", description: "man: beard" },
  { code: "U+1F9D4 U+200D U+2640 U+FE0F", emoji: "🧔‍♀️", description: "woman: beard" },
  { code: "U+1F468 U+200D U+1F9B0", emoji: "👨‍🦰", description: "man: red hair" },
  { code: "U+1F468 U+200D U+1F9B1", emoji: "👨‍🦱", description: "man: curly hair" },
  { code: "U+1F468 U+200D U+1F9B3", emoji: "👨‍🦳", description: "man: white hair" },
  { code: "U+1F468 U+200D U+1F9B2", emoji: "👨‍🦲", description: "man: bald" },
  { code: "U+1F469", emoji: "👩", description: "woman" },
  { code: "U+1F469 U+200D U+1F9B0", emoji: "👩‍🦰", description: "woman: red hair" },
  { code: "U+1F9D1 U+200D U+1F9B0", emoji: "🧑‍🦰", description: "person: red hair" },
  { code: "U+1F469 U+200D U+1F9B1", emoji: "👩‍🦱", description: "woman: curly hair" },
  { code: "U+1F9D1 U+200D U+1F9B1", emoji: "🧑‍🦱", description: "person: curly hair" },
  { code: "U+1F469 U+200D U+1F9B3", emoji: "👩‍🦳", description: "woman: white hair" },

  { code: "U+1F9D1 U+200D U+1F9B3", emoji: "🧑‍🦳", description: "person: white hair" },
  { code: "U+1F469 U+200D U+1F9B2", emoji: "👩‍🦲", description: "woman: bald" },
  { code: "U+1F9D1 U+200D U+1F9B2", emoji: "🧑‍🦲", description: "person: bald" },
  { code: "U+1F471 U+200D U+2640 U+FE0F", emoji: "👱‍♀️", description: "woman: blond hair" },
  { code: "U+1F471 U+200D U+2642 U+FE0F", emoji: "👱‍♂️", description: "man: blond hair" },
  { code: "U+1F9D3", emoji: "🧓", description: "older person" },
  { code: "U+1F474", emoji: "👴", description: "old man" },
  { code: "U+1F475", emoji: "👵", description: "old woman" },

  { code: "U+1F64D", emoji: "🙍", description: "person frowning" },
  { code: "U+1F64D U+200D U+2642 U+FE0F", emoji: "🙍‍♂️", description: "man frowning" },
  { code: "U+1F64D U+200D U+2640 U+FE0F", emoji: "🙍‍♀️", description: "woman frowning" },
  { code: "U+1F64E", emoji: "🙎", description: "person pouting" },
  { code: "U+1F64E U+200D U+2642 U+FE0F", emoji: "🙎‍♂️", description: "man pouting" },
  { code: "U+1F64E U+200D U+2640 U+FE0F", emoji: "🙎‍♀️", description: "woman pouting" },
  { code: "U+1F645", emoji: "🙅", description: "person gesturing NO" },
  { code: "U+1F645 U+200D U+2642 U+FE0F", emoji: "🙅‍♂️", description: "man gesturing NO" },
  { code: "U+1F645 U+200D U+2640 U+FE0F", emoji: "🙅‍♀️", description: "woman gesturing NO" },
  { code: "U+1F646", emoji: "🙆", description: "person gesturing OK" },
  { code: "U+1F646 U+200D U+2642 U+FE0F", emoji: "🙆‍♂️", description: "man gesturing OK" },
  { code: "U+1F646 U+200D U+2640 U+FE0F", emoji: "🙆‍♀️", description: "woman gesturing OK" },
  { code: "U+1F481", emoji: "💁", description: "person tipping hand" },
  { code: "U+1F481 U+200D U+2642 U+FE0F", emoji: "💁‍♂️", description: "man tipping hand" },
  { code: "U+1F481 U+200D U+2640 U+FE0F", emoji: "💁‍♀️", description: "woman tipping hand" },
  { code: "U+1F64B", emoji: "🙋", description: "person raising hand" },
  { code: "U+1F64B U+200D U+2642 U+FE0F", emoji: "🙋‍♂️", description: "man raising hand" },
  { code: "U+1F64B U+200D U+2640 U+FE0F", emoji: "🙋‍♀️", description: "woman raising hand" },
  { code: "U+1F9CF", emoji: "🧏", description: "deaf person" },
  { code: "U+1F9CF U+200D U+2642 U+FE0F", emoji: "🧏‍♂️", description: "deaf man" },

  { code: "U+1F9CF U+200D U+2640 U+FE0F", emoji: "🧏‍♀️", description: "deaf woman" },
  { code: "U+1F647", emoji: "🙇", description: "person bowing" },
  { code: "U+1F647 U+200D U+2642 U+FE0F", emoji: "🙇‍♂️", description: "man bowing" },
  { code: "U+1F647 U+200D U+2640 U+FE0F", emoji: "🙇‍♀️", description: "woman bowing" },
  { code: "U+1F926", emoji: "🤦", description: "person facepalming" },
  { code: "U+1F926 U+200D U+2642 U+FE0F", emoji: "🤦‍♂️", description: "man facepalming" },
  { code: "U+1F926 U+200D U+2640 U+FE0F", emoji: "🤦‍♀️", description: "woman facepalming" },
  { code: "U+1F937", emoji: "🤷", description: "person shrugging" },
  { code: "U+1F937 U+200D U+2642 U+FE0F", emoji: "🤷‍♂️", description: "man shrugging" },
  { code: "U+1F937 U+200D U+2640 U+FE0F", emoji: "🤷‍♀️", description: "woman shrugging" },

  { code: "U+1F9D1 U+200D U+2695 U+FE0F", emoji: "🧑‍⚕️", description: "health worker" },
  { code: "U+1F468 U+200D U+2695 U+FE0F", emoji: "👨‍⚕️", description: "man health worker" },
  { code: "U+1F469 U+200D U+2695 U+FE0F", emoji: "👩‍⚕️", description: "woman health worker" },
  { code: "U+1F9D1 U+200D U+1F393", emoji: "🧑‍🎓", description: "student" },
  { code: "U+1F468 U+200D U+1F393", emoji: "👨‍🎓", description: "man student" },
  { code: "U+1F469 U+200D U+1F393", emoji: "👩‍🎓", description: "woman student" },
  { code: "U+1F9D1 U+200D U+1F3EB", emoji: "🧑‍🏫", description: "teacher" },
  { code: "U+1F468 U+200D U+1F3EB", emoji: "👨‍🏫", description: "man teacher" },
  { code: "U+1F469 U+200D U+1F3EB", emoji: "👩‍🏫", description: "woman teacher" },
  { code: "U+1F9D1 U+200D U+2696 U+FE0F", emoji: "🧑‍⚖️", description: "judge" },
  { code: "U+1F468 U+200D U+2696 U+FE0F", emoji: "👨‍⚖️", description: "man judge" },
  { code: "U+1F469 U+200D U+2696 U+FE0F", emoji: "👩‍⚖️", description: "woman judge" },
  { code: "U+1F9D1 U+200D U+1F33E", emoji: "🧑‍🌾", description: "farmer" },
  { code: "U+1F468 U+200D U+1F33E", emoji: "👨‍🌾", description: "man farmer" },
  { code: "U+1F469 U+200D U+1F33E", emoji: "👩‍🌾", description: "woman farmer" },
  { code: "U+1F9D1 U+200D U+1F373", emoji: "🧑‍🍳", description: "cook" },
  { code: "U+1F468 U+200D U+1F373", emoji: "👨‍🍳", description: "man cook" },
  { code: "U+1F469 U+200D U+1F373", emoji: "👩‍🍳", description: "woman cook" },
  { code: "U+1F9D1 U+200D U+1F527", emoji: "🧑‍🔧", description: "mechanic" },
  { code: "U+1F468 U+200D U+1F527", emoji: "👨‍🔧", description: "man mechanic" },

  { code: "U+1F469 U+200D U+1F527", emoji: "👩‍🔧", description: "woman mechanic" },
  { code: "U+1F9D1 U+200D U+1F3ED", emoji: "🧑‍🏭", description: "factory worker" },
  { code: "U+1F468 U+200D U+1F3ED", emoji: "👨‍🏭", description: "man factory worker" },
  { code: "U+1F469 U+200D U+1F3ED", emoji: "👩‍🏭", description: "woman factory worker" },
  { code: "U+1F9D1 U+200D U+1F4BC", emoji: "🧑‍💼", description: "office worker" },
  { code: "U+1F468 U+200D U+1F4BC", emoji: "👨‍💼", description: "man office worker" },
  { code: "U+1F469 U+200D U+1F4BC", emoji: "👩‍💼", description: "woman office worker" },
  { code: "U+1F9D1 U+200D U+1F52C", emoji: "🧑‍🔬", description: "scientist" },
  { code: "U+1F468 U+200D U+1F52C", emoji: "👨‍🔬", description: "man scientist" },
  { code: "U+1F469 U+200D U+1F52C", emoji: "👩‍🔬", description: "woman scientist" },
  { code: "U+1F9D1 U+200D U+1F4BB", emoji: "🧑‍💻", description: "technologist" },
  { code: "U+1F468 U+200D U+1F4BB", emoji: "👨‍💻", description: "man technologist" },
  { code: "U+1F469 U+200D U+1F4BB", emoji: "👩‍💻", description: "woman technologist" },
  { code: "U+1F9D1 U+200D U+1F3A4", emoji: "🧑‍🎤", description: "singer" },
  { code: "U+1F468 U+200D U+1F3A4", emoji: "👨‍🎤", description: "man singer" },
  { code: "U+1F469 U+200D U+1F3A4", emoji: "👩‍🎤", description: "woman singer" },
  { code: "U+1F9D1 U+200D U+1F3A8", emoji: "🧑‍🎨", description: "artist" },
  { code: "U+1F468 U+200D U+1F3A8", emoji: "👨‍🎨", description: "man artist" },
  { code: "U+1F469 U+200D U+1F3A8", emoji: "👩‍🎨", description: "woman artist" },
  { code: "U+1F9D1 U+200D U+2708 U+FE0F", emoji: "🧑‍✈️", description: "pilot" },

  { code: "U+1F468 U+200D U+2708 U+FE0F", emoji: "👨‍✈️", description: "man pilot" },
  { code: "U+1F469 U+200D U+2708 U+FE0F", emoji: "👩‍✈️", description: "woman pilot" },
  { code: "U+1F9D1 U+200D U+1F680", emoji: "🧑‍🚀", description: "astronaut" },
  { code: "U+1F468 U+200D U+1F680", emoji: "👨‍🚀", description: "man astronaut" },
  { code: "U+1F469 U+200D U+1F680", emoji: "👩‍🚀", description: "woman astronaut" },
  { code: "U+1F9D1 U+200D U+1F692", emoji: "🧑‍🚒", description: "firefighter" },
  { code: "U+1F468 U+200D U+1F692", emoji: "👨‍🚒", description: "man firefighter" },
  { code: "U+1F469 U+200D U+1F692", emoji: "👩‍🚒", description: "woman firefighter" },
  { code: "U+1F46E", emoji: "👮", description: "police officer" },
  { code: "U+1F46E U+200D U+2642 U+FE0F", emoji: "👮‍♂️", description: "man police officer" },
  { code: "U+1F46E U+200D U+2640 U+FE0F", emoji: "👮‍♀️", description: "woman police officer" },
  { code: "U+1F575", emoji: "🕵", description: "detective" },
  { code: "U+1F575 U+FE0F U+200D U+2642 U+FE0F", emoji: "🕵️‍♂️", description: "man detective" },
  { code: "U+1F575 U+FE0F U+200D U+2640 U+FE0F", emoji: "🕵️‍♀️", description: "woman detective" },
  { code: "U+1F482", emoji: "💂", description: "guard" },
  { code: "U+1F482 U+200D U+2642 U+FE0F", emoji: "💂‍♂️", description: "man guard" },
  { code: "U+1F482 U+200D U+2640 U+FE0F", emoji: "💂‍♀️", description: "woman guard" },
  { code: "U+1F977", emoji: "🥷", description: "ninja" },
  { code: "U+1F477", emoji: "👷", description: "construction worker" },
  { code: "U+1F477 U+200D U+2642 U+FE0F", emoji: "👷‍♂️", description: "man construction worker" },

  { code: "U+1F477 U+200D U+2640 U+FE0F", emoji: "👷‍♀️", description: "woman construction worker" },
  { code: "U+1FAC5", emoji: "🫅", description: "person with crown" },
  { code: "U+1F934", emoji: "🤴", description: "prince" },
  { code: "U+1F478", emoji: "👸", description: "princess" },
  { code: "U+1F473", emoji: "👳", description: "person wearing turban" },
  { code: "U+1F473 U+200D U+2642 U+FE0F", emoji: "👳‍♂️", description: "man wearing turban" },
  { code: "U+1F473 U+200D U+2640 U+FE0F", emoji: "👳‍♀️", description: "woman wearing turban" },
  { code: "U+1F472", emoji: "👲", description: "person with skullcap" },
  { code: "U+1F9D5", emoji: "🧕", description: "woman with headscarf" },
  { code: "U+1F935", emoji: "🤵", description: "person in tuxedo" },
  { code: "U+1F935 U+200D U+2642 U+FE0F", emoji: "🤵‍♂️", description: "man in tuxedo" },
  { code: "U+1F935 U+200D U+2640 U+FE0F", emoji: "🤵‍♀️", description: "woman in tuxedo" },
  { code: "U+1F470", emoji: "👰", description: "person with veil" },
  { code: "U+1F470 U+200D U+2642 U+FE0F", emoji: "👰‍♂️", description: "man with veil" },
  { code: "U+1F470 U+200D U+2640 U+FE0F", emoji: "👰‍♀️", description: "woman with veil" },
  { code: "U+1F930", emoji: "🤰", description: "pregnant woman" },
  { code: "U+1FAC3", emoji: "🫃", description: "pregnant man" },
  { code: "U+1FAC4", emoji: "🫄", description: "pregnant person" },
  { code: "U+1F931", emoji: "🤱", description: "breast-feeding" },
  { code: "U+1F469 U+200D U+1F37C", emoji: "👩‍🍼", description: "woman feeding baby" },

  { code: "U+1F468 U+200D U+1F37C", emoji: "👨‍🍼", description: "man feeding baby" },
  { code: "U+1F9D1 U+200D U+1F37C", emoji: "🧑‍🍼", description: "person feeding baby" },

  { code: "U+1F47C", emoji: "👼", description: "baby angel" },
  { code: "U+1F385", emoji: "🎅", description: "Santa Claus" },
  { code: "U+1F936", emoji: "🤶", description: "Mrs. Claus" },
  { code: "U+1F9D1 U+200D U+1F384", emoji: "🧑‍🎄", description: "mx claus" },
  { code: "U+1F9B8", emoji: "🦸", description: "superhero" },
  { code: "U+1F9B8 U+200D U+2642 U+FE0F", emoji: "🦸‍♂️", description: "man superhero" },
  { code: "U+1F9B8 U+200D U+2640 U+FE0F", emoji: "🦸‍♀️", description: "woman superhero" },
  { code: "U+1F9B9", emoji: "🦹", description: "supervillain" },
  { code: "U+1F9B9 U+200D U+2642 U+FE0F", emoji: "🦹‍♂️", description: "man supervillain" },
  { code: "U+1F9B9 U+200D U+2640 U+FE0F", emoji: "🦹‍♀️", description: "woman supervillain" },
  { code: "U+1F9D9", emoji: "🧙", description: "mage" },
  { code: "U+1F9D9 U+200D U+2642 U+FE0F", emoji: "🧙‍♂️", description: "man mage" },
  { code: "U+1F9D9 U+200D U+2640 U+FE0F", emoji: "🧙‍♀️", description: "woman mage" },
  { code: "U+1F9DA", emoji: "🧚", description: "fairy" },
  { code: "U+1F9DA U+200D U+2642 U+FE0F", emoji: "🧚‍♂️", description: "man fairy" },
  { code: "U+1F9DA U+200D U+2640 U+FE0F", emoji: "🧚‍♀️", description: "woman fairy" },
  { code: "U+1F9DB", emoji: "🧛", description: "vampire" },
  { code: "U+1F9DB U+200D U+2642 U+FE0F", emoji: "🧛‍♂️", description: "man vampire" },
  { code: "U+1F9DB U+200D U+2640 U+FE0F", emoji: "🧛‍♀️", description: "woman vampire" },
  { code: "U+1F9DC", emoji: "🧜", description: "merperson" },

  { code: "U+1F9DC U+200D U+2642 U+FE0F", emoji: "🧜‍♂️", description: "merman" },
  { code: "U+1F9DC U+200D U+2640 U+FE0F", emoji: "🧜‍♀️", description: "mermaid" },
  { code: "U+1F9DD", emoji: "🧝", description: "elf" },
  { code: "U+1F9DD U+200D U+2642 U+FE0F", emoji: "🧝‍♂️", description: "man elf" },
  { code: "U+1F9DD U+200D U+2640 U+FE0F", emoji: "🧝‍♀️", description: "woman elf" },
  { code: "U+1F9DE", emoji: "🧞", description: "genie" },
  { code: "U+1F9DE U+200D U+2642 U+FE0F", emoji: "🧞‍♂️", description: "man genie" },
  { code: "U+1F9DE U+200D U+2640 U+FE0F", emoji: "🧞‍♀️", description: "woman genie" },
  { code: "U+1F9DF", emoji: "🧟", description: "zombie" },
  { code: "U+1F9DF U+200D U+2642 U+FE0F", emoji: "🧟‍♂️", description: "man zombie" },
  { code: "U+1F9DF U+200D U+2640 U+FE0F", emoji: "🧟‍♀️", description: "woman zombie" },
  { code: "U+1F9CC", emoji: "🧌", description: "troll" },

  { code: "U+1F486", emoji: "💆", description: "person getting massage" },
  { code: "U+1F486 U+200D U+2642 U+FE0F", emoji: "💆‍♂️", description: "man getting massage" },
  { code: "U+1F486 U+200D U+2640 U+FE0F", emoji: "💆‍♀️", description: "woman getting massage" },
  { code: "U+1F487", emoji: "💇", description: "person getting haircut" },
  { code: "U+1F487 U+200D U+2642 U+FE0F", emoji: "💇‍♂️", description: "man getting haircut" },
  { code: "U+1F487 U+200D U+2640 U+FE0F", emoji: "💇‍♀️", description: "woman getting haircut" },
  { code: "U+1F6B6", emoji: "🚶", description: "person walking" },
  { code: "U+1F6B6 U+200D U+2642 U+FE0F", emoji: "🚶‍♂️", description: "man walking" },
  { code: "U+1F6B6 U+200D U+2640 U+FE0F", emoji: "🚶‍♀️", description: "woman walking" },
  { code: "U+1F6B6 U+200D U+27A1 U+FE0F", emoji: "🚶‍➡️", description: "⊛ person walking facing right" },
  { code: "U+1F6B6 U+200D U+2640 U+FE0F U+200D U+27A1 U+FE0F", emoji: "🚶‍♀️‍➡️", description: "⊛ woman walking facing right" },
  { code: "U+1F6B6 U+200D U+2642 U+FE0F U+200D U+27A1 U+FE0F", emoji: "🚶‍♂️‍➡️", description: "⊛ man walking facing right" },
  { code: "U+1F9CD", emoji: "🧍", description: "person standing" },
  { code: "U+1F9CD U+200D U+2642 U+FE0F", emoji: "🧍‍♂️", description: "man standing" },
  { code: "U+1F9CD U+200D U+2640 U+FE0F", emoji: "🧍‍♀️", description: "woman standing" },
  { code: "U+1F9CE", emoji: "🧎", description: "person kneeling" },
  { code: "U+1F9CE U+200D U+2642 U+FE0F", emoji: "🧎‍♂️", description: "man kneeling" },
  { code: "U+1F9CE U+200D U+2640 U+FE0F", emoji: "🧎‍♀️", description: "woman kneeling" },
  { code: "U+1F9CE U+200D U+27A1 U+FE0F", emoji: "🧎‍➡️", description: "⊛ person kneeling facing right" },
  { code: "U+1F9CE U+200D U+2640 U+FE0F U+200D U+27A1 U+FE0F", emoji: "🧎‍♀️‍➡️", description: "⊛ woman kneeling facing right" },

  { code: "U+1F9CE U+200D U+2642 U+FE0F U+200D U+27A1 U+FE0F", emoji: "🧎‍♂️‍➡️", description: "⊛ man kneeling facing right" },
  { code: "U+1F9D1 U+200D U+1F9AF", emoji: "🧑‍🦯", description: "person with white cane" },
  { code: "U+1F9D1 U+200D U+1F9AF U+200D U+27A1 U+FE0F", emoji: "🧑‍🦯‍➡️", description: "⊛ person with white cane facing right" },
  { code: "U+1F468 U+200D U+1F9AF", emoji: "👨‍🦯", description: "man with white cane" },
  { code: "U+1F468 U+200D U+1F9AF U+200D U+27A1 U+FE0F", emoji: "👨‍🦯‍➡️", description: "⊛ man with white cane facing right" },
  { code: "U+1F469 U+200D U+1F9AF", emoji: "👩‍🦯", description: "woman with white cane" },
  { code: "U+1F469 U+200D U+1F9AF U+200D U+27A1 U+FE0F", emoji: "👩‍🦯‍➡️", description: "⊛ woman with white cane facing right" },
  { code: "U+1F9D1 U+200D U+1F9BC", emoji: "🧑‍🦼", description: "person in motorized wheelchair" },
  { code: "U+1F9D1 U+200D U+1F9BC U+200D U+27A1 U+FE0F", emoji: "🧑‍🦼‍➡️", description: "⊛ person in motorized wheelchair facing right" },
  { code: "U+1F468 U+200D U+1F9BC", emoji: "👨‍🦼", description: "man in motorized wheelchair" },
  { code: "U+1F468 U+200D U+1F9BC U+200D U+27A1 U+FE0F", emoji: "👨‍🦼‍➡️", description: "⊛ man in motorized wheelchair facing right" },
  { code: "U+1F469 U+200D U+1F9BC", emoji: "👩‍🦼", description: "woman in motorized wheelchair" },
  { code: "U+1F469 U+200D U+1F9BC U+200D U+27A1 U+FE0F", emoji: "👩‍🦼‍➡️", description: "⊛ woman in motorized wheelchair facing right" },
  { code: "U+1F9D1 U+200D U+1F9BD", emoji: "🧑‍🦽", description: "person in manual wheelchair" },
  { code: "U+1F9D1 U+200D U+1F9BD U+200D U+27A1 U+FE0F", emoji: "🧑‍🦽‍➡️", description: "⊛ person in manual wheelchair facing right" },
  { code: "U+1F468 U+200D U+1F9BD", emoji: "👨‍🦽", description: "man in manual wheelchair" },
  { code: "U+1F468 U+200D U+1F9BD U+200D U+27A1 U+FE0F", emoji: "👨‍🦽‍➡️", description: "⊛ man in manual wheelchair facing right" },
  { code: "U+1F469 U+200D U+1F9BD", emoji: "👩‍🦽", description: "woman in manual wheelchair" },
  { code: "U+1F469 U+200D U+1F9BD U+200D U+27A1 U+FE0F", emoji: "👩‍🦽‍➡️", description: "⊛ woman in manual wheelchair facing right" },
  { code: "U+1F3C3", emoji: "🏃", description: "person running" },

  { code: "U+1F3C3 U+200D U+2642 U+FE0F", emoji: "🏃‍♂️", description: "man running" },
  { code: "U+1F3C3 U+200D U+2640 U+FE0F", emoji: "🏃‍♀️", description: "woman running" },
  { code: "U+1F3C3 U+200D U+27A1 U+FE0F", emoji: "🏃‍➡️", description: "⊛ person running facing right" },
  { code: "U+1F3C3 U+200D U+2640 U+FE0F U+200D U+27A1 U+FE0F", emoji: "🏃‍♀️‍➡️", description: "⊛ woman running facing right" },
  { code: "U+1F3C3 U+200D U+2642 U+FE0F U+200D U+27A1 U+FE0F", emoji: "🏃‍♂️‍➡️", description: "⊛ man running facing right" },
  { code: "U+1F483", emoji: "💃", description: "woman dancing" },
  { code: "U+1F57A", emoji: "🕺", description: "man dancing" },
  { code: "U+1F574", emoji: "🕴", description: "person in suit levitating" },
  { code: "U+1F46F", emoji: "👯", description: "people with bunny ears" },
  { code: "U+1F46F U+200D U+2642 U+FE0F", emoji: "👯‍♂️", description: "men with bunny ears" },
  { code: "U+1F46F U+200D U+2640 U+FE0F", emoji: "👯‍♀️", description: "women with bunny ears" },
  { code: "U+1F9D6", emoji: "🧖", description: "person in steamy room" },
  { code: "U+1F9D6 U+200D U+2642 U+FE0F", emoji: "🧖‍♂️", description: "man in steamy room" },
  { code: "U+1F9D6 U+200D U+2640 U+FE0F", emoji: "🧖‍♀️", description: "woman in steamy room" },
  { code: "U+1F9D7", emoji: "🧗", description: "person climbing" },
  { code: "U+1F9D7 U+200D U+2642 U+FE0F", emoji: "🧗‍♂️", description: "man climbing" },
  { code: "U+1F9D7 U+200D U+2640 U+FE0F", emoji: "🧗‍♀️", description: "woman climbing" },

  { code: "U+1F93A", emoji: "🤺", description: "person fencing" },
  { code: "U+1F3C7", emoji: "🏇", description: "horse racing" },
  { code: "U+26F7", emoji: "⛷", description: "skier" },
  { code: "U+1F3C2", emoji: "🏂", description: "snowboarder" },
  { code: "U+1F3CC", emoji: "🏌", description: "person golfing" },
  { code: "U+1F3CC U+FE0F U+200D U+2642 U+FE0F", emoji: "🏌️‍♂️", description: "man golfing" },
  { code: "U+1F3CC U+FE0F U+200D U+2640 U+FE0F", emoji: "🏌️‍♀️", description: "woman golfing" },
  { code: "U+1F3C4", emoji: "🏄", description: "person surfing" },
  { code: "U+1F3C4 U+200D U+2642 U+FE0F", emoji: "🏄‍♂️", description: "man surfing" },
  { code: "U+1F3C4 U+200D U+2640 U+FE0F", emoji: "🏄‍♀️", description: "woman surfing" },
  { code: "U+1F6A3", emoji: "🚣", description: "person rowing boat" },
  { code: "U+1F6A3 U+200D U+2642 U+FE0F", emoji: "🚣‍♂️", description: "man rowing boat" },
  { code: "U+1F6A3 U+200D U+2640 U+FE0F", emoji: "🚣‍♀️", description: "woman rowing boat" },
  { code: "U+1F3CA", emoji: "🏊", description: "person swimming" },
  { code: "U+1F3CA U+200D U+2642 U+FE0F", emoji: "🏊‍♂️", description: "man swimming" },
  { code: "U+1F3CA U+200D U+2640 U+FE0F", emoji: "🏊‍♀️", description: "woman swimming" },
  { code: "U+26F9", emoji: "⛹", description: "person bouncing ball" },
  { code: "U+26F9 U+FE0F U+200D U+2642 U+FE0F", emoji: "⛹️‍♂️", description: "man bouncing ball" },
  { code: "U+26F9 U+FE0F U+200D U+2640 U+FE0F", emoji: "⛹️‍♀️", description: "woman bouncing ball" },
  { code: "U+1F3CB", emoji: "🏋", description: "person lifting weights" },

  { code: "U+1F3CB U+FE0F U+200D U+2642 U+FE0F", emoji: "🏋️‍♂️", description: "man lifting weights" },
  { code: "U+1F3CB U+FE0F U+200D U+2640 U+FE0F", emoji: "🏋️‍♀️", description: "woman lifting weights" },
  { code: "U+1F6B4", emoji: "🚴", description: "person biking" },
  { code: "U+1F6B4 U+200D U+2642 U+FE0F", emoji: "🚴‍♂️", description: "man biking" },
  { code: "U+1F6B4 U+200D U+2640 U+FE0F", emoji: "🚴‍♀️", description: "woman biking" },
  { code: "U+1F6B5", emoji: "🚵", description: "person mountain biking" },
  { code: "U+1F6B5 U+200D U+2642 U+FE0F", emoji: "🚵‍♂️", description: "man mountain biking" },
  { code: "U+1F6B5 U+200D U+2640 U+FE0F", emoji: "🚵‍♀️", description: "woman mountain biking" },
  { code: "U+1F938", emoji: "🤸", description: "person cartwheeling" },
  { code: "U+1F938 U+200D U+2642 U+FE0F", emoji: "🤸‍♂️", description: "man cartwheeling" },
  { code: "U+1F938 U+200D U+2640 U+FE0F", emoji: "🤸‍♀️", description: "woman cartwheeling" },
  { code: "U+1F93C", emoji: "🤼", description: "people wrestling" },
  { code: "U+1F93C U+200D U+2642 U+FE0F", emoji: "🤼‍♂️", description: "men wrestling" },
  { code: "U+1F93C U+200D U+2640 U+FE0F", emoji: "🤼‍♀️", description: "women wrestling" },
  { code: "U+1F93D", emoji: "🤽", description: "person playing water polo" },
  { code: "U+1F93D U+200D U+2642 U+FE0F", emoji: "🤽‍♂️", description: "man playing water polo" },
  { code: "U+1F93D U+200D U+2640 U+FE0F", emoji: "🤽‍♀️", description: "woman playing water polo" },
  { code: "U+1F93E", emoji: "🤾", description: "person playing handball" },
  { code: "U+1F93E U+200D U+2642 U+FE0F", emoji: "🤾‍♂️", description: "man playing handball" },
  { code: "U+1F93E U+200D U+2640 U+FE0F", emoji: "🤾‍♀️", description: "woman playing handball" },

  { code: "U+1F939", emoji: "🤹", description: "person juggling" },
  { code: "U+1F939 U+200D U+2642 U+FE0F", emoji: "🤹‍♂️", description: "man juggling" },
  { code: "U+1F939 U+200D U+2640 U+FE0F", emoji: "🤹‍♀️", description: "woman juggling" },

  { code: "U+1F9D8", emoji: "🧘", description: "person in lotus position" },
  { code: "U+1F9D8 U+200D U+2642 U+FE0F", emoji: "🧘‍♂️", description: "man in lotus position" },
  { code: "U+1F9D8 U+200D U+2640 U+FE0F", emoji: "🧘‍♀️", description: "woman in lotus position" },
  { code: "U+1F6C0", emoji: "🛀", description: "person taking bath" },
  { code: "U+1F6CC", emoji: "🛌", description: "person in bed" },

  { code: "U+1F9D1 U+200D U+1F91D U+200D U+1F9D1", emoji: "🧑‍🤝‍🧑", description: "people holding hands" },
  { code: "U+1F46D", emoji: "👭", description: "women holding hands" },
  { code: "U+1F46B", emoji: "👫", description: "woman and man holding hands" },
  { code: "U+1F46C", emoji: "👬", description: "men holding hands" },
  { code: "U+1F48F", emoji: "💏", description: "kiss" },
  { code: "U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468", emoji: "👩‍❤️‍💋‍👨", description: "kiss: woman, man" },
  { code: "U+1F468 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468", emoji: "👨‍❤️‍💋‍👨", description: "kiss: man, man" },
  { code: "U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F469", emoji: "👩‍❤️‍💋‍👩", description: "kiss: woman, woman" },
  { code: "U+1F491", emoji: "💑", description: "couple with heart" },
  { code: "U+1F469 U+200D U+2764 U+FE0F U+200D U+1F468", emoji: "👩‍❤️‍👨", description: "couple with heart: woman, man" },
  { code: "U+1F468 U+200D U+2764 U+FE0F U+200D U+1F468", emoji: "👨‍❤️‍👨", description: "couple with heart: man, man" },
  { code: "U+1F469 U+200D U+2764 U+FE0F U+200D U+1F469", emoji: "👩‍❤️‍👩", description: "couple with heart: woman, woman" },
  { code: "U+1F468 U+200D U+1F469 U+200D U+1F466", emoji: "👨‍👩‍👦", description: "family: man, woman, boy" },
  { code: "U+1F468 U+200D U+1F469 U+200D U+1F467", emoji: "👨‍👩‍👧", description: "family: man, woman, girl" },
  { code: "U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466", emoji: "👨‍👩‍👧‍👦", description: "family: man, woman, girl, boy" },
  { code: "U+1F468 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466", emoji: "👨‍👩‍👦‍👦", description: "family: man, woman, boy, boy" },
  { code: "U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467", emoji: "👨‍👩‍👧‍👧", description: "family: man, woman, girl, girl" },
  { code: "U+1F468 U+200D U+1F468 U+200D U+1F466", emoji: "👨‍👨‍👦", description: "family: man, man, boy" },
  { code: "U+1F468 U+200D U+1F468 U+200D U+1F467", emoji: "👨‍👨‍👧", description: "family: man, man, girl" },
  { code: "U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F466", emoji: "👨‍👨‍👧‍👦", description: "family: man, man, girl, boy" },

  { code: "U+1F468 U+200D U+1F468 U+200D U+1F466 U+200D U+1F466", emoji: "👨‍👨‍👦‍👦", description: "family: man, man, boy, boy" },
  { code: "U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F467", emoji: "👨‍👨‍👧‍👧", description: "family: man, man, girl, girl" },
  { code: "U+1F469 U+200D U+1F469 U+200D U+1F466", emoji: "👩‍👩‍👦", description: "family: woman, woman, boy" },
  { code: "U+1F469 U+200D U+1F469 U+200D U+1F467", emoji: "👩‍👩‍👧", description: "family: woman, woman, girl" },
  { code: "U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466", emoji: "👩‍👩‍👧‍👦", description: "family: woman, woman, girl, boy" },
  { code: "U+1F469 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466", emoji: "👩‍👩‍👦‍👦", description: "family: woman, woman, boy, boy" },
  { code: "U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467", emoji: "👩‍👩‍👧‍👧", description: "family: woman, woman, girl, girl" },
  { code: "U+1F468 U+200D U+1F466", emoji: "👨‍👦", description: "family: man, boy" },
  { code: "U+1F468 U+200D U+1F466 U+200D U+1F466", emoji: "👨‍👦‍👦", description: "family: man, boy, boy" },
  { code: "U+1F468 U+200D U+1F467", emoji: "👨‍👧", description: "family: man, girl" },
  { code: "U+1F468 U+200D U+1F467 U+200D U+1F466", emoji: "👨‍👧‍👦", description: "family: man, girl, boy" },
  { code: "U+1F468 U+200D U+1F467 U+200D U+1F467", emoji: "👨‍👧‍👧", description: "family: man, girl, girl" },
  { code: "U+1F469 U+200D U+1F466", emoji: "👩‍👦", description: "family: woman, boy" },
  { code: "U+1F469 U+200D U+1F466 U+200D U+1F466", emoji: "👩‍👦‍👦", description: "family: woman, boy, boy" },
  { code: "U+1F469 U+200D U+1F467", emoji: "👩‍👧", description: "family: woman, girl" },
  { code: "U+1F469 U+200D U+1F467 U+200D U+1F466", emoji: "👩‍👧‍👦", description: "family: woman, girl, boy" },
  { code: "U+1F469 U+200D U+1F467 U+200D U+1F467", emoji: "👩‍👧‍👧", description: "family: woman, girl, girl" },

  { code: "U+1F5E3", emoji: "🗣", description: "speaking head" },
  { code: "U+1F464", emoji: "👤", description: "bust in silhouette" },
  { code: "U+1F465", emoji: "👥", description: "busts in silhouette" },
  { code: "U+1FAC2", emoji: "🫂", description: "people hugging" },
  { code: "U+1F46A", emoji: "👪", description: "family" },
  { code: "U+1F9D1 U+200D U+1F9D1 U+200D U+1F9D2", emoji: "🧑‍🧑‍🧒", description: "⊛ family: adult, adult, child" },
  { code: "U+1F9D1 U+200D U+1F9D1 U+200D U+1F9D2 U+200D U+1F9D2", emoji: "🧑‍🧑‍🧒‍🧒", description: "⊛ family: adult, adult, child, child" },
  { code: "U+1F9D1 U+200D U+1F9D2", emoji: "🧑‍🧒", description: "⊛ family: adult, child" },
  { code: "U+1F9D1 U+200D U+1F9D2 U+200D U+1F9D2", emoji: "🧑‍🧒‍🧒", description: "⊛ family: adult, child, child" },
  { code: "U+1F463", emoji: "👣", description: "footprints" },

  { code: "U+1F9B0", emoji: "🦰", description: "red hair" },
  { code: "U+1F9B1", emoji: "🦱", description: "curly hair" },
  { code: "U+1F9B3", emoji: "🦳", description: "white hair" },
  { code: "U+1F9B2", emoji: "🦲", description: "bald" },

  { code: "U+1F435", emoji: "🐵", description: "monkey face" },
  { code: "U+1F412", emoji: "🐒", description: "monkey" },
  { code: "U+1F98D", emoji: "🦍", description: "gorilla" },
  { code: "U+1F9A7", emoji: "🦧", description: "orangutan" },
  { code: "U+1F436", emoji: "🐶", description: "dog face" },
  { code: "U+1F415", emoji: "🐕", description: "dog" },
  { code: "U+1F9AE", emoji: "🦮", description: "guide dog" },
  { code: "U+1F415 U+200D U+1F9BA", emoji: "🐕‍🦺", description: "service dog" },
  { code: "U+1F429", emoji: "🐩", description: "poodle" },
  { code: "U+1F43A", emoji: "🐺", description: "wolf" },
  { code: "U+1F98A", emoji: "🦊", description: "fox" },
  { code: "U+1F99D", emoji: "🦝", description: "raccoon" },
  { code: "U+1F431", emoji: "🐱", description: "cat face" },
  { code: "U+1F408", emoji: "🐈", description: "cat" },
  { code: "U+1F408 U+200D U+2B1B", emoji: "🐈‍⬛", description: "black cat" },
  { code: "U+1F981", emoji: "🦁", description: "lion" },
  { code: "U+1F42F", emoji: "🐯", description: "tiger face" },
  { code: "U+1F405", emoji: "🐅", description: "tiger" },
  { code: "U+1F406", emoji: "🐆", description: "leopard" },
  { code: "U+1F434", emoji: "🐴", description: "horse face" },

  { code: "U+1FACE", emoji: "🫎", description: "moose" },
  { code: "U+1FACF", emoji: "🫏", description: "donkey" },
  { code: "U+1F40E", emoji: "🐎", description: "horse" },
  { code: "U+1F984", emoji: "🦄", description: "unicorn" },
  { code: "U+1F993", emoji: "🦓", description: "zebra" },
  { code: "U+1F98C", emoji: "🦌", description: "deer" },
  { code: "U+1F9AC", emoji: "🦬", description: "bison" },
  { code: "U+1F42E", emoji: "🐮", description: "cow face" },
  { code: "U+1F402", emoji: "🐂", description: "ox" },
  { code: "U+1F403", emoji: "🐃", description: "water buffalo" },
  { code: "U+1F404", emoji: "🐄", description: "cow" },
  { code: "U+1F437", emoji: "🐷", description: "pig face" },
  { code: "U+1F416", emoji: "🐖", description: "pig" },
  { code: "U+1F417", emoji: "🐗", description: "boar" },
  { code: "U+1F43D", emoji: "🐽", description: "pig nose" },
  { code: "U+1F40F", emoji: "🐏", description: "ram" },
  { code: "U+1F411", emoji: "🐑", description: "ewe" },
  { code: "U+1F410", emoji: "🐐", description: "goat" },
  { code: "U+1F42A", emoji: "🐪", description: "camel" },
  { code: "U+1F42B", emoji: "🐫", description: "two-hump camel" },

  { code: "U+1F999", emoji: "🦙", description: "llama" },
  { code: "U+1F992", emoji: "🦒", description: "giraffe" },
  { code: "U+1F418", emoji: "🐘", description: "elephant" },
  { code: "U+1F9A3", emoji: "🦣", description: "mammoth" },
  { code: "U+1F98F", emoji: "🦏", description: "rhinoceros" },
  { code: "U+1F99B", emoji: "🦛", description: "hippopotamus" },
  { code: "U+1F42D", emoji: "🐭", description: "mouse face" },
  { code: "U+1F401", emoji: "🐁", description: "mouse" },
  { code: "U+1F400", emoji: "🐀", description: "rat" },
  { code: "U+1F439", emoji: "🐹", description: "hamster" },
  { code: "U+1F430", emoji: "🐰", description: "rabbit face" },
  { code: "U+1F407", emoji: "🐇", description: "rabbit" },
  { code: "U+1F43F", emoji: "🐿", description: "chipmunk" },
  { code: "U+1F9AB", emoji: "🦫", description: "beaver" },
  { code: "U+1F994", emoji: "🦔", description: "hedgehog" },
  { code: "U+1F987", emoji: "🦇", description: "bat" },
  { code: "U+1F43B", emoji: "🐻", description: "bear" },
  { code: "U+1F43B U+200D U+2744 U+FE0F", emoji: "🐻‍❄️", description: "polar bear" },
  { code: "U+1F428", emoji: "🐨", description: "koala" },
  { code: "U+1F43C", emoji: "🐼", description: "panda" },

  { code: "U+1F9A5", emoji: "🦥", description: "sloth" },
  { code: "U+1F9A6", emoji: "🦦", description: "otter" },
  { code: "U+1F9A8", emoji: "🦨", description: "skunk" },
  { code: "U+1F998", emoji: "🦘", description: "kangaroo" },
  { code: "U+1F9A1", emoji: "🦡", description: "badger" },
  { code: "U+1F43E", emoji: "🐾", description: "paw prints" },

  { code: "U+1F983", emoji: "🦃", description: "turkey" },
  { code: "U+1F414", emoji: "🐔", description: "chicken" },
  { code: "U+1F413", emoji: "🐓", description: "rooster" },
  { code: "U+1F423", emoji: "🐣", description: "hatching chick" },
  { code: "U+1F424", emoji: "🐤", description: "baby chick" },
  { code: "U+1F425", emoji: "🐥", description: "front-facing baby chick" },
  { code: "U+1F426", emoji: "🐦", description: "bird" },
  { code: "U+1F427", emoji: "🐧", description: "penguin" },
  { code: "U+1F54A", emoji: "🕊", description: "dove" },
  { code: "U+1F985", emoji: "🦅", description: "eagle" },
  { code: "U+1F986", emoji: "🦆", description: "duck" },
  { code: "U+1F9A2", emoji: "🦢", description: "swan" },
  { code: "U+1F989", emoji: "🦉", description: "owl" },
  { code: "U+1F9A4", emoji: "🦤", description: "dodo" },
  { code: "U+1FAB6", emoji: "🪶", description: "feather" },
  { code: "U+1F9A9", emoji: "🦩", description: "flamingo" },
  { code: "U+1F99A", emoji: "🦚", description: "peacock" },
  { code: "U+1F99C", emoji: "🦜", description: "parrot" },
  { code: "U+1FABD", emoji: "🪽", description: "wing" },
  { code: "U+1F426 U+200D U+2B1B", emoji: "🐦‍⬛", description: "black bird" },

  { code: "U+1FABF", emoji: "🪿", description: "goose" },
  { code: "U+1F426 U+200D U+1F525", emoji: "🐦‍🔥", description: "⊛ phoenix" },

  { code: "U+1F438", emoji: "🐸", description: "frog" },

  { code: "U+1F40A", emoji: "🐊", description: "crocodile" },
  { code: "U+1F422", emoji: "🐢", description: "turtle" },
  { code: "U+1F98E", emoji: "🦎", description: "lizard" },
  { code: "U+1F40D", emoji: "🐍", description: "snake" },
  { code: "U+1F432", emoji: "🐲", description: "dragon face" },
  { code: "U+1F409", emoji: "🐉", description: "dragon" },
  { code: "U+1F995", emoji: "🦕", description: "sauropod" },
  { code: "U+1F996", emoji: "🦖", description: "T-Rex" },

  { code: "U+1F433", emoji: "🐳", description: "spouting whale" },
  { code: "U+1F40B", emoji: "🐋", description: "whale" },
  { code: "U+1F42C", emoji: "🐬", description: "dolphin" },
  { code: "U+1F9AD", emoji: "🦭", description: "seal" },
  { code: "U+1F41F", emoji: "🐟", description: "fish" },
  { code: "U+1F420", emoji: "🐠", description: "tropical fish" },
  { code: "U+1F421", emoji: "🐡", description: "blowfish" },
  { code: "U+1F988", emoji: "🦈", description: "shark" },
  { code: "U+1F419", emoji: "🐙", description: "octopus" },
  { code: "U+1F41A", emoji: "🐚", description: "spiral shell" },
  { code: "U+1FAB8", emoji: "🪸", description: "coral" },
  { code: "U+1FABC", emoji: "🪼", description: "jellyfish" },

  { code: "U+1F40C", emoji: "🐌", description: "snail" },
  { code: "U+1F98B", emoji: "🦋", description: "butterfly" },
  { code: "U+1F41B", emoji: "🐛", description: "bug" },
  { code: "U+1F41C", emoji: "🐜", description: "ant" },
  { code: "U+1F41D", emoji: "🐝", description: "honeybee" },
  { code: "U+1FAB2", emoji: "🪲", description: "beetle" },
  { code: "U+1F41E", emoji: "🐞", description: "lady beetle" },
  { code: "U+1F997", emoji: "🦗", description: "cricket" },
  { code: "U+1FAB3", emoji: "🪳", description: "cockroach" },
  { code: "U+1F577", emoji: "🕷", description: "spider" },
  { code: "U+1F578", emoji: "🕸", description: "spider web" },
  { code: "U+1F982", emoji: "🦂", description: "scorpion" },
  { code: "U+1F99F", emoji: "🦟", description: "mosquito" },
  { code: "U+1FAB0", emoji: "🪰", description: "fly" },
  { code: "U+1FAB1", emoji: "🪱", description: "worm" },
  { code: "U+1F9A0", emoji: "🦠", description: "microbe" },

  { code: "U+1F490", emoji: "💐", description: "bouquet" },
  { code: "U+1F338", emoji: "🌸", description: "cherry blossom" },
  { code: "U+1F4AE", emoji: "💮", description: "white flower" },
  { code: "U+1FAB7", emoji: "🪷", description: "lotus" },
  { code: "U+1F3F5", emoji: "🏵", description: "rosette" },
  { code: "U+1F339", emoji: "🌹", description: "rose" },
  { code: "U+1F940", emoji: "🥀", description: "wilted flower" },
  { code: "U+1F33A", emoji: "🌺", description: "hibiscus" },
  { code: "U+1F33B", emoji: "🌻", description: "sunflower" },
  { code: "U+1F33C", emoji: "🌼", description: "blossom" },
  { code: "U+1F337", emoji: "🌷", description: "tulip" },
  { code: "U+1FABB", emoji: "🪻", description: "hyacinth" },

  { code: "U+1F331", emoji: "🌱", description: "seedling" },
  { code: "U+1FAB4", emoji: "🪴", description: "potted plant" },
  { code: "U+1F332", emoji: "🌲", description: "evergreen tree" },
  { code: "U+1F333", emoji: "🌳", description: "deciduous tree" },
  { code: "U+1F334", emoji: "🌴", description: "palm tree" },
  { code: "U+1F335", emoji: "🌵", description: "cactus" },
  { code: "U+1F33E", emoji: "🌾", description: "sheaf of rice" },
  { code: "U+1F33F", emoji: "🌿", description: "herb" },
  { code: "U+2618", emoji: "☘", description: "shamrock" },
  { code: "U+1F340", emoji: "🍀", description: "four leaf clover" },
  { code: "U+1F341", emoji: "🍁", description: "maple leaf" },
  { code: "U+1F342", emoji: "🍂", description: "fallen leaf" },
  { code: "U+1F343", emoji: "🍃", description: "leaf fluttering in wind" },
  { code: "U+1FAB9", emoji: "🪹", description: "empty nest" },
  { code: "U+1FABA", emoji: "🪺", description: "nest with eggs" },
  { code: "U+1F344", emoji: "🍄", description: "mushroom" },

  { code: "U+1F347", emoji: "🍇", description: "grapes" },
  { code: "U+1F348", emoji: "🍈", description: "melon" },
  { code: "U+1F349", emoji: "🍉", description: "watermelon" },
  { code: "U+1F34A", emoji: "🍊", description: "tangerine" },
  { code: "U+1F34B", emoji: "🍋", description: "lemon" },
  { code: "U+1F34B U+200D U+1F7E9", emoji: "🍋‍🟩", description: "⊛ lime" },
  { code: "U+1F34C", emoji: "🍌", description: "banana" },
  { code: "U+1F34D", emoji: "🍍", description: "pineapple" },
  { code: "U+1F96D", emoji: "🥭", description: "mango" },
  { code: "U+1F34E", emoji: "🍎", description: "red apple" },
  { code: "U+1F34F", emoji: "🍏", description: "green apple" },
  { code: "U+1F350", emoji: "🍐", description: "pear" },
  { code: "U+1F351", emoji: "🍑", description: "peach" },
  { code: "U+1F352", emoji: "🍒", description: "cherries" },
  { code: "U+1F353", emoji: "🍓", description: "strawberry" },
  { code: "U+1FAD0", emoji: "🫐", description: "blueberries" },
  { code: "U+1F95D", emoji: "🥝", description: "kiwi fruit" },
  { code: "U+1F345", emoji: "🍅", description: "tomato" },
  { code: "U+1FAD2", emoji: "🫒", description: "olive" },
  { code: "U+1F965", emoji: "🥥", description: "coconut" },

  { code: "U+1F951", emoji: "🥑", description: "avocado" },
  { code: "U+1F346", emoji: "🍆", description: "eggplant" },
  { code: "U+1F954", emoji: "🥔", description: "potato" },
  { code: "U+1F955", emoji: "🥕", description: "carrot" },
  { code: "U+1F33D", emoji: "🌽", description: "ear of corn" },
  { code: "U+1F336", emoji: "🌶", description: "hot pepper" },
  { code: "U+1FAD1", emoji: "🫑", description: "bell pepper" },
  { code: "U+1F952", emoji: "🥒", description: "cucumber" },
  { code: "U+1F96C", emoji: "🥬", description: "leafy green" },
  { code: "U+1F966", emoji: "🥦", description: "broccoli" },
  { code: "U+1F9C4", emoji: "🧄", description: "garlic" },
  { code: "U+1F9C5", emoji: "🧅", description: "onion" },
  { code: "U+1F95C", emoji: "🥜", description: "peanuts" },
  { code: "U+1FAD8", emoji: "🫘", description: "beans" },
  { code: "U+1F330", emoji: "🌰", description: "chestnut" },
  { code: "U+1FADA", emoji: "🫚", description: "ginger root" },
  { code: "U+1FADB", emoji: "🫛", description: "pea pod" },
  { code: "U+1F344 U+200D U+1F7EB", emoji: "🍄‍🟫", description: "⊛ brown mushroom" },

  { code: "U+1F35E", emoji: "🍞", description: "bread" },
  { code: "U+1F950", emoji: "🥐", description: "croissant" },
  { code: "U+1F956", emoji: "🥖", description: "baguette bread" },
  { code: "U+1FAD3", emoji: "🫓", description: "flatbread" },
  { code: "U+1F968", emoji: "🥨", description: "pretzel" },
  { code: "U+1F96F", emoji: "🥯", description: "bagel" },
  { code: "U+1F95E", emoji: "🥞", description: "pancakes" },
  { code: "U+1F9C7", emoji: "🧇", description: "waffle" },
  { code: "U+1F9C0", emoji: "🧀", description: "cheese wedge" },
  { code: "U+1F356", emoji: "🍖", description: "meat on bone" },
  { code: "U+1F357", emoji: "🍗", description: "poultry leg" },
  { code: "U+1F969", emoji: "🥩", description: "cut of meat" },
  { code: "U+1F953", emoji: "🥓", description: "bacon" },
  { code: "U+1F354", emoji: "🍔", description: "hamburger" },
  { code: "U+1F35F", emoji: "🍟", description: "french fries" },
  { code: "U+1F355", emoji: "🍕", description: "pizza" },
  { code: "U+1F32D", emoji: "🌭", description: "hot dog" },
  { code: "U+1F96A", emoji: "🥪", description: "sandwich" },
  { code: "U+1F32E", emoji: "🌮", description: "taco" },
  { code: "U+1F32F", emoji: "🌯", description: "burrito" },

  { code: "U+1FAD4", emoji: "🫔", description: "tamale" },
  { code: "U+1F959", emoji: "🥙", description: "stuffed flatbread" },
  { code: "U+1F9C6", emoji: "🧆", description: "falafel" },
  { code: "U+1F95A", emoji: "🥚", description: "egg" },
  { code: "U+1F373", emoji: "🍳", description: "cooking" },
  { code: "U+1F958", emoji: "🥘", description: "shallow pan of food" },
  { code: "U+1F372", emoji: "🍲", description: "pot of food" },
  { code: "U+1FAD5", emoji: "🫕", description: "fondue" },
  { code: "U+1F963", emoji: "🥣", description: "bowl with spoon" },
  { code: "U+1F957", emoji: "🥗", description: "green salad" },
  { code: "U+1F37F", emoji: "🍿", description: "popcorn" },
  { code: "U+1F9C8", emoji: "🧈", description: "butter" },
  { code: "U+1F9C2", emoji: "🧂", description: "salt" },
  { code: "U+1F96B", emoji: "🥫", description: "canned food" },

  { code: "U+1F371", emoji: "🍱", description: "bento box" },
  { code: "U+1F358", emoji: "🍘", description: "rice cracker" },
  { code: "U+1F359", emoji: "🍙", description: "rice ball" },
  { code: "U+1F35A", emoji: "🍚", description: "cooked rice" },
  { code: "U+1F35B", emoji: "🍛", description: "curry rice" },
  { code: "U+1F35C", emoji: "🍜", description: "steaming bowl" },
  { code: "U+1F35D", emoji: "🍝", description: "spaghetti" },
  { code: "U+1F360", emoji: "🍠", description: "roasted sweet potato" },
  { code: "U+1F362", emoji: "🍢", description: "oden" },
  { code: "U+1F363", emoji: "🍣", description: "sushi" },
  { code: "U+1F364", emoji: "🍤", description: "fried shrimp" },
  { code: "U+1F365", emoji: "🍥", description: "fish cake with swirl" },
  { code: "U+1F96E", emoji: "🥮", description: "moon cake" },
  { code: "U+1F361", emoji: "🍡", description: "dango" },
  { code: "U+1F95F", emoji: "🥟", description: "dumpling" },
  { code: "U+1F960", emoji: "🥠", description: "fortune cookie" },
  { code: "U+1F961", emoji: "🥡", description: "takeout box" },

  { code: "U+1F980", emoji: "🦀", description: "crab" },
  { code: "U+1F99E", emoji: "🦞", description: "lobster" },
  { code: "U+1F990", emoji: "🦐", description: "shrimp" },
  { code: "U+1F991", emoji: "🦑", description: "squid" },
  { code: "U+1F9AA", emoji: "🦪", description: "oyster" },

  { code: "U+1F366", emoji: "🍦", description: "soft ice cream" },
  { code: "U+1F367", emoji: "🍧", description: "shaved ice" },
  { code: "U+1F368", emoji: "🍨", description: "ice cream" },
  { code: "U+1F369", emoji: "🍩", description: "doughnut" },
  { code: "U+1F36A", emoji: "🍪", description: "cookie" },
  { code: "U+1F382", emoji: "🎂", description: "birthday cake" },
  { code: "U+1F370", emoji: "🍰", description: "shortcake" },
  { code: "U+1F9C1", emoji: "🧁", description: "cupcake" },
  { code: "U+1F967", emoji: "🥧", description: "pie" },
  { code: "U+1F36B", emoji: "🍫", description: "chocolate bar" },
  { code: "U+1F36C", emoji: "🍬", description: "candy" },
  { code: "U+1F36D", emoji: "🍭", description: "lollipop" },
  { code: "U+1F36E", emoji: "🍮", description: "custard" },
  { code: "U+1F36F", emoji: "🍯", description: "honey pot" },

  { code: "U+1F37C", emoji: "🍼", description: "baby bottle" },
  { code: "U+1F95B", emoji: "🥛", description: "glass of milk" },
  { code: "U+2615", emoji: "☕", description: "hot beverage" },
  { code: "U+1FAD6", emoji: "🫖", description: "teapot" },
  { code: "U+1F375", emoji: "🍵", description: "teacup without handle" },
  { code: "U+1F376", emoji: "🍶", description: "sake" },
  { code: "U+1F37E", emoji: "🍾", description: "bottle with popping cork" },
  { code: "U+1F377", emoji: "🍷", description: "wine glass" },
  { code: "U+1F378", emoji: "🍸", description: "cocktail glass" },
  { code: "U+1F379", emoji: "🍹", description: "tropical drink" },
  { code: "U+1F37A", emoji: "🍺", description: "beer mug" },
  { code: "U+1F37B", emoji: "🍻", description: "clinking beer mugs" },
  { code: "U+1F942", emoji: "🥂", description: "clinking glasses" },
  { code: "U+1F943", emoji: "🥃", description: "tumbler glass" },
  { code: "U+1FAD7", emoji: "🫗", description: "pouring liquid" },
  { code: "U+1F964", emoji: "🥤", description: "cup with straw" },
  { code: "U+1F9CB", emoji: "🧋", description: "bubble tea" },
  { code: "U+1F9C3", emoji: "🧃", description: "beverage box" },
  { code: "U+1F9C9", emoji: "🧉", description: "mate" },
  { code: "U+1F9CA", emoji: "🧊", description: "ice" },

  { code: "U+1F962", emoji: "🥢", description: "chopsticks" },
  { code: "U+1F37D", emoji: "🍽", description: "fork and knife with plate" },
  { code: "U+1F374", emoji: "🍴", description: "fork and knife" },
  { code: "U+1F944", emoji: "🥄", description: "spoon" },
  { code: "U+1F52A", emoji: "🔪", description: "kitchen knife" },
  { code: "U+1FAD9", emoji: "🫙", description: "jar" },
  { code: "U+1F3FA", emoji: "🏺", description: "amphora" },

  { code: "U+1F30D", emoji: "🌍", description: "globe showing Europe-Africa" },
  { code: "U+1F30E", emoji: "🌎", description: "globe showing Americas" },
  { code: "U+1F30F", emoji: "🌏", description: "globe showing Asia-Australia" },
  { code: "U+1F310", emoji: "🌐", description: "globe with meridians" },
  { code: "U+1F5FA", emoji: "🗺", description: "world map" },
  { code: "U+1F5FE", emoji: "🗾", description: "map of Japan" },
  { code: "U+1F9ED", emoji: "🧭", description: "compass" },

  { code: "U+1F3D4", emoji: "🏔", description: "snow-capped mountain" },
  { code: "U+26F0", emoji: "⛰", description: "mountain" },
  { code: "U+1F30B", emoji: "🌋", description: "volcano" },
  { code: "U+1F5FB", emoji: "🗻", description: "mount fuji" },
  { code: "U+1F3D5", emoji: "🏕", description: "camping" },
  { code: "U+1F3D6", emoji: "🏖", description: "beach with umbrella" },
  { code: "U+1F3DC", emoji: "🏜", description: "desert" },
  { code: "U+1F3DD", emoji: "🏝", description: "desert island" },
  { code: "U+1F3DE", emoji: "🏞", description: "national park" },

  { code: "U+1F3DF", emoji: "🏟", description: "stadium" },
  { code: "U+1F3DB", emoji: "🏛", description: "classical building" },
  { code: "U+1F3D7", emoji: "🏗", description: "building construction" },
  { code: "U+1F9F1", emoji: "🧱", description: "brick" },
  { code: "U+1FAA8", emoji: "🪨", description: "rock" },
  { code: "U+1FAB5", emoji: "🪵", description: "wood" },
  { code: "U+1F6D6", emoji: "🛖", description: "hut" },
  { code: "U+1F3D8", emoji: "🏘", description: "houses" },
  { code: "U+1F3DA", emoji: "🏚", description: "derelict house" },
  { code: "U+1F3E0", emoji: "🏠", description: "house" },
  { code: "U+1F3E1", emoji: "🏡", description: "house with garden" },
  { code: "U+1F3E2", emoji: "🏢", description: "office building" },
  { code: "U+1F3E3", emoji: "🏣", description: "Japanese post office" },
  { code: "U+1F3E4", emoji: "🏤", description: "post office" },
  { code: "U+1F3E5", emoji: "🏥", description: "hospital" },
  { code: "U+1F3E6", emoji: "🏦", description: "bank" },
  { code: "U+1F3E8", emoji: "🏨", description: "hotel" },
  { code: "U+1F3E9", emoji: "🏩", description: "love hotel" },
  { code: "U+1F3EA", emoji: "🏪", description: "convenience store" },
  { code: "U+1F3EB", emoji: "🏫", description: "school" },

  { code: "U+1F3EC", emoji: "🏬", description: "department store" },
  { code: "U+1F3ED", emoji: "🏭", description: "factory" },
  { code: "U+1F3EF", emoji: "🏯", description: "Japanese castle" },
  { code: "U+1F3F0", emoji: "🏰", description: "castle" },
  { code: "U+1F492", emoji: "💒", description: "wedding" },
  { code: "U+1F5FC", emoji: "🗼", description: "Tokyo tower" },
  { code: "U+1F5FD", emoji: "🗽", description: "Statue of Liberty" },

  { code: "U+26EA", emoji: "⛪", description: "church" },
  { code: "U+1F54C", emoji: "🕌", description: "mosque" },
  { code: "U+1F6D5", emoji: "🛕", description: "hindu temple" },
  { code: "U+1F54D", emoji: "🕍", description: "synagogue" },
  { code: "U+26E9", emoji: "⛩", description: "shinto shrine" },
  { code: "U+1F54B", emoji: "🕋", description: "kaaba" },

  { code: "U+26F2", emoji: "⛲", description: "fountain" },
  { code: "U+26FA", emoji: "⛺", description: "tent" },
  { code: "U+1F301", emoji: "🌁", description: "foggy" },
  { code: "U+1F303", emoji: "🌃", description: "night with stars" },
  { code: "U+1F3D9", emoji: "🏙", description: "cityscape" },
  { code: "U+1F304", emoji: "🌄", description: "sunrise over mountains" },
  { code: "U+1F305", emoji: "🌅", description: "sunrise" },
  { code: "U+1F306", emoji: "🌆", description: "cityscape at dusk" },
  { code: "U+1F307", emoji: "🌇", description: "sunset" },
  { code: "U+1F309", emoji: "🌉", description: "bridge at night" },
  { code: "U+2668", emoji: "♨", description: "hot springs" },
  { code: "U+1F3A0", emoji: "🎠", description: "carousel horse" },
  { code: "U+1F6DD", emoji: "🛝", description: "playground slide" },
  { code: "U+1F3A1", emoji: "🎡", description: "ferris wheel" },
  { code: "U+1F3A2", emoji: "🎢", description: "roller coaster" },
  { code: "U+1F488", emoji: "💈", description: "barber pole" },
  { code: "U+1F3AA", emoji: "🎪", description: "circus tent" },

  { code: "U+1F682", emoji: "🚂", description: "locomotive" },
  { code: "U+1F683", emoji: "🚃", description: "railway car" },
  { code: "U+1F684", emoji: "🚄", description: "high-speed train" },
  { code: "U+1F685", emoji: "🚅", description: "bullet train" },
  { code: "U+1F686", emoji: "🚆", description: "train" },
  { code: "U+1F687", emoji: "🚇", description: "metro" },
  { code: "U+1F688", emoji: "🚈", description: "light rail" },
  { code: "U+1F689", emoji: "🚉", description: "station" },
  { code: "U+1F68A", emoji: "🚊", description: "tram" },
  { code: "U+1F69D", emoji: "🚝", description: "monorail" },
  { code: "U+1F69E", emoji: "🚞", description: "mountain railway" },
  { code: "U+1F68B", emoji: "🚋", description: "tram car" },
  { code: "U+1F68C", emoji: "🚌", description: "bus" },
  { code: "U+1F68D", emoji: "🚍", description: "oncoming bus" },
  { code: "U+1F68E", emoji: "🚎", description: "trolleybus" },
  { code: "U+1F690", emoji: "🚐", description: "minibus" },
  { code: "U+1F691", emoji: "🚑", description: "ambulance" },
  { code: "U+1F692", emoji: "🚒", description: "fire engine" },
  { code: "U+1F693", emoji: "🚓", description: "police car" },
  { code: "U+1F694", emoji: "🚔", description: "oncoming police car" },

  { code: "U+1F695", emoji: "🚕", description: "taxi" },
  { code: "U+1F696", emoji: "🚖", description: "oncoming taxi" },
  { code: "U+1F697", emoji: "🚗", description: "automobile" },
  { code: "U+1F698", emoji: "🚘", description: "oncoming automobile" },
  { code: "U+1F699", emoji: "🚙", description: "sport utility vehicle" },
  { code: "U+1F6FB", emoji: "🛻", description: "pickup truck" },
  { code: "U+1F69A", emoji: "🚚", description: "delivery truck" },
  { code: "U+1F69B", emoji: "🚛", description: "articulated lorry" },
  { code: "U+1F69C", emoji: "🚜", description: "tractor" },
  { code: "U+1F3CE", emoji: "🏎", description: "racing car" },
  { code: "U+1F3CD", emoji: "🏍", description: "motorcycle" },
  { code: "U+1F6F5", emoji: "🛵", description: "motor scooter" },
  { code: "U+1F9BD", emoji: "🦽", description: "manual wheelchair" },
  { code: "U+1F9BC", emoji: "🦼", description: "motorized wheelchair" },
  { code: "U+1F6FA", emoji: "🛺", description: "auto rickshaw" },
  { code: "U+1F6B2", emoji: "🚲", description: "bicycle" },
  { code: "U+1F6F4", emoji: "🛴", description: "kick scooter" },
  { code: "U+1F6F9", emoji: "🛹", description: "skateboard" },
  { code: "U+1F6FC", emoji: "🛼", description: "roller skate" },
  { code: "U+1F68F", emoji: "🚏", description: "bus stop" },

  { code: "U+1F6E3", emoji: "🛣", description: "motorway" },
  { code: "U+1F6E4", emoji: "🛤", description: "railway track" },
  { code: "U+1F6E2", emoji: "🛢", description: "oil drum" },
  { code: "U+26FD", emoji: "⛽", description: "fuel pump" },
  { code: "U+1F6DE", emoji: "🛞", description: "wheel" },
  { code: "U+1F6A8", emoji: "🚨", description: "police car light" },
  { code: "U+1F6A5", emoji: "🚥", description: "horizontal traffic light" },
  { code: "U+1F6A6", emoji: "🚦", description: "vertical traffic light" },
  { code: "U+1F6D1", emoji: "🛑", description: "stop sign" },
  { code: "U+1F6A7", emoji: "🚧", description: "construction" },

  { code: "U+2693", emoji: "⚓", description: "anchor" },
  { code: "U+1F6DF", emoji: "🛟", description: "ring buoy" },
  { code: "U+26F5", emoji: "⛵", description: "sailboat" },
  { code: "U+1F6F6", emoji: "🛶", description: "canoe" },
  { code: "U+1F6A4", emoji: "🚤", description: "speedboat" },
  { code: "U+1F6F3", emoji: "🛳", description: "passenger ship" },
  { code: "U+26F4", emoji: "⛴", description: "ferry" },
  { code: "U+1F6E5", emoji: "🛥", description: "motor boat" },
  { code: "U+1F6A2", emoji: "🚢", description: "ship" },

  { code: "U+2708", emoji: "✈", description: "airplane" },
  { code: "U+1F6E9", emoji: "🛩", description: "small airplane" },
  { code: "U+1F6EB", emoji: "🛫", description: "airplane departure" },
  { code: "U+1F6EC", emoji: "🛬", description: "airplane arrival" },
  { code: "U+1FA82", emoji: "🪂", description: "parachute" },
  { code: "U+1F4BA", emoji: "💺", description: "seat" },
  { code: "U+1F681", emoji: "🚁", description: "helicopter" },
  { code: "U+1F69F", emoji: "🚟", description: "suspension railway" },
  { code: "U+1F6A0", emoji: "🚠", description: "mountain cableway" },
  { code: "U+1F6A1", emoji: "🚡", description: "aerial tramway" },
  { code: "U+1F6F0", emoji: "🛰", description: "satellite" },
  { code: "U+1F680", emoji: "🚀", description: "rocket" },
  { code: "U+1F6F8", emoji: "🛸", description: "flying saucer" },

  { code: "U+1F6CE", emoji: "🛎", description: "bellhop bell" },
  { code: "U+1F9F3", emoji: "🧳", description: "luggage" },

  { code: "U+231B", emoji: "⌛", description: "hourglass done" },
  { code: "U+23F3", emoji: "⏳", description: "hourglass not done" },
  { code: "U+231A", emoji: "⌚", description: "watch" },
  { code: "U+23F0", emoji: "⏰", description: "alarm clock" },
  { code: "U+23F1", emoji: "⏱", description: "stopwatch" },
  { code: "U+23F2", emoji: "⏲", description: "timer clock" },
  { code: "U+1F570", emoji: "🕰", description: "mantelpiece clock" },
  { code: "U+1F55B", emoji: "🕛", description: "twelve o’clock" },
  { code: "U+1F567", emoji: "🕧", description: "twelve-thirty" },
  { code: "U+1F550", emoji: "🕐", description: "one o’clock" },
  { code: "U+1F55C", emoji: "🕜", description: "one-thirty" },
  { code: "U+1F551", emoji: "🕑", description: "two o’clock" },
  { code: "U+1F55D", emoji: "🕝", description: "two-thirty" },
  { code: "U+1F552", emoji: "🕒", description: "three o’clock" },
  { code: "U+1F55E", emoji: "🕞", description: "three-thirty" },
  { code: "U+1F553", emoji: "🕓", description: "four o’clock" },
  { code: "U+1F55F", emoji: "🕟", description: "four-thirty" },
  { code: "U+1F554", emoji: "🕔", description: "five o’clock" },
  { code: "U+1F560", emoji: "🕠", description: "five-thirty" },
  { code: "U+1F555", emoji: "🕕", description: "six o’clock" },

  { code: "U+1F561", emoji: "🕡", description: "six-thirty" },
  { code: "U+1F556", emoji: "🕖", description: "seven o’clock" },
  { code: "U+1F562", emoji: "🕢", description: "seven-thirty" },
  { code: "U+1F557", emoji: "🕗", description: "eight o’clock" },
  { code: "U+1F563", emoji: "🕣", description: "eight-thirty" },
  { code: "U+1F558", emoji: "🕘", description: "nine o’clock" },
  { code: "U+1F564", emoji: "🕤", description: "nine-thirty" },
  { code: "U+1F559", emoji: "🕙", description: "ten o’clock" },
  { code: "U+1F565", emoji: "🕥", description: "ten-thirty" },
  { code: "U+1F55A", emoji: "🕚", description: "eleven o’clock" },
  { code: "U+1F566", emoji: "🕦", description: "eleven-thirty" },

  { code: "U+1F311", emoji: "🌑", description: "new moon" },
  { code: "U+1F312", emoji: "🌒", description: "waxing crescent moon" },
  { code: "U+1F313", emoji: "🌓", description: "first quarter moon" },
  { code: "U+1F314", emoji: "🌔", description: "waxing gibbous moon" },
  { code: "U+1F315", emoji: "🌕", description: "full moon" },
  { code: "U+1F316", emoji: "🌖", description: "waning gibbous moon" },
  { code: "U+1F317", emoji: "🌗", description: "last quarter moon" },
  { code: "U+1F318", emoji: "🌘", description: "waning crescent moon" },
  { code: "U+1F319", emoji: "🌙", description: "crescent moon" },
  { code: "U+1F31A", emoji: "🌚", description: "new moon face" },
  { code: "U+1F31B", emoji: "🌛", description: "first quarter moon face" },
  { code: "U+1F31C", emoji: "🌜", description: "last quarter moon face" },
  { code: "U+1F321", emoji: "🌡", description: "thermometer" },
  { code: "U+2600", emoji: "☀", description: "sun" },
  { code: "U+1F31D", emoji: "🌝", description: "full moon face" },
  { code: "U+1F31E", emoji: "🌞", description: "sun with face" },
  { code: "U+1FA90", emoji: "🪐", description: "ringed planet" },
  { code: "U+2B50", emoji: "⭐", description: "star" },
  { code: "U+1F31F", emoji: "🌟", description: "glowing star" },
  { code: "U+1F320", emoji: "🌠", description: "shooting star" },

  { code: "U+1F30C", emoji: "🌌", description: "milky way" },
  { code: "U+2601", emoji: "☁", description: "cloud" },
  { code: "U+26C5", emoji: "⛅", description: "sun behind cloud" },
  { code: "U+26C8", emoji: "⛈", description: "cloud with lightning and rain" },
  { code: "U+1F324", emoji: "🌤", description: "sun behind small cloud" },
  { code: "U+1F325", emoji: "🌥", description: "sun behind large cloud" },
  { code: "U+1F326", emoji: "🌦", description: "sun behind rain cloud" },
  { code: "U+1F327", emoji: "🌧", description: "cloud with rain" },
  { code: "U+1F328", emoji: "🌨", description: "cloud with snow" },
  { code: "U+1F329", emoji: "🌩", description: "cloud with lightning" },
  { code: "U+1F32A", emoji: "🌪", description: "tornado" },
  { code: "U+1F32B", emoji: "🌫", description: "fog" },
  { code: "U+1F32C", emoji: "🌬", description: "wind face" },
  { code: "U+1F300", emoji: "🌀", description: "cyclone" },
  { code: "U+1F308", emoji: "🌈", description: "rainbow" },
  { code: "U+1F302", emoji: "🌂", description: "closed umbrella" },
  { code: "U+2602", emoji: "☂", description: "umbrella" },
  { code: "U+2614", emoji: "☔", description: "umbrella with rain drops" },
  { code: "U+26F1", emoji: "⛱", description: "umbrella on ground" },
  { code: "U+26A1", emoji: "⚡", description: "high voltage" },

  { code: "U+2744", emoji: "❄", description: "snowflake" },
  { code: "U+2603", emoji: "☃", description: "snowman" },
  { code: "U+26C4", emoji: "⛄", description: "snowman without snow" },
  { code: "U+2604", emoji: "☄", description: "comet" },
  { code: "U+1F525", emoji: "🔥", description: "fire" },
  { code: "U+1F4A7", emoji: "💧", description: "droplet" },
  { code: "U+1F30A", emoji: "🌊", description: "water wave" },

  { code: "U+1F383", emoji: "🎃", description: "jack-o-lantern" },
  { code: "U+1F384", emoji: "🎄", description: "Christmas tree" },
  { code: "U+1F386", emoji: "🎆", description: "fireworks" },
  { code: "U+1F387", emoji: "🎇", description: "sparkler" },
  { code: "U+1F9E8", emoji: "🧨", description: "firecracker" },
  { code: "U+2728", emoji: "✨", description: "sparkles" },
  { code: "U+1F388", emoji: "🎈", description: "balloon" },
  { code: "U+1F389", emoji: "🎉", description: "party popper" },
  { code: "U+1F38A", emoji: "🎊", description: "confetti ball" },
  { code: "U+1F38B", emoji: "🎋", description: "tanabata tree" },
  { code: "U+1F38D", emoji: "🎍", description: "pine decoration" },
  { code: "U+1F38E", emoji: "🎎", description: "Japanese dolls" },
  { code: "U+1F38F", emoji: "🎏", description: "carp streamer" },
  { code: "U+1F390", emoji: "🎐", description: "wind chime" },
  { code: "U+1F391", emoji: "🎑", description: "moon viewing ceremony" },
  { code: "U+1F9E7", emoji: "🧧", description: "red envelope" },
  { code: "U+1F380", emoji: "🎀", description: "ribbon" },
  { code: "U+1F381", emoji: "🎁", description: "wrapped gift" },
  { code: "U+1F397", emoji: "🎗", description: "reminder ribbon" },
  { code: "U+1F39F", emoji: "🎟", description: "admission tickets" },

  { code: "U+1F3AB", emoji: "🎫", description: "ticket" },

  { code: "U+1F396", emoji: "🎖", description: "military medal" },
  { code: "U+1F3C6", emoji: "🏆", description: "trophy" },
  { code: "U+1F3C5", emoji: "🏅", description: "sports medal" },
  { code: "U+1F947", emoji: "🥇", description: "1st place medal" },
  { code: "U+1F948", emoji: "🥈", description: "2nd place medal" },
  { code: "U+1F949", emoji: "🥉", description: "3rd place medal" },

  { code: "U+26BD", emoji: "⚽", description: "soccer ball" },
  { code: "U+26BE", emoji: "⚾", description: "baseball" },
  { code: "U+1F94E", emoji: "🥎", description: "softball" },
  { code: "U+1F3C0", emoji: "🏀", description: "basketball" },
  { code: "U+1F3D0", emoji: "🏐", description: "volleyball" },
  { code: "U+1F3C8", emoji: "🏈", description: "american football" },
  { code: "U+1F3C9", emoji: "🏉", description: "rugby football" },
  { code: "U+1F3BE", emoji: "🎾", description: "tennis" },
  { code: "U+1F94F", emoji: "🥏", description: "flying disc" },
  { code: "U+1F3B3", emoji: "🎳", description: "bowling" },
  { code: "U+1F3CF", emoji: "🏏", description: "cricket game" },
  { code: "U+1F3D1", emoji: "🏑", description: "field hockey" },
  { code: "U+1F3D2", emoji: "🏒", description: "ice hockey" },
  { code: "U+1F94D", emoji: "🥍", description: "lacrosse" },
  { code: "U+1F3D3", emoji: "🏓", description: "ping pong" },
  { code: "U+1F3F8", emoji: "🏸", description: "badminton" },
  { code: "U+1F94A", emoji: "🥊", description: "boxing glove" },
  { code: "U+1F94B", emoji: "🥋", description: "martial arts uniform" },
  { code: "U+1F945", emoji: "🥅", description: "goal net" },
  { code: "U+26F3", emoji: "⛳", description: "flag in hole" },

  { code: "U+26F8", emoji: "⛸", description: "ice skate" },
  { code: "U+1F3A3", emoji: "🎣", description: "fishing pole" },
  { code: "U+1F93F", emoji: "🤿", description: "diving mask" },
  { code: "U+1F3BD", emoji: "🎽", description: "running shirt" },
  { code: "U+1F3BF", emoji: "🎿", description: "skis" },
  { code: "U+1F6F7", emoji: "🛷", description: "sled" },
  { code: "U+1F94C", emoji: "🥌", description: "curling stone" },

  { code: "U+1F3AF", emoji: "🎯", description: "bullseye" },
  { code: "U+1FA80", emoji: "🪀", description: "yo-yo" },
  { code: "U+1FA81", emoji: "🪁", description: "kite" },
  { code: "U+1F52B", emoji: "🔫", description: "water pistol" },
  { code: "U+1F3B1", emoji: "🎱", description: "pool 8 ball" },
  { code: "U+1F52E", emoji: "🔮", description: "crystal ball" },
  { code: "U+1FA84", emoji: "🪄", description: "magic wand" },
  { code: "U+1F3AE", emoji: "🎮", description: "video game" },
  { code: "U+1F579", emoji: "🕹", description: "joystick" },
  { code: "U+1F3B0", emoji: "🎰", description: "slot machine" },
  { code: "U+1F3B2", emoji: "🎲", description: "game die" },
  { code: "U+1F9E9", emoji: "🧩", description: "puzzle piece" },
  { code: "U+1F9F8", emoji: "🧸", description: "teddy bear" },
  { code: "U+1FA85", emoji: "🪅", description: "piñata" },
  { code: "U+1FAA9", emoji: "🪩", description: "mirror ball" },
  { code: "U+1FA86", emoji: "🪆", description: "nesting dolls" },
  { code: "U+2660", emoji: "♠", description: "spade suit" },
  { code: "U+2665", emoji: "♥", description: "heart suit" },
  { code: "U+2666", emoji: "♦", description: "diamond suit" },
  { code: "U+2663", emoji: "♣", description: "club suit" },

  { code: "U+265F", emoji: "♟", description: "chess pawn" },
  { code: "U+1F0CF", emoji: "🃏", description: "joker" },
  { code: "U+1F004", emoji: "🀄", description: "mahjong red dragon" },
  { code: "U+1F3B4", emoji: "🎴", description: "flower playing cards" },

  { code: "U+1F3AD", emoji: "🎭", description: "performing arts" },
  { code: "U+1F5BC", emoji: "🖼", description: "framed picture" },
  { code: "U+1F3A8", emoji: "🎨", description: "artist palette" },
  { code: "U+1F9F5", emoji: "🧵", description: "thread" },
  { code: "U+1FAA1", emoji: "🪡", description: "sewing needle" },
  { code: "U+1F9F6", emoji: "🧶", description: "yarn" },
  { code: "U+1FAA2", emoji: "🪢", description: "knot" },

  { code: "U+1F453", emoji: "👓", description: "glasses" },
  { code: "U+1F576", emoji: "🕶", description: "sunglasses" },
  { code: "U+1F97D", emoji: "🥽", description: "goggles" },
  { code: "U+1F97C", emoji: "🥼", description: "lab coat" },
  { code: "U+1F9BA", emoji: "🦺", description: "safety vest" },
  { code: "U+1F454", emoji: "👔", description: "necktie" },
  { code: "U+1F455", emoji: "👕", description: "t-shirt" },
  { code: "U+1F456", emoji: "👖", description: "jeans" },
  { code: "U+1F9E3", emoji: "🧣", description: "scarf" },
  { code: "U+1F9E4", emoji: "🧤", description: "gloves" },
  { code: "U+1F9E5", emoji: "🧥", description: "coat" },
  { code: "U+1F9E6", emoji: "🧦", description: "socks" },
  { code: "U+1F457", emoji: "👗", description: "dress" },
  { code: "U+1F458", emoji: "👘", description: "kimono" },
  { code: "U+1F97B", emoji: "🥻", description: "sari" },
  { code: "U+1FA71", emoji: "🩱", description: "one-piece swimsuit" },
  { code: "U+1FA72", emoji: "🩲", description: "briefs" },
  { code: "U+1FA73", emoji: "🩳", description: "shorts" },
  { code: "U+1F459", emoji: "👙", description: "bikini" },
  { code: "U+1F45A", emoji: "👚", description: "woman’s clothes" },

  { code: "U+1FAAD", emoji: "🪭", description: "folding hand fan" },
  { code: "U+1F45B", emoji: "👛", description: "purse" },
  { code: "U+1F45C", emoji: "👜", description: "handbag" },
  { code: "U+1F45D", emoji: "👝", description: "clutch bag" },
  { code: "U+1F6CD", emoji: "🛍", description: "shopping bags" },
  { code: "U+1F392", emoji: "🎒", description: "backpack" },
  { code: "U+1FA74", emoji: "🩴", description: "thong sandal" },
  { code: "U+1F45E", emoji: "👞", description: "man’s shoe" },
  { code: "U+1F45F", emoji: "👟", description: "running shoe" },
  { code: "U+1F97E", emoji: "🥾", description: "hiking boot" },
  { code: "U+1F97F", emoji: "🥿", description: "flat shoe" },
  { code: "U+1F460", emoji: "👠", description: "high-heeled shoe" },
  { code: "U+1F461", emoji: "👡", description: "woman’s sandal" },
  { code: "U+1FA70", emoji: "🩰", description: "ballet shoes" },
  { code: "U+1F462", emoji: "👢", description: "woman’s boot" },
  { code: "U+1FAAE", emoji: "🪮", description: "hair pick" },
  { code: "U+1F451", emoji: "👑", description: "crown" },
  { code: "U+1F452", emoji: "👒", description: "woman’s hat" },
  { code: "U+1F3A9", emoji: "🎩", description: "top hat" },
  { code: "U+1F393", emoji: "🎓", description: "graduation cap" },

  { code: "U+1F9E2", emoji: "🧢", description: "billed cap" },
  { code: "U+1FA96", emoji: "🪖", description: "military helmet" },
  { code: "U+26D1", emoji: "⛑", description: "rescue worker’s helmet" },
  { code: "U+1F4FF", emoji: "📿", description: "prayer beads" },
  { code: "U+1F484", emoji: "💄", description: "lipstick" },
  { code: "U+1F48D", emoji: "💍", description: "ring" },
  { code: "U+1F48E", emoji: "💎", description: "gem stone" },

  { code: "U+1F507", emoji: "🔇", description: "muted speaker" },
  { code: "U+1F508", emoji: "🔈", description: "speaker low volume" },
  { code: "U+1F509", emoji: "🔉", description: "speaker medium volume" },
  { code: "U+1F50A", emoji: "🔊", description: "speaker high volume" },
  { code: "U+1F4E2", emoji: "📢", description: "loudspeaker" },
  { code: "U+1F4E3", emoji: "📣", description: "megaphone" },
  { code: "U+1F4EF", emoji: "📯", description: "postal horn" },
  { code: "U+1F514", emoji: "🔔", description: "bell" },
  { code: "U+1F515", emoji: "🔕", description: "bell with slash" },

  { code: "U+1F3BC", emoji: "🎼", description: "musical score" },
  { code: "U+1F3B5", emoji: "🎵", description: "musical note" },
  { code: "U+1F3B6", emoji: "🎶", description: "musical notes" },
  { code: "U+1F399", emoji: "🎙", description: "studio microphone" },
  { code: "U+1F39A", emoji: "🎚", description: "level slider" },
  { code: "U+1F39B", emoji: "🎛", description: "control knobs" },
  { code: "U+1F3A4", emoji: "🎤", description: "microphone" },
  { code: "U+1F3A7", emoji: "🎧", description: "headphone" },
  { code: "U+1F4FB", emoji: "📻", description: "radio" },

  { code: "U+1F3B7", emoji: "🎷", description: "saxophone" },
  { code: "U+1FA97", emoji: "🪗", description: "accordion" },
  { code: "U+1F3B8", emoji: "🎸", description: "guitar" },
  { code: "U+1F3B9", emoji: "🎹", description: "musical keyboard" },
  { code: "U+1F3BA", emoji: "🎺", description: "trumpet" },
  { code: "U+1F3BB", emoji: "🎻", description: "violin" },
  { code: "U+1FA95", emoji: "🪕", description: "banjo" },
  { code: "U+1F941", emoji: "🥁", description: "drum" },
  { code: "U+1FA98", emoji: "🪘", description: "long drum" },
  { code: "U+1FA87", emoji: "🪇", description: "maracas" },
  { code: "U+1FA88", emoji: "🪈", description: "flute" },

  { code: "U+1F4F1", emoji: "📱", description: "mobile phone" },
  { code: "U+1F4F2", emoji: "📲", description: "mobile phone with arrow" },
  { code: "U+260E", emoji: "☎", description: "telephone" },
  { code: "U+1F4DE", emoji: "📞", description: "telephone receiver" },
  { code: "U+1F4DF", emoji: "📟", description: "pager" },
  { code: "U+1F4E0", emoji: "📠", description: "fax machine" },

  { code: "U+1F50B", emoji: "🔋", description: "battery" },
  { code: "U+1FAAB", emoji: "🪫", description: "low battery" },
  { code: "U+1F50C", emoji: "🔌", description: "electric plug" },
  { code: "U+1F4BB", emoji: "💻", description: "laptop" },
  { code: "U+1F5A5", emoji: "🖥", description: "desktop computer" },
  { code: "U+1F5A8", emoji: "🖨", description: "printer" },
  { code: "U+2328", emoji: "⌨", description: "keyboard" },
  { code: "U+1F5B1", emoji: "🖱", description: "computer mouse" },
  { code: "U+1F5B2", emoji: "🖲", description: "trackball" },
  { code: "U+1F4BD", emoji: "💽", description: "computer disk" },
  { code: "U+1F4BE", emoji: "💾", description: "floppy disk" },
  { code: "U+1F4BF", emoji: "💿", description: "optical disk" },
  { code: "U+1F4C0", emoji: "📀", description: "dvd" },
  { code: "U+1F9EE", emoji: "🧮", description: "abacus" },

  { code: "U+1F3A5", emoji: "🎥", description: "movie camera" },
  { code: "U+1F39E", emoji: "🎞", description: "film frames" },
  { code: "U+1F4FD", emoji: "📽", description: "film projector" },
  { code: "U+1F3AC", emoji: "🎬", description: "clapper board" },
  { code: "U+1F4FA", emoji: "📺", description: "television" },
  { code: "U+1F4F7", emoji: "📷", description: "camera" },
  { code: "U+1F4F8", emoji: "📸", description: "camera with flash" },
  { code: "U+1F4F9", emoji: "📹", description: "video camera" },
  { code: "U+1F4FC", emoji: "📼", description: "videocassette" },
  { code: "U+1F50D", emoji: "🔍", description: "magnifying glass tilted left" },
  { code: "U+1F50E", emoji: "🔎", description: "magnifying glass tilted right" },
  { code: "U+1F56F", emoji: "🕯", description: "candle" },
  { code: "U+1F4A1", emoji: "💡", description: "light bulb" },
  { code: "U+1F526", emoji: "🔦", description: "flashlight" },
  { code: "U+1F3EE", emoji: "🏮", description: "red paper lantern" },
  { code: "U+1FA94", emoji: "🪔", description: "diya lamp" },

  { code: "U+1F4D4", emoji: "📔", description: "notebook with decorative cover" },
  { code: "U+1F4D5", emoji: "📕", description: "closed book" },
  { code: "U+1F4D6", emoji: "📖", description: "open book" },
  { code: "U+1F4D7", emoji: "📗", description: "green book" },
  { code: "U+1F4D8", emoji: "📘", description: "blue book" },
  { code: "U+1F4D9", emoji: "📙", description: "orange book" },
  { code: "U+1F4DA", emoji: "📚", description: "books" },
  { code: "U+1F4D3", emoji: "📓", description: "notebook" },
  { code: "U+1F4D2", emoji: "📒", description: "ledger" },
  { code: "U+1F4C3", emoji: "📃", description: "page with curl" },
  { code: "U+1F4DC", emoji: "📜", description: "scroll" },
  { code: "U+1F4C4", emoji: "📄", description: "page facing up" },
  { code: "U+1F4F0", emoji: "📰", description: "newspaper" },
  { code: "U+1F5DE", emoji: "🗞", description: "rolled-up newspaper" },
  { code: "U+1F4D1", emoji: "📑", description: "bookmark tabs" },
  { code: "U+1F516", emoji: "🔖", description: "bookmark" },
  { code: "U+1F3F7", emoji: "🏷", description: "label" },

  { code: "U+1F4B0", emoji: "💰", description: "money bag" },
  { code: "U+1FA99", emoji: "🪙", description: "coin" },
  { code: "U+1F4B4", emoji: "💴", description: "yen banknote" },
  { code: "U+1F4B5", emoji: "💵", description: "dollar banknote" },
  { code: "U+1F4B6", emoji: "💶", description: "euro banknote" },
  { code: "U+1F4B7", emoji: "💷", description: "pound banknote" },
  { code: "U+1F4B8", emoji: "💸", description: "money with wings" },
  { code: "U+1F4B3", emoji: "💳", description: "credit card" },
  { code: "U+1F9FE", emoji: "🧾", description: "receipt" },
  { code: "U+1F4B9", emoji: "💹", description: "chart increasing with yen" },

  { code: "U+2709", emoji: "✉", description: "envelope" },
  { code: "U+1F4E7", emoji: "📧", description: "e-mail" },
  { code: "U+1F4E8", emoji: "📨", description: "incoming envelope" },
  { code: "U+1F4E9", emoji: "📩", description: "envelope with arrow" },
  { code: "U+1F4E4", emoji: "📤", description: "outbox tray" },
  { code: "U+1F4E5", emoji: "📥", description: "inbox tray" },
  { code: "U+1F4E6", emoji: "📦", description: "package" },
  { code: "U+1F4EB", emoji: "📫", description: "closed mailbox with raised flag" },
  { code: "U+1F4EA", emoji: "📪", description: "closed mailbox with lowered flag" },
  { code: "U+1F4EC", emoji: "📬", description: "open mailbox with raised flag" },
  { code: "U+1F4ED", emoji: "📭", description: "open mailbox with lowered flag" },
  { code: "U+1F4EE", emoji: "📮", description: "postbox" },
  { code: "U+1F5F3", emoji: "🗳", description: "ballot box with ballot" },

  { code: "U+270F", emoji: "✏", description: "pencil" },
  { code: "U+2712", emoji: "✒", description: "black nib" },
  { code: "U+1F58B", emoji: "🖋", description: "fountain pen" },
  { code: "U+1F58A", emoji: "🖊", description: "pen" },
  { code: "U+1F58C", emoji: "🖌", description: "paintbrush" },
  { code: "U+1F58D", emoji: "🖍", description: "crayon" },
  { code: "U+1F4DD", emoji: "📝", description: "memo" },

  { code: "U+1F4BC", emoji: "💼", description: "briefcase" },
  { code: "U+1F4C1", emoji: "📁", description: "file folder" },
  { code: "U+1F4C2", emoji: "📂", description: "open file folder" },
  { code: "U+1F5C2", emoji: "🗂", description: "card index dividers" },
  { code: "U+1F4C5", emoji: "📅", description: "calendar" },
  { code: "U+1F4C6", emoji: "📆", description: "tear-off calendar" },
  { code: "U+1F5D2", emoji: "🗒", description: "spiral notepad" },
  { code: "U+1F5D3", emoji: "🗓", description: "spiral calendar" },
  { code: "U+1F4C7", emoji: "📇", description: "card index" },
  { code: "U+1F4C8", emoji: "📈", description: "chart increasing" },
  { code: "U+1F4C9", emoji: "📉", description: "chart decreasing" },
  { code: "U+1F4CA", emoji: "📊", description: "bar chart" },
  { code: "U+1F4CB", emoji: "📋", description: "clipboard" },
  { code: "U+1F4CC", emoji: "📌", description: "pushpin" },
  { code: "U+1F4CD", emoji: "📍", description: "round pushpin" },
  { code: "U+1F4CE", emoji: "📎", description: "paperclip" },
  { code: "U+1F587", emoji: "🖇", description: "linked paperclips" },
  { code: "U+1F4CF", emoji: "📏", description: "straight ruler" },
  { code: "U+1F4D0", emoji: "📐", description: "triangular ruler" },
  { code: "U+2702", emoji: "✂", description: "scissors" },

  { code: "U+1F5C3", emoji: "🗃", description: "card file box" },
  { code: "U+1F5C4", emoji: "🗄", description: "file cabinet" },
  { code: "U+1F5D1", emoji: "🗑", description: "wastebasket" },

  { code: "U+1F512", emoji: "🔒", description: "locked" },
  { code: "U+1F513", emoji: "🔓", description: "unlocked" },
  { code: "U+1F50F", emoji: "🔏", description: "locked with pen" },
  { code: "U+1F510", emoji: "🔐", description: "locked with key" },
  { code: "U+1F511", emoji: "🔑", description: "key" },
  { code: "U+1F5DD", emoji: "🗝", description: "old key" },

  { code: "U+1F528", emoji: "🔨", description: "hammer" },
  { code: "U+1FA93", emoji: "🪓", description: "axe" },
  { code: "U+26CF", emoji: "⛏", description: "pick" },
  { code: "U+2692", emoji: "⚒", description: "hammer and pick" },
  { code: "U+1F6E0", emoji: "🛠", description: "hammer and wrench" },
  { code: "U+1F5E1", emoji: "🗡", description: "dagger" },
  { code: "U+2694", emoji: "⚔", description: "crossed swords" },
  { code: "U+1F4A3", emoji: "💣", description: "bomb" },
  { code: "U+1FA83", emoji: "🪃", description: "boomerang" },
  { code: "U+1F3F9", emoji: "🏹", description: "bow and arrow" },
  { code: "U+1F6E1", emoji: "🛡", description: "shield" },
  { code: "U+1FA9A", emoji: "🪚", description: "carpentry saw" },
  { code: "U+1F527", emoji: "🔧", description: "wrench" },
  { code: "U+1FA9B", emoji: "🪛", description: "screwdriver" },
  { code: "U+1F529", emoji: "🔩", description: "nut and bolt" },
  { code: "U+2699", emoji: "⚙", description: "gear" },
  { code: "U+1F5DC", emoji: "🗜", description: "clamp" },
  { code: "U+2696", emoji: "⚖", description: "balance scale" },
  { code: "U+1F9AF", emoji: "🦯", description: "white cane" },
  { code: "U+1F517", emoji: "🔗", description: "link" },

  { code: "U+26D3 U+FE0F U+200D U+1F4A5", emoji: "⛓️‍💥", description: "⊛ broken chain" },
  { code: "U+26D3", emoji: "⛓", description: "chains" },
  { code: "U+1FA9D", emoji: "🪝", description: "hook" },
  { code: "U+1F9F0", emoji: "🧰", description: "toolbox" },
  { code: "U+1F9F2", emoji: "🧲", description: "magnet" },
  { code: "U+1FA9C", emoji: "🪜", description: "ladder" },

  { code: "U+2697", emoji: "⚗", description: "alembic" },
  { code: "U+1F9EA", emoji: "🧪", description: "test tube" },
  { code: "U+1F9EB", emoji: "🧫", description: "petri dish" },
  { code: "U+1F9EC", emoji: "🧬", description: "dna" },
  { code: "U+1F52C", emoji: "🔬", description: "microscope" },
  { code: "U+1F52D", emoji: "🔭", description: "telescope" },
  { code: "U+1F4E1", emoji: "📡", description: "satellite antenna" },

  { code: "U+1F489", emoji: "💉", description: "syringe" },
  { code: "U+1FA78", emoji: "🩸", description: "drop of blood" },
  { code: "U+1F48A", emoji: "💊", description: "pill" },
  { code: "U+1FA79", emoji: "🩹", description: "adhesive bandage" },
  { code: "U+1FA7C", emoji: "🩼", description: "crutch" },
  { code: "U+1FA7A", emoji: "🩺", description: "stethoscope" },
  { code: "U+1FA7B", emoji: "🩻", description: "x-ray" },

  { code: "U+1F6AA", emoji: "🚪", description: "door" },
  { code: "U+1F6D7", emoji: "🛗", description: "elevator" },
  { code: "U+1FA9E", emoji: "🪞", description: "mirror" },
  { code: "U+1FA9F", emoji: "🪟", description: "window" },
  { code: "U+1F6CF", emoji: "🛏", description: "bed" },
  { code: "U+1F6CB", emoji: "🛋", description: "couch and lamp" },
  { code: "U+1FA91", emoji: "🪑", description: "chair" },
  { code: "U+1F6BD", emoji: "🚽", description: "toilet" },
  { code: "U+1FAA0", emoji: "🪠", description: "plunger" },
  { code: "U+1F6BF", emoji: "🚿", description: "shower" },
  { code: "U+1F6C1", emoji: "🛁", description: "bathtub" },
  { code: "U+1FAA4", emoji: "🪤", description: "mouse trap" },
  { code: "U+1FA92", emoji: "🪒", description: "razor" },
  { code: "U+1F9F4", emoji: "🧴", description: "lotion bottle" },
  { code: "U+1F9F7", emoji: "🧷", description: "safety pin" },
  { code: "U+1F9F9", emoji: "🧹", description: "broom" },
  { code: "U+1F9FA", emoji: "🧺", description: "basket" },
  { code: "U+1F9FB", emoji: "🧻", description: "roll of paper" },
  { code: "U+1FAA3", emoji: "🪣", description: "bucket" },
  { code: "U+1F9FC", emoji: "🧼", description: "soap" },

  { code: "U+1FAE7", emoji: "🫧", description: "bubbles" },
  { code: "U+1FAA5", emoji: "🪥", description: "toothbrush" },
  { code: "U+1F9FD", emoji: "🧽", description: "sponge" },
  { code: "U+1F9EF", emoji: "🧯", description: "fire extinguisher" },
  { code: "U+1F6D2", emoji: "🛒", description: "shopping cart" },

  { code: "U+1F6AC", emoji: "🚬", description: "cigarette" },
  { code: "U+26B0", emoji: "⚰", description: "coffin" },
  { code: "U+1FAA6", emoji: "🪦", description: "headstone" },
  { code: "U+26B1", emoji: "⚱", description: "funeral urn" },
  { code: "U+1F9FF", emoji: "🧿", description: "nazar amulet" },
  { code: "U+1FAAC", emoji: "🪬", description: "hamsa" },
  { code: "U+1F5FF", emoji: "🗿", description: "moai" },
  { code: "U+1FAA7", emoji: "🪧", description: "placard" },
  { code: "U+1FAAA", emoji: "🪪", description: "identification card" },

  { code: "U+1F3E7", emoji: "🏧", description: "ATM sign" },
  { code: "U+1F6AE", emoji: "🚮", description: "litter in bin sign" },
  { code: "U+1F6B0", emoji: "🚰", description: "potable water" },
  { code: "U+267F", emoji: "♿", description: "wheelchair symbol" },
  { code: "U+1F6B9", emoji: "🚹", description: "men’s room" },
  { code: "U+1F6BA", emoji: "🚺", description: "women’s room" },
  { code: "U+1F6BB", emoji: "🚻", description: "restroom" },
  { code: "U+1F6BC", emoji: "🚼", description: "baby symbol" },
  { code: "U+1F6BE", emoji: "🚾", description: "water closet" },
  { code: "U+1F6C2", emoji: "🛂", description: "passport control" },
  { code: "U+1F6C3", emoji: "🛃", description: "customs" },
  { code: "U+1F6C4", emoji: "🛄", description: "baggage claim" },
  { code: "U+1F6C5", emoji: "🛅", description: "left luggage" },

  { code: "U+26A0", emoji: "⚠", description: "warning" },
  { code: "U+1F6B8", emoji: "🚸", description: "children crossing" },
  { code: "U+26D4", emoji: "⛔", description: "no entry" },
  { code: "U+1F6AB", emoji: "🚫", description: "prohibited" },
  { code: "U+1F6B3", emoji: "🚳", description: "no bicycles" },
  { code: "U+1F6AD", emoji: "🚭", description: "no smoking" },
  { code: "U+1F6AF", emoji: "🚯", description: "no littering" },
  { code: "U+1F6B1", emoji: "🚱", description: "non-potable water" },
  { code: "U+1F6B7", emoji: "🚷", description: "no pedestrians" },
  { code: "U+1F4F5", emoji: "📵", description: "no mobile phones" },
  { code: "U+1F51E", emoji: "🔞", description: "no one under eighteen" },
  { code: "U+2622", emoji: "☢", description: "radioactive" },
  { code: "U+2623", emoji: "☣", description: "biohazard" },

  { code: "U+2B06", emoji: "⬆", description: "up arrow" },
  { code: "U+2197", emoji: "↗", description: "up-right arrow" },
  { code: "U+27A1", emoji: "➡", description: "right arrow" },
  { code: "U+2198", emoji: "↘", description: "down-right arrow" },
  { code: "U+2B07", emoji: "⬇", description: "down arrow" },
  { code: "U+2199", emoji: "↙", description: "down-left arrow" },
  { code: "U+2B05", emoji: "⬅", description: "left arrow" },
  { code: "U+2196", emoji: "↖", description: "up-left arrow" },
  { code: "U+2195", emoji: "↕", description: "up-down arrow" },
  { code: "U+2194", emoji: "↔", description: "left-right arrow" },
  { code: "U+21A9", emoji: "↩", description: "right arrow curving left" },
  { code: "U+21AA", emoji: "↪", description: "left arrow curving right" },
  { code: "U+2934", emoji: "⤴", description: "right arrow curving up" },
  { code: "U+2935", emoji: "⤵", description: "right arrow curving down" },
  { code: "U+1F503", emoji: "🔃", description: "clockwise vertical arrows" },
  { code: "U+1F504", emoji: "🔄", description: "counterclockwise arrows button" },
  { code: "U+1F519", emoji: "🔙", description: "BACK arrow" },
  { code: "U+1F51A", emoji: "🔚", description: "END arrow" },
  { code: "U+1F51B", emoji: "🔛", description: "ON! arrow" },
  { code: "U+1F51C", emoji: "🔜", description: "SOON arrow" },

  { code: "U+1F51D", emoji: "🔝", description: "TOP arrow" },

  { code: "U+1F6D0", emoji: "🛐", description: "place of worship" },
  { code: "U+269B", emoji: "⚛", description: "atom symbol" },
  { code: "U+1F549", emoji: "🕉", description: "om" },
  { code: "U+2721", emoji: "✡", description: "star of David" },
  { code: "U+2638", emoji: "☸", description: "wheel of dharma" },
  { code: "U+262F", emoji: "☯", description: "yin yang" },
  { code: "U+271D", emoji: "✝", description: "latin cross" },
  { code: "U+2626", emoji: "☦", description: "orthodox cross" },
  { code: "U+262A", emoji: "☪", description: "star and crescent" },
  { code: "U+262E", emoji: "☮", description: "peace symbol" },
  { code: "U+1F54E", emoji: "🕎", description: "menorah" },
  { code: "U+1F52F", emoji: "🔯", description: "dotted six-pointed star" },
  { code: "U+1FAAF", emoji: "🪯", description: "khanda" },

  { code: "U+2648", emoji: "♈", description: "Aries" },
  { code: "U+2649", emoji: "♉", description: "Taurus" },
  { code: "U+264A", emoji: "♊", description: "Gemini" },
  { code: "U+264B", emoji: "♋", description: "Cancer" },
  { code: "U+264C", emoji: "♌", description: "Leo" },
  { code: "U+264D", emoji: "♍", description: "Virgo" },
  { code: "U+264E", emoji: "♎", description: "Libra" },
  { code: "U+264F", emoji: "♏", description: "Scorpio" },
  { code: "U+2650", emoji: "♐", description: "Sagittarius" },
  { code: "U+2651", emoji: "♑", description: "Capricorn" },
  { code: "U+2652", emoji: "♒", description: "Aquarius" },
  { code: "U+2653", emoji: "♓", description: "Pisces" },
  { code: "U+26CE", emoji: "⛎", description: "Ophiuchus" },

  { code: "U+1F500", emoji: "🔀", description: "shuffle tracks button" },
  { code: "U+1F501", emoji: "🔁", description: "repeat button" },
  { code: "U+1F502", emoji: "🔂", description: "repeat single button" },
  { code: "U+25B6", emoji: "▶", description: "play button" },
  { code: "U+23E9", emoji: "⏩", description: "fast-forward button" },
  { code: "U+23ED", emoji: "⏭", description: "next track button" },
  { code: "U+23EF", emoji: "⏯", description: "play or pause button" },
  { code: "U+25C0", emoji: "◀", description: "reverse button" },
  { code: "U+23EA", emoji: "⏪", description: "fast reverse button" },
  { code: "U+23EE", emoji: "⏮", description: "last track button" },
  { code: "U+1F53C", emoji: "🔼", description: "upwards button" },
  { code: "U+23EB", emoji: "⏫", description: "fast up button" },
  { code: "U+1F53D", emoji: "🔽", description: "downwards button" },
  { code: "U+23EC", emoji: "⏬", description: "fast down button" },
  { code: "U+23F8", emoji: "⏸", description: "pause button" },
  { code: "U+23F9", emoji: "⏹", description: "stop button" },
  { code: "U+23FA", emoji: "⏺", description: "record button" },
  { code: "U+23CF", emoji: "⏏", description: "eject button" },
  { code: "U+1F3A6", emoji: "🎦", description: "cinema" },
  { code: "U+1F505", emoji: "🔅", description: "dim button" },

  { code: "U+1F506", emoji: "🔆", description: "bright button" },
  { code: "U+1F4F6", emoji: "📶", description: "antenna bars" },
  { code: "U+1F6DC", emoji: "🛜", description: "wireless" },
  { code: "U+1F4F3", emoji: "📳", description: "vibration mode" },
  { code: "U+1F4F4", emoji: "📴", description: "mobile phone off" },

  { code: "U+2640", emoji: "♀", description: "female sign" },
  { code: "U+2642", emoji: "♂", description: "male sign" },
  { code: "U+26A7", emoji: "⚧", description: "transgender symbol" },

  { code: "U+2716", emoji: "✖", description: "multiply" },
  { code: "U+2795", emoji: "➕", description: "plus" },
  { code: "U+2796", emoji: "➖", description: "minus" },
  { code: "U+2797", emoji: "➗", description: "divide" },
  { code: "U+1F7F0", emoji: "🟰", description: "heavy equals sign" },
  { code: "U+267E", emoji: "♾", description: "infinity" },

  { code: "U+203C", emoji: "‼", description: "double exclamation mark" },
  { code: "U+2049", emoji: "⁉", description: "exclamation question mark" },
  { code: "U+2753", emoji: "❓", description: "red question mark" },
  { code: "U+2754", emoji: "❔", description: "white question mark" },
  { code: "U+2755", emoji: "❕", description: "white exclamation mark" },
  { code: "U+2757", emoji: "❗", description: "red exclamation mark" },
  { code: "U+3030", emoji: "〰", description: "wavy dash" },

  { code: "U+1F4B1", emoji: "💱", description: "currency exchange" },
  { code: "U+1F4B2", emoji: "💲", description: "heavy dollar sign" },

  { code: "U+2695", emoji: "⚕", description: "medical symbol" },
  { code: "U+267B", emoji: "♻", description: "recycling symbol" },
  { code: "U+269C", emoji: "⚜", description: "fleur-de-lis" },
  { code: "U+1F531", emoji: "🔱", description: "trident emblem" },
  { code: "U+1F4DB", emoji: "📛", description: "name badge" },
  { code: "U+1F530", emoji: "🔰", description: "Japanese symbol for beginner" },
  { code: "U+2B55", emoji: "⭕", description: "hollow red circle" },
  { code: "U+2705", emoji: "✅", description: "check mark button" },
  { code: "U+2611", emoji: "☑", description: "check box with check" },
  { code: "U+2714", emoji: "✔", description: "check mark" },
  { code: "U+274C", emoji: "❌", description: "cross mark" },
  { code: "U+274E", emoji: "❎", description: "cross mark button" },
  { code: "U+27B0", emoji: "➰", description: "curly loop" },
  { code: "U+27BF", emoji: "➿", description: "double curly loop" },
  { code: "U+303D", emoji: "〽", description: "part alternation mark" },
  { code: "U+2733", emoji: "✳", description: "eight-spoked asterisk" },
  { code: "U+2734", emoji: "✴", description: "eight-pointed star" },
  { code: "U+2747", emoji: "❇", description: "sparkle" },
  { code: "U+00A9", emoji: "©", description: "copyright" },
  { code: "U+00AE", emoji: "®", description: "registered" },

  { code: "U+2122", emoji: "™", description: "trade mark" },

  { code: "U+0023 U+FE0F U+20E3", emoji: "#️⃣", description: "keycap: #" },
  { code: "U+002A U+FE0F U+20E3", emoji: "*️⃣", description: "keycap: *" },
  { code: "U+0030 U+FE0F U+20E3", emoji: "0️⃣", description: "keycap: 0" },
  { code: "U+0031 U+FE0F U+20E3", emoji: "1️⃣", description: "keycap: 1" },
  { code: "U+0032 U+FE0F U+20E3", emoji: "2️⃣", description: "keycap: 2" },
  { code: "U+0033 U+FE0F U+20E3", emoji: "3️⃣", description: "keycap: 3" },
  { code: "U+0034 U+FE0F U+20E3", emoji: "4️⃣", description: "keycap: 4" },
  { code: "U+0035 U+FE0F U+20E3", emoji: "5️⃣", description: "keycap: 5" },
  { code: "U+0036 U+FE0F U+20E3", emoji: "6️⃣", description: "keycap: 6" },
  { code: "U+0037 U+FE0F U+20E3", emoji: "7️⃣", description: "keycap: 7" },
  { code: "U+0038 U+FE0F U+20E3", emoji: "8️⃣", description: "keycap: 8" },
  { code: "U+0039 U+FE0F U+20E3", emoji: "9️⃣", description: "keycap: 9" },
  { code: "U+1F51F", emoji: "🔟", description: "keycap: 10" },

  { code: "U+1F520", emoji: "🔠", description: "input latin uppercase" },
  { code: "U+1F521", emoji: "🔡", description: "input latin lowercase" },
  { code: "U+1F522", emoji: "🔢", description: "input numbers" },
  { code: "U+1F523", emoji: "🔣", description: "input symbols" },
  { code: "U+1F524", emoji: "🔤", description: "input latin letters" },
  { code: "U+1F170", emoji: "🅰", description: "A button (blood type)" },
  { code: "U+1F18E", emoji: "🆎", description: "AB button (blood type)" },
  { code: "U+1F171", emoji: "🅱", description: "B button (blood type)" },
  { code: "U+1F191", emoji: "🆑", description: "CL button" },
  { code: "U+1F192", emoji: "🆒", description: "COOL button" },
  { code: "U+1F193", emoji: "🆓", description: "FREE button" },
  { code: "U+2139", emoji: "ℹ", description: "information" },
  { code: "U+1F194", emoji: "🆔", description: "ID button" },
  { code: "U+24C2", emoji: "Ⓜ", description: "circled M" },
  { code: "U+1F195", emoji: "🆕", description: "NEW button" },
  { code: "U+1F196", emoji: "🆖", description: "NG button" },
  { code: "U+1F17E", emoji: "🅾", description: "O button (blood type)" },
  { code: "U+1F197", emoji: "🆗", description: "OK button" },
  { code: "U+1F17F", emoji: "🅿", description: "P button" },
  { code: "U+1F198", emoji: "🆘", description: "SOS button" },

  { code: "U+1F199", emoji: "🆙", description: "UP! button" },
  { code: "U+1F19A", emoji: "🆚", description: "VS button" },
  { code: "U+1F201", emoji: "🈁", description: "Japanese “here” button" },
  { code: "U+1F202", emoji: "🈂", description: "Japanese “service charge” button" },
  { code: "U+1F237", emoji: "🈷", description: "Japanese “monthly amount” button" },
  { code: "U+1F236", emoji: "🈶", description: "Japanese “not free of charge” button" },
  { code: "U+1F22F", emoji: "🈯", description: "Japanese “reserved” button" },
  { code: "U+1F250", emoji: "🉐", description: "Japanese “bargain” button" },
  { code: "U+1F239", emoji: "🈹", description: "Japanese “discount” button" },
  { code: "U+1F21A", emoji: "🈚", description: "Japanese “free of charge” button" },
  { code: "U+1F232", emoji: "🈲", description: "Japanese “prohibited” button" },
  { code: "U+1F251", emoji: "🉑", description: "Japanese “acceptable” button" },
  { code: "U+1F238", emoji: "🈸", description: "Japanese “application” button" },
  { code: "U+1F234", emoji: "🈴", description: "Japanese “passing grade” button" },
  { code: "U+1F233", emoji: "🈳", description: "Japanese “vacancy” button" },
  { code: "U+3297", emoji: "㊗", description: "Japanese “congratulations” button" },
  { code: "U+3299", emoji: "㊙", description: "Japanese “secret” button" },
  { code: "U+1F23A", emoji: "🈺", description: "Japanese “open for business” button" },
  { code: "U+1F235", emoji: "🈵", description: "Japanese “no vacancy” button" },

  { code: "U+1F534", emoji: "🔴", description: "red circle" },
  { code: "U+1F7E0", emoji: "🟠", description: "orange circle" },
  { code: "U+1F7E1", emoji: "🟡", description: "yellow circle" },
  { code: "U+1F7E2", emoji: "🟢", description: "green circle" },
  { code: "U+1F535", emoji: "🔵", description: "blue circle" },
  { code: "U+1F7E3", emoji: "🟣", description: "purple circle" },
  { code: "U+1F7E4", emoji: "🟤", description: "brown circle" },
  { code: "U+26AB", emoji: "⚫", description: "black circle" },
  { code: "U+26AA", emoji: "⚪", description: "white circle" },
  { code: "U+1F7E5", emoji: "🟥", description: "red square" },
  { code: "U+1F7E7", emoji: "🟧", description: "orange square" },
  { code: "U+1F7E8", emoji: "🟨", description: "yellow square" },
  { code: "U+1F7E9", emoji: "🟩", description: "green square" },
  { code: "U+1F7E6", emoji: "🟦", description: "blue square" },
  { code: "U+1F7EA", emoji: "🟪", description: "purple square" },
  { code: "U+1F7EB", emoji: "🟫", description: "brown square" },
  { code: "U+2B1B", emoji: "⬛", description: "black large square" },
  { code: "U+2B1C", emoji: "⬜", description: "white large square" },
  { code: "U+25FC", emoji: "◼", description: "black medium square" },
  { code: "U+25FB", emoji: "◻", description: "white medium square" },

  { code: "U+25FE", emoji: "◾", description: "black medium-small square" },
  { code: "U+25FD", emoji: "◽", description: "white medium-small square" },
  { code: "U+25AA", emoji: "▪", description: "black small square" },
  { code: "U+25AB", emoji: "▫", description: "white small square" },
  { code: "U+1F536", emoji: "🔶", description: "large orange diamond" },
  { code: "U+1F537", emoji: "🔷", description: "large blue diamond" },
  { code: "U+1F538", emoji: "🔸", description: "small orange diamond" },
  { code: "U+1F539", emoji: "🔹", description: "small blue diamond" },
  { code: "U+1F53A", emoji: "🔺", description: "red triangle pointed up" },
  { code: "U+1F53B", emoji: "🔻", description: "red triangle pointed down" },
  { code: "U+1F4A0", emoji: "💠", description: "diamond with a dot" },
  { code: "U+1F518", emoji: "🔘", description: "radio button" },
  { code: "U+1F533", emoji: "🔳", description: "white square button" },
  { code: "U+1F532", emoji: "🔲", description: "black square button" },

  { code: "U+1F3C1", emoji: "🏁", description: "chequered flag" },
  { code: "U+1F6A9", emoji: "🚩", description: "triangular flag" },
  { code: "U+1F38C", emoji: "🎌", description: "crossed flags" },
  { code: "U+1F3F4", emoji: "🏴", description: "black flag" },
  { code: "U+1F3F3", emoji: "🏳", description: "white flag" },
  { code: "U+1F3F3 U+FE0F U+200D U+1F308", emoji: "🏳️‍🌈", description: "rainbow flag" },
  { code: "U+1F3F3 U+FE0F U+200D U+26A7 U+FE0F", emoji: "🏳️‍⚧️", description: "transgender flag" },
  { code: "U+1F3F4 U+200D U+2620 U+FE0F", emoji: "🏴‍☠️", description: "pirate flag" },

  { code: "U+1F1E6 U+1F1E8", emoji: "🇦🇨", description: "flag: Ascension Island" },
  { code: "U+1F1E6 U+1F1E9", emoji: "🇦🇩", description: "flag: Andorra" },
  { code: "U+1F1E6 U+1F1EA", emoji: "🇦🇪", description: "flag: United Arab Emirates" },
  { code: "U+1F1E6 U+1F1EB", emoji: "🇦🇫", description: "flag: Afghanistan" },
  { code: "U+1F1E6 U+1F1EC", emoji: "🇦🇬", description: "flag: Antigua & Barbuda" },
  { code: "U+1F1E6 U+1F1EE", emoji: "🇦🇮", description: "flag: Anguilla" },
  { code: "U+1F1E6 U+1F1F1", emoji: "🇦🇱", description: "flag: Albania" },
  { code: "U+1F1E6 U+1F1F2", emoji: "🇦🇲", description: "flag: Armenia" },
  { code: "U+1F1E6 U+1F1F4", emoji: "🇦🇴", description: "flag: Angola" },
  { code: "U+1F1E6 U+1F1F6", emoji: "🇦🇶", description: "flag: Antarctica" },
  { code: "U+1F1E6 U+1F1F7", emoji: "🇦🇷", description: "flag: Argentina" },
  { code: "U+1F1E6 U+1F1F8", emoji: "🇦🇸", description: "flag: American Samoa" },
  { code: "U+1F1E6 U+1F1F9", emoji: "🇦🇹", description: "flag: Austria" },
  { code: "U+1F1E6 U+1F1FA", emoji: "🇦🇺", description: "flag: Australia" },
  { code: "U+1F1E6 U+1F1FC", emoji: "🇦🇼", description: "flag: Aruba" },
  { code: "U+1F1E6 U+1F1FD", emoji: "🇦🇽", description: "flag: Åland Islands" },
  { code: "U+1F1E6 U+1F1FF", emoji: "🇦🇿", description: "flag: Azerbaijan" },
  { code: "U+1F1E7 U+1F1E6", emoji: "🇧🇦", description: "flag: Bosnia & Herzegovina" },
  { code: "U+1F1E7 U+1F1E7", emoji: "🇧🇧", description: "flag: Barbados" },
  { code: "U+1F1E7 U+1F1E9", emoji: "🇧🇩", description: "flag: Bangladesh" },

  { code: "U+1F1E7 U+1F1EA", emoji: "🇧🇪", description: "flag: Belgium" },
  { code: "U+1F1E7 U+1F1EB", emoji: "🇧🇫", description: "flag: Burkina Faso" },
  { code: "U+1F1E7 U+1F1EC", emoji: "🇧🇬", description: "flag: Bulgaria" },
  { code: "U+1F1E7 U+1F1ED", emoji: "🇧🇭", description: "flag: Bahrain" },
  { code: "U+1F1E7 U+1F1EE", emoji: "🇧🇮", description: "flag: Burundi" },
  { code: "U+1F1E7 U+1F1EF", emoji: "🇧🇯", description: "flag: Benin" },
  { code: "U+1F1E7 U+1F1F1", emoji: "🇧🇱", description: "flag: St. Barthélemy" },
  { code: "U+1F1E7 U+1F1F2", emoji: "🇧🇲", description: "flag: Bermuda" },
  { code: "U+1F1E7 U+1F1F3", emoji: "🇧🇳", description: "flag: Brunei" },
  { code: "U+1F1E7 U+1F1F4", emoji: "🇧🇴", description: "flag: Bolivia" },
  { code: "U+1F1E7 U+1F1F6", emoji: "🇧🇶", description: "flag: Caribbean Netherlands" },
  { code: "U+1F1E7 U+1F1F7", emoji: "🇧🇷", description: "flag: Brazil" },
  { code: "U+1F1E7 U+1F1F8", emoji: "🇧🇸", description: "flag: Bahamas" },
  { code: "U+1F1E7 U+1F1F9", emoji: "🇧🇹", description: "flag: Bhutan" },
  { code: "U+1F1E7 U+1F1FB", emoji: "🇧🇻", description: "flag: Bouvet Island" },
  { code: "U+1F1E7 U+1F1FC", emoji: "🇧🇼", description: "flag: Botswana" },
  { code: "U+1F1E7 U+1F1FE", emoji: "🇧🇾", description: "flag: Belarus" },
  { code: "U+1F1E7 U+1F1FF", emoji: "🇧🇿", description: "flag: Belize" },
  { code: "U+1F1E8 U+1F1E6", emoji: "🇨🇦", description: "flag: Canada" },
  { code: "U+1F1E8 U+1F1E8", emoji: "🇨🇨", description: "flag: Cocos (Keeling) Islands" },

  { code: "U+1F1E8 U+1F1E9", emoji: "🇨🇩", description: "flag: Congo - Kinshasa" },
  { code: "U+1F1E8 U+1F1EB", emoji: "🇨🇫", description: "flag: Central African Republic" },
  { code: "U+1F1E8 U+1F1EC", emoji: "🇨🇬", description: "flag: Congo - Brazzaville" },
  { code: "U+1F1E8 U+1F1ED", emoji: "🇨🇭", description: "flag: Switzerland" },
  { code: "U+1F1E8 U+1F1EE", emoji: "🇨🇮", description: "flag: Côte d’Ivoire" },
  { code: "U+1F1E8 U+1F1F0", emoji: "🇨🇰", description: "flag: Cook Islands" },
  { code: "U+1F1E8 U+1F1F1", emoji: "🇨🇱", description: "flag: Chile" },
  { code: "U+1F1E8 U+1F1F2", emoji: "🇨🇲", description: "flag: Cameroon" },
  { code: "U+1F1E8 U+1F1F3", emoji: "🇨🇳", description: "flag: China" },
  { code: "U+1F1E8 U+1F1F4", emoji: "🇨🇴", description: "flag: Colombia" },
  { code: "U+1F1E8 U+1F1F5", emoji: "🇨🇵", description: "flag: Clipperton Island" },
  { code: "U+1F1E8 U+1F1F7", emoji: "🇨🇷", description: "flag: Costa Rica" },
  { code: "U+1F1E8 U+1F1FA", emoji: "🇨🇺", description: "flag: Cuba" },
  { code: "U+1F1E8 U+1F1FB", emoji: "🇨🇻", description: "flag: Cape Verde" },
  { code: "U+1F1E8 U+1F1FC", emoji: "🇨🇼", description: "flag: Curaçao" },
  { code: "U+1F1E8 U+1F1FD", emoji: "🇨🇽", description: "flag: Christmas Island" },
  { code: "U+1F1E8 U+1F1FE", emoji: "🇨🇾", description: "flag: Cyprus" },
  { code: "U+1F1E8 U+1F1FF", emoji: "🇨🇿", description: "flag: Czechia" },
  { code: "U+1F1E9 U+1F1EA", emoji: "🇩🇪", description: "flag: Germany" },
  { code: "U+1F1E9 U+1F1EC", emoji: "🇩🇬", description: "flag: Diego Garcia" },

  { code: "U+1F1E9 U+1F1EF", emoji: "🇩🇯", description: "flag: Djibouti" },
  { code: "U+1F1E9 U+1F1F0", emoji: "🇩🇰", description: "flag: Denmark" },
  { code: "U+1F1E9 U+1F1F2", emoji: "🇩🇲", description: "flag: Dominica" },
  { code: "U+1F1E9 U+1F1F4", emoji: "🇩🇴", description: "flag: Dominican Republic" },
  { code: "U+1F1E9 U+1F1FF", emoji: "🇩🇿", description: "flag: Algeria" },
  { code: "U+1F1EA U+1F1E6", emoji: "🇪🇦", description: "flag: Ceuta & Melilla" },
  { code: "U+1F1EA U+1F1E8", emoji: "🇪🇨", description: "flag: Ecuador" },
  { code: "U+1F1EA U+1F1EA", emoji: "🇪🇪", description: "flag: Estonia" },
  { code: "U+1F1EA U+1F1EC", emoji: "🇪🇬", description: "flag: Egypt" },
  { code: "U+1F1EA U+1F1ED", emoji: "🇪🇭", description: "flag: Western Sahara" },
  { code: "U+1F1EA U+1F1F7", emoji: "🇪🇷", description: "flag: Eritrea" },
  { code: "U+1F1EA U+1F1F8", emoji: "🇪🇸", description: "flag: Spain" },
  { code: "U+1F1EA U+1F1F9", emoji: "🇪🇹", description: "flag: Ethiopia" },
  { code: "U+1F1EA U+1F1FA", emoji: "🇪🇺", description: "flag: European Union" },
  { code: "U+1F1EB U+1F1EE", emoji: "🇫🇮", description: "flag: Finland" },
  { code: "U+1F1EB U+1F1EF", emoji: "🇫🇯", description: "flag: Fiji" },
  { code: "U+1F1EB U+1F1F0", emoji: "🇫🇰", description: "flag: Falkland Islands" },
  { code: "U+1F1EB U+1F1F2", emoji: "🇫🇲", description: "flag: Micronesia" },
  { code: "U+1F1EB U+1F1F4", emoji: "🇫🇴", description: "flag: Faroe Islands" },
  { code: "U+1F1EB U+1F1F7", emoji: "🇫🇷", description: "flag: France" },

  { code: "U+1F1EC U+1F1E6", emoji: "🇬🇦", description: "flag: Gabon" },
  { code: "U+1F1EC U+1F1E7", emoji: "🇬🇧", description: "flag: United Kingdom" },
  { code: "U+1F1EC U+1F1E9", emoji: "🇬🇩", description: "flag: Grenada" },
  { code: "U+1F1EC U+1F1EA", emoji: "🇬🇪", description: "flag: Georgia" },
  { code: "U+1F1EC U+1F1EB", emoji: "🇬🇫", description: "flag: French Guiana" },
  { code: "U+1F1EC U+1F1EC", emoji: "🇬🇬", description: "flag: Guernsey" },
  { code: "U+1F1EC U+1F1ED", emoji: "🇬🇭", description: "flag: Ghana" },
  { code: "U+1F1EC U+1F1EE", emoji: "🇬🇮", description: "flag: Gibraltar" },
  { code: "U+1F1EC U+1F1F1", emoji: "🇬🇱", description: "flag: Greenland" },
  { code: "U+1F1EC U+1F1F2", emoji: "🇬🇲", description: "flag: Gambia" },
  { code: "U+1F1EC U+1F1F3", emoji: "🇬🇳", description: "flag: Guinea" },
  { code: "U+1F1EC U+1F1F5", emoji: "🇬🇵", description: "flag: Guadeloupe" },
  { code: "U+1F1EC U+1F1F6", emoji: "🇬🇶", description: "flag: Equatorial Guinea" },
  { code: "U+1F1EC U+1F1F7", emoji: "🇬🇷", description: "flag: Greece" },
  { code: "U+1F1EC U+1F1F8", emoji: "🇬🇸", description: "flag: South Georgia & South Sandwich Islands" },
  { code: "U+1F1EC U+1F1F9", emoji: "🇬🇹", description: "flag: Guatemala" },
  { code: "U+1F1EC U+1F1FA", emoji: "🇬🇺", description: "flag: Guam" },
  { code: "U+1F1EC U+1F1FC", emoji: "🇬🇼", description: "flag: Guinea-Bissau" },
  { code: "U+1F1EC U+1F1FE", emoji: "🇬🇾", description: "flag: Guyana" },
  { code: "U+1F1ED U+1F1F0", emoji: "🇭🇰", description: "flag: Hong Kong SAR China" },

  { code: "U+1F1ED U+1F1F2", emoji: "🇭🇲", description: "flag: Heard & McDonald Islands" },
  { code: "U+1F1ED U+1F1F3", emoji: "🇭🇳", description: "flag: Honduras" },
  { code: "U+1F1ED U+1F1F7", emoji: "🇭🇷", description: "flag: Croatia" },
  { code: "U+1F1ED U+1F1F9", emoji: "🇭🇹", description: "flag: Haiti" },
  { code: "U+1F1ED U+1F1FA", emoji: "🇭🇺", description: "flag: Hungary" },
  { code: "U+1F1EE U+1F1E8", emoji: "🇮🇨", description: "flag: Canary Islands" },
  { code: "U+1F1EE U+1F1E9", emoji: "🇮🇩", description: "flag: Indonesia" },
  { code: "U+1F1EE U+1F1EA", emoji: "🇮🇪", description: "flag: Ireland" },
  { code: "U+1F1EE U+1F1F1", emoji: "🇮🇱", description: "flag: Israel" },
  { code: "U+1F1EE U+1F1F2", emoji: "🇮🇲", description: "flag: Isle of Man" },
  { code: "U+1F1EE U+1F1F3", emoji: "🇮🇳", description: "flag: India" },
  { code: "U+1F1EE U+1F1F4", emoji: "🇮🇴", description: "flag: British Indian Ocean Territory" },
  { code: "U+1F1EE U+1F1F6", emoji: "🇮🇶", description: "flag: Iraq" },
  { code: "U+1F1EE U+1F1F7", emoji: "🇮🇷", description: "flag: Iran" },
  { code: "U+1F1EE U+1F1F8", emoji: "🇮🇸", description: "flag: Iceland" },
  { code: "U+1F1EE U+1F1F9", emoji: "🇮🇹", description: "flag: Italy" },
  { code: "U+1F1EF U+1F1EA", emoji: "🇯🇪", description: "flag: Jersey" },
  { code: "U+1F1EF U+1F1F2", emoji: "🇯🇲", description: "flag: Jamaica" },
  { code: "U+1F1EF U+1F1F4", emoji: "🇯🇴", description: "flag: Jordan" },
  { code: "U+1F1EF U+1F1F5", emoji: "🇯🇵", description: "flag: Japan" },

  { code: "U+1F1F0 U+1F1EA", emoji: "🇰🇪", description: "flag: Kenya" },
  { code: "U+1F1F0 U+1F1EC", emoji: "🇰🇬", description: "flag: Kyrgyzstan" },
  { code: "U+1F1F0 U+1F1ED", emoji: "🇰🇭", description: "flag: Cambodia" },
  { code: "U+1F1F0 U+1F1EE", emoji: "🇰🇮", description: "flag: Kiribati" },
  { code: "U+1F1F0 U+1F1F2", emoji: "🇰🇲", description: "flag: Comoros" },
  { code: "U+1F1F0 U+1F1F3", emoji: "🇰🇳", description: "flag: St. Kitts & Nevis" },
  { code: "U+1F1F0 U+1F1F5", emoji: "🇰🇵", description: "flag: North Korea" },
  { code: "U+1F1F0 U+1F1F7", emoji: "🇰🇷", description: "flag: South Korea" },
  { code: "U+1F1F0 U+1F1FC", emoji: "🇰🇼", description: "flag: Kuwait" },
  { code: "U+1F1F0 U+1F1FE", emoji: "🇰🇾", description: "flag: Cayman Islands" },
  { code: "U+1F1F0 U+1F1FF", emoji: "🇰🇿", description: "flag: Kazakhstan" },
  { code: "U+1F1F1 U+1F1E6", emoji: "🇱🇦", description: "flag: Laos" },
  { code: "U+1F1F1 U+1F1E7", emoji: "🇱🇧", description: "flag: Lebanon" },
  { code: "U+1F1F1 U+1F1E8", emoji: "🇱🇨", description: "flag: St. Lucia" },
  { code: "U+1F1F1 U+1F1EE", emoji: "🇱🇮", description: "flag: Liechtenstein" },
  { code: "U+1F1F1 U+1F1F0", emoji: "🇱🇰", description: "flag: Sri Lanka" },
  { code: "U+1F1F1 U+1F1F7", emoji: "🇱🇷", description: "flag: Liberia" },
  { code: "U+1F1F1 U+1F1F8", emoji: "🇱🇸", description: "flag: Lesotho" },
  { code: "U+1F1F1 U+1F1F9", emoji: "🇱🇹", description: "flag: Lithuania" },
  { code: "U+1F1F1 U+1F1FA", emoji: "🇱🇺", description: "flag: Luxembourg" },

  { code: "U+1F1F1 U+1F1FB", emoji: "🇱🇻", description: "flag: Latvia" },
  { code: "U+1F1F1 U+1F1FE", emoji: "🇱🇾", description: "flag: Libya" },
  { code: "U+1F1F2 U+1F1E6", emoji: "🇲🇦", description: "flag: Morocco" },
  { code: "U+1F1F2 U+1F1E8", emoji: "🇲🇨", description: "flag: Monaco" },
  { code: "U+1F1F2 U+1F1E9", emoji: "🇲🇩", description: "flag: Moldova" },
  { code: "U+1F1F2 U+1F1EA", emoji: "🇲🇪", description: "flag: Montenegro" },
  { code: "U+1F1F2 U+1F1EB", emoji: "🇲🇫", description: "flag: St. Martin" },
  { code: "U+1F1F2 U+1F1EC", emoji: "🇲🇬", description: "flag: Madagascar" },
  { code: "U+1F1F2 U+1F1ED", emoji: "🇲🇭", description: "flag: Marshall Islands" },
  { code: "U+1F1F2 U+1F1F0", emoji: "🇲🇰", description: "flag: North Macedonia" },
  { code: "U+1F1F2 U+1F1F1", emoji: "🇲🇱", description: "flag: Mali" },
  { code: "U+1F1F2 U+1F1F2", emoji: "🇲🇲", description: "flag: Myanmar (Burma)" },
  { code: "U+1F1F2 U+1F1F3", emoji: "🇲🇳", description: "flag: Mongolia" },
  { code: "U+1F1F2 U+1F1F4", emoji: "🇲🇴", description: "flag: Macao SAR China" },
  { code: "U+1F1F2 U+1F1F5", emoji: "🇲🇵", description: "flag: Northern Mariana Islands" },
  { code: "U+1F1F2 U+1F1F6", emoji: "🇲🇶", description: "flag: Martinique" },
  { code: "U+1F1F2 U+1F1F7", emoji: "🇲🇷", description: "flag: Mauritania" },
  { code: "U+1F1F2 U+1F1F8", emoji: "🇲🇸", description: "flag: Montserrat" },
  { code: "U+1F1F2 U+1F1F9", emoji: "🇲🇹", description: "flag: Malta" },
  { code: "U+1F1F2 U+1F1FA", emoji: "🇲🇺", description: "flag: Mauritius" },

  { code: "U+1F1F2 U+1F1FB", emoji: "🇲🇻", description: "flag: Maldives" },
  { code: "U+1F1F2 U+1F1FC", emoji: "🇲🇼", description: "flag: Malawi" },
  { code: "U+1F1F2 U+1F1FD", emoji: "🇲🇽", description: "flag: Mexico" },
  { code: "U+1F1F2 U+1F1FE", emoji: "🇲🇾", description: "flag: Malaysia" },
  { code: "U+1F1F2 U+1F1FF", emoji: "🇲🇿", description: "flag: Mozambique" },
  { code: "U+1F1F3 U+1F1E6", emoji: "🇳🇦", description: "flag: Namibia" },
  { code: "U+1F1F3 U+1F1E8", emoji: "🇳🇨", description: "flag: New Caledonia" },
  { code: "U+1F1F3 U+1F1EA", emoji: "🇳🇪", description: "flag: Niger" },
  { code: "U+1F1F3 U+1F1EB", emoji: "🇳🇫", description: "flag: Norfolk Island" },
  { code: "U+1F1F3 U+1F1EC", emoji: "🇳🇬", description: "flag: Nigeria" },
  { code: "U+1F1F3 U+1F1EE", emoji: "🇳🇮", description: "flag: Nicaragua" },
  { code: "U+1F1F3 U+1F1F1", emoji: "🇳🇱", description: "flag: Netherlands" },
  { code: "U+1F1F3 U+1F1F4", emoji: "🇳🇴", description: "flag: Norway" },
  { code: "U+1F1F3 U+1F1F5", emoji: "🇳🇵", description: "flag: Nepal" },
  { code: "U+1F1F3 U+1F1F7", emoji: "🇳🇷", description: "flag: Nauru" },
  { code: "U+1F1F3 U+1F1FA", emoji: "🇳🇺", description: "flag: Niue" },
  { code: "U+1F1F3 U+1F1FF", emoji: "🇳🇿", description: "flag: New Zealand" },
  { code: "U+1F1F4 U+1F1F2", emoji: "🇴🇲", description: "flag: Oman" },
  { code: "U+1F1F5 U+1F1E6", emoji: "🇵🇦", description: "flag: Panama" },
  { code: "U+1F1F5 U+1F1EA", emoji: "🇵🇪", description: "flag: Peru" },

  { code: "U+1F1F5 U+1F1EB", emoji: "🇵🇫", description: "flag: French Polynesia" },
  { code: "U+1F1F5 U+1F1EC", emoji: "🇵🇬", description: "flag: Papua New Guinea" },
  { code: "U+1F1F5 U+1F1ED", emoji: "🇵🇭", description: "flag: Philippines" },
  { code: "U+1F1F5 U+1F1F0", emoji: "🇵🇰", description: "flag: Pakistan" },
  { code: "U+1F1F5 U+1F1F1", emoji: "🇵🇱", description: "flag: Poland" },
  { code: "U+1F1F5 U+1F1F2", emoji: "🇵🇲", description: "flag: St. Pierre & Miquelon" },
  { code: "U+1F1F5 U+1F1F3", emoji: "🇵🇳", description: "flag: Pitcairn Islands" },
  { code: "U+1F1F5 U+1F1F7", emoji: "🇵🇷", description: "flag: Puerto Rico" },
  { code: "U+1F1F5 U+1F1F8", emoji: "🇵🇸", description: "flag: Palestinian Territories" },
  { code: "U+1F1F5 U+1F1F9", emoji: "🇵🇹", description: "flag: Portugal" },
  { code: "U+1F1F5 U+1F1FC", emoji: "🇵🇼", description: "flag: Palau" },
  { code: "U+1F1F5 U+1F1FE", emoji: "🇵🇾", description: "flag: Paraguay" },
  { code: "U+1F1F6 U+1F1E6", emoji: "🇶🇦", description: "flag: Qatar" },
  { code: "U+1F1F7 U+1F1EA", emoji: "🇷🇪", description: "flag: Réunion" },
  { code: "U+1F1F7 U+1F1F4", emoji: "🇷🇴", description: "flag: Romania" },
  { code: "U+1F1F7 U+1F1F8", emoji: "🇷🇸", description: "flag: Serbia" },
  { code: "U+1F1F7 U+1F1FA", emoji: "🇷🇺", description: "flag: Russia" },
  { code: "U+1F1F7 U+1F1FC", emoji: "🇷🇼", description: "flag: Rwanda" },
  { code: "U+1F1F8 U+1F1E6", emoji: "🇸🇦", description: "flag: Saudi Arabia" },
  { code: "U+1F1F8 U+1F1E7", emoji: "🇸🇧", description: "flag: Solomon Islands" },

  { code: "U+1F1F8 U+1F1E8", emoji: "🇸🇨", description: "flag: Seychelles" },
  { code: "U+1F1F8 U+1F1E9", emoji: "🇸🇩", description: "flag: Sudan" },
  { code: "U+1F1F8 U+1F1EA", emoji: "🇸🇪", description: "flag: Sweden" },
  { code: "U+1F1F8 U+1F1EC", emoji: "🇸🇬", description: "flag: Singapore" },
  { code: "U+1F1F8 U+1F1ED", emoji: "🇸🇭", description: "flag: St. Helena" },
  { code: "U+1F1F8 U+1F1EE", emoji: "🇸🇮", description: "flag: Slovenia" },
  { code: "U+1F1F8 U+1F1EF", emoji: "🇸🇯", description: "flag: Svalbard & Jan Mayen" },
  { code: "U+1F1F8 U+1F1F0", emoji: "🇸🇰", description: "flag: Slovakia" },
  { code: "U+1F1F8 U+1F1F1", emoji: "🇸🇱", description: "flag: Sierra Leone" },
  { code: "U+1F1F8 U+1F1F2", emoji: "🇸🇲", description: "flag: San Marino" },
  { code: "U+1F1F8 U+1F1F3", emoji: "🇸🇳", description: "flag: Senegal" },
  { code: "U+1F1F8 U+1F1F4", emoji: "🇸🇴", description: "flag: Somalia" },
  { code: "U+1F1F8 U+1F1F7", emoji: "🇸🇷", description: "flag: Suriname" },
  { code: "U+1F1F8 U+1F1F8", emoji: "🇸🇸", description: "flag: South Sudan" },
  { code: "U+1F1F8 U+1F1F9", emoji: "🇸🇹", description: "flag: São Tomé & Príncipe" },
  { code: "U+1F1F8 U+1F1FB", emoji: "🇸🇻", description: "flag: El Salvador" },
  { code: "U+1F1F8 U+1F1FD", emoji: "🇸🇽", description: "flag: Sint Maarten" },
  { code: "U+1F1F8 U+1F1FE", emoji: "🇸🇾", description: "flag: Syria" },
  { code: "U+1F1F8 U+1F1FF", emoji: "🇸🇿", description: "flag: Eswatini" },
  { code: "U+1F1F9 U+1F1E6", emoji: "🇹🇦", description: "flag: Tristan da Cunha" },

  { code: "U+1F1F9 U+1F1E8", emoji: "🇹🇨", description: "flag: Turks & Caicos Islands" },
  { code: "U+1F1F9 U+1F1E9", emoji: "🇹🇩", description: "flag: Chad" },
  { code: "U+1F1F9 U+1F1EB", emoji: "🇹🇫", description: "flag: French Southern Territories" },
  { code: "U+1F1F9 U+1F1EC", emoji: "🇹🇬", description: "flag: Togo" },
  { code: "U+1F1F9 U+1F1ED", emoji: "🇹🇭", description: "flag: Thailand" },
  { code: "U+1F1F9 U+1F1EF", emoji: "🇹🇯", description: "flag: Tajikistan" },
  { code: "U+1F1F9 U+1F1F0", emoji: "🇹🇰", description: "flag: Tokelau" },
  { code: "U+1F1F9 U+1F1F1", emoji: "🇹🇱", description: "flag: Timor-Leste" },
  { code: "U+1F1F9 U+1F1F2", emoji: "🇹🇲", description: "flag: Turkmenistan" },
  { code: "U+1F1F9 U+1F1F3", emoji: "🇹🇳", description: "flag: Tunisia" },
  { code: "U+1F1F9 U+1F1F4", emoji: "🇹🇴", description: "flag: Tonga" },
  { code: "U+1F1F9 U+1F1F7", emoji: "🇹🇷", description: "flag: Türkiye" },
  { code: "U+1F1F9 U+1F1F9", emoji: "🇹🇹", description: "flag: Trinidad & Tobago" },
  { code: "U+1F1F9 U+1F1FB", emoji: "🇹🇻", description: "flag: Tuvalu" },
  { code: "U+1F1F9 U+1F1FC", emoji: "🇹🇼", description: "flag: Taiwan" },
  { code: "U+1F1F9 U+1F1FF", emoji: "🇹🇿", description: "flag: Tanzania" },
  { code: "U+1F1FA U+1F1E6", emoji: "🇺🇦", description: "flag: Ukraine" },
  { code: "U+1F1FA U+1F1EC", emoji: "🇺🇬", description: "flag: Uganda" },
  { code: "U+1F1FA U+1F1F2", emoji: "🇺🇲", description: "flag: U.S. Outlying Islands" },
  { code: "U+1F1FA U+1F1F3", emoji: "🇺🇳", description: "flag: United Nations" },

  { code: "U+1F1FA U+1F1F8", emoji: "🇺🇸", description: "flag: United States" },
  { code: "U+1F1FA U+1F1FE", emoji: "🇺🇾", description: "flag: Uruguay" },
  { code: "U+1F1FA U+1F1FF", emoji: "🇺🇿", description: "flag: Uzbekistan" },
  { code: "U+1F1FB U+1F1E6", emoji: "🇻🇦", description: "flag: Vatican City" },
  { code: "U+1F1FB U+1F1E8", emoji: "🇻🇨", description: "flag: St. Vincent & Grenadines" },
  { code: "U+1F1FB U+1F1EA", emoji: "🇻🇪", description: "flag: Venezuela" },
  { code: "U+1F1FB U+1F1EC", emoji: "🇻🇬", description: "flag: British Virgin Islands" },
  { code: "U+1F1FB U+1F1EE", emoji: "🇻🇮", description: "flag: U.S. Virgin Islands" },
  { code: "U+1F1FB U+1F1F3", emoji: "🇻🇳", description: "flag: Vietnam" },
  { code: "U+1F1FB U+1F1FA", emoji: "🇻🇺", description: "flag: Vanuatu" },
  { code: "U+1F1FC U+1F1EB", emoji: "🇼🇫", description: "flag: Wallis & Futuna" },
  { code: "U+1F1FC U+1F1F8", emoji: "🇼🇸", description: "flag: Samoa" },
  { code: "U+1F1FD U+1F1F0", emoji: "🇽🇰", description: "flag: Kosovo" },
  { code: "U+1F1FE U+1F1EA", emoji: "🇾🇪", description: "flag: Yemen" },
  { code: "U+1F1FE U+1F1F9", emoji: "🇾🇹", description: "flag: Mayotte" },
  { code: "U+1F1FF U+1F1E6", emoji: "🇿🇦", description: "flag: South Africa" },
  { code: "U+1F1FF U+1F1F2", emoji: "🇿🇲", description: "flag: Zambia" },
  { code: "U+1F1FF U+1F1FC", emoji: "🇿🇼", description: "flag: Zimbabwe" },

  { code: "U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F", emoji: "🏴󠁧󠁢󠁥󠁮󠁧󠁿", description: "flag: England" },
  { code: "U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F", emoji: "🏴󠁧󠁢󠁳󠁣󠁴󠁿", description: "flag: Scotland" },
  { code: "U+1F3F4 U+E0067 U+E0062 U+E0077 U+E006C U+E0073 U+E007F", emoji: "🏴󠁧󠁢󠁷󠁬󠁳󠁿", description: "flag: Wales" },
] as Emoji[];
